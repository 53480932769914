import React, {useRef, useEffect, useMemo, useState} from "react";

import {DateTime, Info} from "luxon";
import {Range}          from "utils/Array";
import {X}              from "react-bootstrap-icons";

class SyntheticEvent {
	target;
	constructor(name, value) {
		this.target = Object.freeze({
			name,
			value
		});
	}
}

const Stage = Object.freeze({
	SELECT_DAY   : 1,
	SELECT_MONTH : 2,
	SELECT_YEAR  : 3
});

const InitialState = {
	date         : DateTime.now(),
	browsingDate : DateTime.now(),
	focus        : false,
	stage        : Stage.SELECT_DAY
};

const DefaultConfig = Object.freeze({
	leftArrow  : "⯇",
	rightArrow : "⯈"
});

const DatePicker = ({className = "", name="", value = null, userConfig = {}, onChange = () => {}, event = false, ...args}) => {
	const field                 = useRef();
	const [state, setState]     = useState(InitialState);
	const [mousing, setMousing] = useState(false);

	const config = useMemo(() => {
		return {...DefaultConfig, ...userConfig};
	}, [userConfig]);

	useEffect(() => {
		if (value === "")
			setState(oldState => ({...oldState, date : ""}));
		if (!value || !(typeof value === "string"))
			return;
		setState(oldState => {
			const newState = {...oldState};

			newState.date         = DateTime.fromISO(value);
			newState.browsingDate = DateTime.fromISO(value);

			return newState;
		});	
	}, [value]);

	const clear = () => {
		setState(oldState => {
			return {...oldState, date : ""}
		});
		if (event)
			onChange(new SyntheticEvent(name, ""));
		else
			onChange(null);
	};

	const select = day => {
		if (event)
			onChange(new SyntheticEvent(name, day.toISO()));
		else
			onChange(day.toISO());
	};

	useEffect(() => {
		const dateField = field.current;
		if (!dateField)
			return;

		const focusListener = () => {
			setState(oldState => ({...oldState, focus : true}));
		};

		const clickListener = event => {
			if (event.target === dateField)
				return;

			setState(oldState => {
				if (oldState.focus === false || mousing === true)
					return oldState;
				return {...oldState, focus : false};
			});
		}

		dateField.addEventListener("focus", focusListener);
		document.addEventListener("click", clickListener);

		return () => {
			dateField.removeEventListener("focus", focusListener);
			document.removeEventListener("click", clickListener);
		};
	}, [field, mousing]);

	const spacer = useMemo(() => {
		return Range(state.browsingDate.startOf("month").weekday - 1).fill(null);
	}, [state.browsingDate]);

	const days = useMemo(() => {
		const allDays = [];
		for (let i = 0; i < state.browsingDate.daysInMonth; i++) {
			allDays.push(state.browsingDate.startOf("month").plus({days : i}));
		}

		return allDays;
	}, [state.browsingDate]);

	const DaySelect = () => {
		const dayMatches = day => {
			return day.hasSame(state.date, "day") &&
				day.hasSame(state.date, "month") &&
				day.hasSame(state.date, "year")
		};

		const isToday = day => {
			return DateTime.now().toISODate() === day.toISODate();
		};

		const setDay = date => {
			const oldState = {...state};

			oldState.date         = date;
			oldState.browsingDate = date;
			oldState.focus        = false;

			setState(oldState);
		};

		return (
			<>
				<header className="header">
					<span className="navigation" onClick={() => setState(oldState => ({...oldState, browsingDate : state.browsingDate.minus({months : 1})}))}>{config.leftArrow}</span>
					<span onClick={() => setState(oldState => ({...oldState, stage : Stage.SELECT_MONTH}))}>{state.browsingDate.monthLong}, <span className="header-year">{state.browsingDate.year}</span></span>
					<span className="navigation" onClick={() => setState(oldState => ({...oldState, browsingDate : state.browsingDate.plus({months : 1})}))}>{config.rightArrow}</span>
				</header>
				{Info.weekdays("short").map((day, index) => <span className="weekday" key={index}>{day}</span>)}
				{spacer.map((_,__) => <span key={__}></span>)}
				{days.map((day, index) => <span className={`day ${dayMatches(day) ? "selected" : ""} ${isToday(day) ? "today" : ""}`} onClick={() => {setDay(day); select(day);}} key={index}>{day.day}</span>)}
			</>
		);
	}
	
	const MonthSelect = () => {
		const setMonth = month => {
			const oldState = {...state};

			oldState.browsingDate = state.browsingDate.set({month});
			oldState.date         = state.date.set({month});
			oldState.stage        = Stage.SELECT_DAY;

			setState(oldState);
		};

		return (
			<>
				<header className="header months">
					<span className="navigation" onClick={() => setState(oldState => ({...oldState, browsingDate : state.browsingDate.minus({years : 1})}))}>{config.leftArrow}</span>
					<span onClick={() => setState(oldState => ({...oldState, stage : Stage.SELECT_YEAR}))}>{state.browsingDate.year}</span>
					<span className="navigation" onClick={() => setState(oldState => ({...oldState, browsingDate : state.browsingDate.plus({years : 1})}))}>{config.rightArrow}</span>
				</header>
				{Info.months("short").map((month, index) => (
					<span className={`month ${state.date.month === index + 1 ? "today" : ""}`}
							onClick={() => setMonth(index + 1)} key={index}>
						{month}
					</span>
				))}
			</>
		);
	};

	const YearSelect = () => {
		const year  = state.browsingDate.year;
		const start = year - 4;
		const end   = year + 4;

		const years = useMemo(() => {
			const years = [];
			for (let y = start; y <= end; y++) 
				years.push(y);

			return years;
		}, [state.browsingDate, start, end]);

		const setYear = year => {
			const oldState = {...state};

			if (!state.date)
				state.date = DateTime.now();

			oldState.browsingDate = state.browsingDate.set({year});
			oldState.date         = state.date.set({year});
			oldState.stage        = Stage.SELECT_MONTH;

			setState(oldState);
		};

		return (
			<>
				<header className="header years">
					<span className="navigation" onClick={() => setState(oldState => ({...oldState, browsingDate : state.browsingDate.minus({years : 9})}))}>{config.leftArrow}</span>
					<span>{start} – {end}</span>
					<span className="navigation" onClick={() => setState(oldState => ({...oldState, browsingDate : state.browsingDate.plus({years : 9})}))}>{config.rightArrow}</span>
				</header>
				{years.map((year, index) => (
					<span className={`year ${state.date.year === year ? "today" : ""}`}
							onClick={() => setYear(year)} key={index}>
						{year}
					</span>
				))}
			</>
		);
	};

	return (
		<section className="datepicker">
			<input type="text" ref={field} {...args} className={className} value={state.date.toLocaleString()} onChange={() => {}} />
			<span className="clear" onClick={clear}><X /></span>
			<section className={`selector`}
					style={{display : state.focus ? "grid" : "none", gridTemplateColumns : `repeat(${state.stage === Stage.SELECT_DAY ? 7 : 3}, 1fr)`}}
					{...args} onMouseOver={() => setMousing(true)} onMouseOut={() => setMousing(false)}>
				{(() => {
					switch(state.stage) {
						case Stage.SELECT_YEAR:
							return <YearSelect />;
						case Stage.SELECT_MONTH:
							return <MonthSelect />;
						case Stage.SELECT_DAY:
						default:
							return <DaySelect />;
					}
				})()}
			</section>
		</section>
	);
};

export default DatePicker;