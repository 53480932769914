import React from "react";
import { Server } from "react-bootstrap-icons";

import { useEffect, useState } from "react";

import { NavLink, Routes, Route } from "react-router-dom";



import ContractList from "./components/ContractList";
import CustomerList from "./components/CustomerList";
import DomainList from "./components/DomainList";

import useHHStore from "./hooks/useHHStore";
import usePermission from "components/hooks/usePermission";
import { useAlert } from "react-alert";
import { StrapiResponse } from "utils/StrapiUtils";

const HostingHub = ({ type }) => {
	const alert = useAlert();
	const canUseModul = usePermission("hh-domain", "find", "hh-domain")

	const loadDomains = useHHStore(store => store.domainsLoad);
	const loadCustomers = useHHStore(store => store.customersLoad);
	const loadContracts = useHHStore(store => store.contractsLoad);

	useEffect(() => {
		if (!canUseModul)
			return;
		loadContracts(alert);
		loadCustomers(alert);
		loadDomains(alert);
	}, [alert, loadDomains, loadCustomers, canUseModul]);

	if (!canUseModul) {
		return <></>;
	}

	if (type === "nav") {
		return (
			<>
				<NavLink className={({ isActive }) => isActive ? "active" : ""} to="/HostingHub/DomainList"><Server title="Hosting Hub" /><span>Hosting Hub</span></NavLink>
			</>
		);
	}

	return (
		<>
			<nav>
				<ul className="nav nav-tabs">
					<li className="nav-item"><NavLink end className="nav-link" to="/HostingHub/DomainList">Domains</NavLink></li>
					<li className="nav-item"><NavLink end className="nav-link" to="/HostingHub/CustomerList">Kunden</NavLink></li>
					<li className="nav-item"><NavLink end className="nav-link" to="/HostingHub/ContractList">Verträge</NavLink></li>
				</ul>
			</nav>
			<Routes>
				<Route path="DomainList" element={<DomainList />} />
				<Route path="CustomerList" element={<CustomerList />} />
				<Route path="ContractList" element={<ContractList />} />
			</Routes>
		</>
	);
}
export default HostingHub;