const Languages = Object.freeze([
	"AF",
	"AK",
	"AM",
	"AN",
	"AR",
	"AZ",
	"BE",
	"BG",
	"BN",
	"BR",
	"BS",
	"CA",
	"CO",
	"CS",
	"CY",
	"DA",
	"DE",
	"EE",
	"EL",
	"EN",
	"EO",
	"ES",
	"ET",
	"EU",
	"FA",
	"FI",
	"FO",
	"FR",
	"FY",
	"GA",
	"GD",
	"GL",
	"GN",
	"GU",
	"HA",
	"HE",
	"HI",
	"HR",
	"HT",
	"HU",
	"HY",
	"IA",
	"ID",
	"IG",
	"IS",
	"IT",
	"JA",
	"JV",
	"KA",
	"KG",
	"KK",
	"KM",
	"KN",
	"KO",
	"KU",
	"KY",
	"LA",
	"LB",
	"LG",
	"LN",
	"LO",
	"LT",
	"LV",
	"MG",
	"MI",
	"MK",
	"ML",
	"MN",
	"MR",
	"MS",
	"MT",
	"MY",
	"NB",
	"NE",
	"NL",
	"NN",
	"NO",
	"NY",
	"OC",
	"OM",
	"OR",
	"PA",
	"PL",
	"PS",
	"PT",
	"QU",
	"RM",
	"RN",
	"RO",
	"RU",
	"RW",
	"SD",
	"SI",
	"SK",
	"SL",
	"SM",
	"SN",
	"SO",
	"SQ",
	"SR",
	"ST",
	"SU",
	"SV",
	"SW",
	"TA",
	"TE",
	"TG",
	"TH",
	"TI",
	"TK",
	"TL",
	"TN",
	"TO",
	"TR",
	"TT",
	"TW",
	"UG",
	"UK",
	"UR",
	"UZ",
	"VI",
	"WA",
	"WO",
	"XH",
	"YI",
	"YO",
	"ZH",
	"ZU"
]);

const getLanguageName = isoCode => {
	return new Intl.DisplayNames(["de"], {type : "language"}).of(isoCode);
};

export {getLanguageName};
export default Languages;