import React, { useCallback, useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useAlert } from "react-alert";

import SortableTable from "../../../components/shared/SortableTable";
import SearchField from "../forms/SearchField";
import { Trash, Pencil, FileEarmarkPlus } from "react-bootstrap-icons";
import YesNoModal from "../../../components/shared/YesNoModal";
import CustomerEdit from "../modals/CustomerEdit";

import { StrapiRequest, StrapiResponse } from "utils/StrapiUtils";
import useHHStore from "../hooks/useHHStore";
import NoResult from "components/shared/NoResult";
import Spinner from "components/shared/Spinner";

const CustomerList = () => {
    const alert = useAlert();

    const customers = useHHStore(store => store.customers);
    const [filtered, setFiltered] = useState([]);
    const [search, setSearch] = useState("");
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const [customer, setCustomer] = useHHStore(store => [store.customer,store.customerSet]);
	const deleteCustomer = useHHStore(store => store.customerDelete);
	const ready = useHHStore(store => store.customersReady);

    const headers = useMemo(() => [
        {
            label: "Kundenname",
            member: "name",
            sortable: true
        },
        {
            label: "Extras",
            sortable: false,
            functional: ({ member }) => {
                if (member.extras)
                    return member.extras;
                else
                    return <></>
            }
        },
        {
            label: "",
            member: null,
            sortable: false,
            width: "8%",
            align: "right",
            functional: ({ member }) => {
                return (
                    <>
                        <button className="btn" onClick={() => {
                            setCustomer(member);
                            setShowEdit(true);
                        }}><Pencil />
                        </button>
                        <button className="btn" onClick={() => {
                            setCustomer(member);
                            setShowDelete(true);
                        }}>
                            <Trash />
                        </button>
                    </>
                );
            }
        }
    ], []);


    useEffect(() => {
        setFiltered(search === "" ? customers : customers.filter(customer => String(customer.name).match(new RegExp(`${search}`, "i"))));
    }, [customers, search]);


    return (
        <>
            <button className="btn btn-success btn-sm float-right"
                onClick={event => { setCustomer(null); setShowEdit(true); }}>
                <FileEarmarkPlus className="big" />Kunde anlegen
            </button>
            <section className="grid three-thirds">
            <SearchField search={search} setSearch={setSearch} />
            </section>
			{ready?
			filtered.length > 0 ?
            <SortableTable data={filtered} headers={headers} />
			: <NoResult />
			:<Spinner />}
            <CustomerEdit customer={customer} show={showEdit} setShow={setShowEdit} />
            <YesNoModal text={`Den Kunden '${customer ? customer.name : ""}' wirklich löschen`}
                show={showDelete} setShow={setShowDelete} callback={()=>deleteCustomer(alert)}
            />
        </>
    );
};

export default CustomerList;