import React from "react";

import {ListNested} from "react-bootstrap-icons";

const NoResult = ({children}) => {
	return (
		<section className="noresult">
			<ListNested />
			{children || "Leider keine Ergebnisse"}
		</section>
	);
};

export default NoResult;