import React, {useMemo} from "react";

import {CircleFill, Telephone, Envelope, Phone, ArrowRightShort, Printer} from "react-bootstrap-icons";

import {DateTime, Duration} from "luxon";
import _                    from "lodash";

import FlagFormatter        from "formatters/FlagFormatter";
import EmailFormatter       from "formatters/EmailFormatter";
import PhoneNumberFormatter from "formatters/PhoneNumberFormatter";
import DateFormatter        from "formatters/DateFormatter";
import Shield               from "components/shared/Shield";
import {getLanguageName}    from "utils/Languages";

import usePBStore from "modules/phonebook/hooks/usePBStore";

const UserCardInfo = () => {
	const user  = usePBStore(store => store.user);
	const today = DateTime.now();

	const data = datum => {
		return user?.pb_userdatum?.hasOwnProperty(datum) ? user.pb_userdatum[datum] : null;
	};

	const organisations = useMemo(() => {
		if (!user)
			return null;
		const out  = [];
		const orgs = _.groupBy(user.pb_userdatum?.organisation, "unit.name");
		for (const [key, values] of Object.entries(orgs))
			out.push({ key, values});

		return out;
	}, [user]);

	return (
		  <>
			<section>
				<section className="row">
						<section className="col-md-3">
							<article className="avatar">
								{user?.pb_userdatum?.avatar ?
									<img
										src={user?.pb_userdatum?.avatar instanceof File ? window.URL.createObjectURL(user.pb_userdatum.avatar) : process.env.REACT_APP_BACKEND_URL + user?.pb_userdatum?.avatar?.url}
										alt={user?.pb_userdatum?.avatar?.name || "no name"}
										className="image__avatar"
									/>
								: <div className="no-avatar"><span>{user?.firstname.charAt(0)}{user?.lastname.charAt(0)}</span></div>}
							</article>
						</section>
					<section className="col-md-9" style={{
						display: "flex",
						alignItems : "center",
						padding : user?.pb_userdatum?.avatar ? "initial" : "4rem 0"
					}}>
						<section>
							<span style={{fontSize : "1.5rem", color: "#aaa"}}>{data("company")}</span><br />
							<h1 style={{fontWeight : "100"}}>
								{user?.firstname} {user?.lastname}
								{data("title") ? `, ${data("title")}` : null}
							</h1>
							{data("salutation") ? <Shield tag="Anrede" value={data("salutation")} /> : null}
							{data("jobtitle") ? <Shield tag="Funktion" className="warning" value={data("jobtitle")} /> : null}
							{data("ward") ? <Shield tag="Station" className="primary" value={data("ward").name} /> : null}

						</section>
					</section>
				</section>
				<section className="row">
					<section className="col-md-3 text-right text-secondary small">Aktiv</section>
					<section className="col-md">
						<CircleFill className={!user?.blocked && user?.confirmed ? "text-success" : "text-danger"} style={{fontSize : "0.8rem"}} />
					</section>
				</section>
				<section className="row">
					<section className="col-md-3 text-right text-secondary small">Id</section>
					<section className="col-md-9">
						<code>{user?.id}</code>
					</section>
				</section>
				<section>
					<hr />
				</section>
				{user?.pb_userdatum?.contacts?.map((contact, index) => {
					return (
						<React.Fragment key={index}>
							<section className="row">
								<section className="col-md-3 text-right text-secondary small">{contact.label?.name}</section>
								<section className="col-md-9" style={{lineHeight : "2rem"}}>
									{contact.phonenumber ? <><Telephone className="mr-2" /> {PhoneNumberFormatter(contact.phonenumber)}<br /></> : null}
									{contact.mobilenumber ? <><Phone className="mr-2" /> {PhoneNumberFormatter(contact.mobilenumber)}<br /></> : null}
									{contact.faxnumber ? <><Printer className="mr-2" /> {PhoneNumberFormatter(contact.faxnumber)}<br /></> : null}
									{contact.email ? <><Envelope className="mr-2" /> {EmailFormatter(contact.email)}<br /></> : null}
								</section>
							</section>
							<section>
								<hr />
							</section>
						</React.Fragment>
					);
				})}
				{user?.pb_userdatum?.address ?
					<section className="row">
						<section className="col-md-3 text-right text-secondary small">Adresse</section>
						<section className="col-md-9">
							{data("address")?.street}<br />
							{data("address")?.zip} {data("address")?.city}<br />
							{data("address")?.state}
							{data("address")?.country ?
								<p className="my-2">
									{FlagFormatter(data("address")?.country)}
								</p>
							: null}
						</section>
					</section>
					: null }
			</section>
			 {user?.pb_userdatum?.birthday ?
				<section className="row">
					<section className="col-md-3 text-right text-secondary small">Geburtsdatum</section>
					<section className="col-md-9">
						{DateFormatter(user.pb_userdatum.birthday)} <i className="ml-3">({Duration.fromMillis(today - DateTime.fromISO(user.pb_userdatum.birthday)).toFormat("y")} Jahre)</i>
					</section>
				</section>
			: null}

			{user?.pb_userdatum?.languages?.length > 0?
				<>
					<section>
						<hr />
					</section>
					<section className="row">
						<section className="col-md-3 text-right text-secondary small">Sprachen</section>
						<section className="col-md-9">
							{[...user?.pb_userdatum?.languages].sort((a, b) => getLanguageName(a.code).localeCompare(getLanguageName(b.code))).map(lang => {
								return (
									<span key={lang.code} className="badge badge-secondary mr-2">{getLanguageName(lang.code)}</span>
								);
							})}
						</section>
					</section>
				</>
			: null }

			<section>
				<hr />
			</section>

			{organisations?.map(organisation => {
				return(
					<React.Fragment key={organisation.key}>
						<section className="row">
							<section className="col-md-3 text-right text-secondary small">{organisation.key}</section>
							<section className="col-md-9">
								{organisation.values.map(value => <React.Fragment key={value.id}><ArrowRightShort /> {value.value}<br /></React.Fragment>)}
							</section>
						</section>
						<section>
							<hr />
						</section>
					</React.Fragment>
				);
			})}
			{data("notes") ?
				<section className="row">
					<section className="col-md-3 text-right text-secondary small">Notizen</section>
					<section className="col-md-9 text-muted font-italic">
						{data("notes")}
					</section>
				</section>
			: null}
		</>
	);
};

export default UserCardInfo;