import React, {useMemo, useState} from "react";

import {Telephone, Printer, InfoCircle, Pencil} from "react-bootstrap-icons";

import SortableTable        from "components/shared/SortableTable";
import PageHeader           from "components/shared/PageHeader";
import PhoneNumberFormatter from "formatters/PhoneNumberFormatter";
import SearchField          from "components/shared/SearchField";

import usePBStore from "../hooks/usePBStore";
import RoomEdit   from "./modals/RoomEdit";

const RoomsList = ({}) => {
	const rooms                   = usePBStore(store => store.rooms);
	const [room, setRoom]         = usePBStore(store => [store.room, store.roomSet]);
	const [showEdit, setShowEdit] = usePBStore(store => [store.roomEditShow, store.roomEditShowSet]);
	const [search, setSearch]     = useState("");

	const headers = useMemo(() => [
		{
			label  : "Name",
			member : "name",
			width  : "10%"
		},
		{
			label     : "Nummer",
			member    : "slug",
			width     : "8%",
			formatter : value => <code>{value}</code>
		},
		{
			label  : "Ort",
			member : "location"
		},
		{
			label      : "Nummer",
			member     : "contact.phonenumber",
			sortable   : false,
			functional : ({member}) => {
				return (
					<>
						{member.contact?.phonenumber ? <span style={{display : "block"}}><Telephone style={{opacity : 1}} className="mr-2" /> {PhoneNumberFormatter(member.contact.phonenumber)}</span> : null}
						{member.contact?.faxnumber ? <span style={{display : "block"}}><Printer style={{opacity : 1}} className="mr-2" /> {PhoneNumberFormatter(member.contact.faxnumber)}</span> : null}
					</>
				);
			}
		},
		{
			label: "",
			member: null,
			sortable: false,
			width: "7%",
			functional: ({member}) => {
				return (
					<>
						<button className="btn" onClick={() => {setRoom(member); setShowEdit(true);}}><Pencil /></button>
					</>
				)
			}
		}
	], []);

	const filteredRooms = useMemo(() => {
		if (search.length < 1)
			return rooms;
		const regex = new RegExp(`.*${search}.*`, "gi");
		return rooms.filter(item => item.name?.match(regex) || item.slug?.match(regex) || item.location?.match(regex));
	}, [rooms, search]);

	return (
		<>
			<PageHeader title="Räume">
				<section className="alert alert-info" style={{display : "grid", gridTemplateColumns : "50px auto"}}>
					<InfoCircle className="big" />
					<span>
						Räume werden aus dem <b>marvolo.</b>-Modul "Raumverwaltung" übernommen und können dort angelegt, gelöscht und geändert werden.
					</span>
				</section>
				<section className="grid three-thirds">
					<SearchField search={search} setSearch={setSearch} />
				</section>
			</PageHeader>
			{rooms.length > 0 ?
				<SortableTable headers={headers} data={filteredRooms} rowCallback={room => {setRoom(room); setShowEdit(true);}} />
			: null}
			<RoomEdit show={showEdit} setShow={setShowEdit} room={room} />
		</>
	);
};

export default RoomsList;