import PDF, {Align} from "../../../../utils/PDF";

import DateFormatter from "../../../../formatters/DateFormatter";

const HoursReport = (student, data, deployment) => {
	const doc = new PDF({
		top    : 10,
		right  : 20,
		bottom : 15,
		left   : 20
	});
	const hoursSum = data.reduce((accumulator, object) => {
		return accumulator += object.duration_minutes
	}, 0);
	doc.heading("marvolo. Stundenexport", Align.CENTER);

	doc.write("Name der/des Auszubildenden:", 8, 5);
	doc.write(`${student.firstname.trim()} ${student.lastname.trim()}`);

	doc.write("Kurs:", 8, 5);
	doc.write(`${student.course.name} (${student.course.year})`);

	doc.vspace(5).startColumns(2);
	doc.write("Einsatzort:", 8);
	doc.write(deployment.ward.name);

	doc.nextColumn();

	doc.write("Einsatzeitraum:", 8);
	doc.write(`${DateFormatter(new Date(deployment.from))} - ${DateFormatter(new Date(deployment.to))}`);

	doc.endColumns();

	doc.write("Auflistung der Anleiterstunden", 10, 15, {
		styles : {
			fontWeight : "bold"
		}
	});

	doc.vspace(5).tabular({
		columnStyles : {
			0 : {
				cellWidth : 30
			},
			2 : {
				cellWidth : 30
			}
		},
		head : [[
			"Datum",
			"Beschreibung",
			"Praxisanleiter",
			"Stunden"
		]],
		body : data.map(item => {
			return [
				DateFormatter(item.date),
				`${item.description}\n\n\nKategorie: ${item.category ? item.category.name : "-"}`,
				item.chief_firstname ?
					`${item.chief_firstname} ${item.chief_lastname}` :
					`${item.user.firstname} ${item.user.lastname}`,
				{
					content : `${parseInt(item.duration_minutes / 60)}h ${item.duration_minutes % 60}m`,
					styles  : {
						halign : "right"
					}
				}
			];
		}),
		foot : [
			[
				{
					content : "Summe",
					colSpan : 3,
				},
				{
					content : `${parseInt(hoursSum / 60)}h ${hoursSum % 60}m`,
					styles  : {
						halign : "right"
					}
				}
			]
		],
		styles : {
			fontSize: 8
		},
		pageBreak : "auto"
	});

	/*
	doc.write(`Durch die nachfolgende Unterschrift werden die praktischen Anleitungssequenzen bestätigt.`, 10, 5);

	doc.vspace(10);
	doc.startColumns(2);

	doc.write("Praxisanleiter/in");
	doc.vspace(10).hline();
	doc.write(" Datum, Unterschrift", 8, -3);

	doc.nextColumn();

	doc.write("Auszubildende/r");
	doc.vspace(10).hline();
	doc.write(" Datum, Unterschrift", 8, -3);

	doc.endColumns();
	*/

	doc.show(`marvolo_stundenexport-${student.lastname?.toLowerCase()}_${student.firstname?.toLowerCase()}`);

};

export default HoursReport;
