import create from "zustand";
import { immer } from "zustand/middleware/immer";
import axios from "axios";
import { DateTime } from "luxon";

import Time from "utils/Time";
import { StrapiRequest, StrapiResponse } from "utils/StrapiUtils";
import Log from "utils/Log";


const useHHStore = create(immer((set, get) => ({

	domains: [],
	domainsReady: false,
	domainsLoad: alert => {
		axios.get("/hh-domains?populate[hh_vertrag][populate]=*").then(response => {
			set({ domains: StrapiResponse(response), domainsReady: true });
		}).catch(error => {
			alert.error(`Fehler beim Laden Aufgetreten: ${error}`);
		})
	},

	domain: null,
	domainSet: domain => set({ domain }),
	domainDelete: alert => {
		const { domain, domains } = get();
		axios.delete(`/hh-domains/${domain.id}`).then(response => {
			const newDomains = domains.filter(item => item.id != domain.id)
			alert.success(`Die Domain '${domain.domain} wurde gelöscht`);
			set({ domains: newDomains, domain: null });
		}).catch(error => alert.error(`Fehler beim löschen ${error}`));
	},
	domainSave: (formData, alert) => {
		const { domain, domains, contracts } = get();
		const saveMethod = domain ? axios.put : axios.post;
		const saveURL = domain ? `/hh-domains/${domain.id}` : "/hh-domains";

		const newData = { ...formData };

		saveMethod(saveURL, StrapiRequest(newData)).then((response) => {
			newData.hh_vertrag = contracts.filter(contract => contract.id == newData.hh_vertrag)[0];
			if (domain)
				set({ domains: domains.map(item => item.id === domain.id ? { ...newData } : item) });
			else{
				newData.id = StrapiResponse(response).id;
				set({ domains: [...domains, { ...newData }] });
			}
		}).catch(error => alert.error(`Beim Speichern ist ein Fehler aufgetreten: ${error}`));
	},



	customers: [],
	customersReady: false,
	customersLoad: alert => {
		axios.get("/hh-kundes?populate=*").then(response => {
			set({ customers: StrapiResponse(response), customersReady: true });
		}).catch(error => {
			alert.error(`Fehler beim Laden Aufgetreten: ${error}`);
		})
	},

	customer: null,
	customerSet: customer => set({ customer }),
	customerDelete: alert => {
		const { customer, customers } = get();
		if (customer?.hh_vertrags.length == 0) {
			axios.delete(`/hh-kundes/${customer.id}`).then(response => {
				const newCustomers = customers.filter(item => item.id != customer.id);
				alert.success(`Der Kunden '${customer ? customer.name : ""}' wurde gelöscht`);
				set({ customers: newCustomers, customer: null });
			}).catch(error => {
				alert.error(`Fehler beim löschen ${error}`);
			})
		}
		else {
			alert.error(`Kunde ${customer.name} hat noch Verträge`);
		}
	},
	customerSave: (formData, alert) => {
		const { customer, customers } = get();
		const saveMethod = customer ? axios.put : axios.post;
		const saveURL = customer ? `/hh-kundes/${customer.id}` : "/hh-kundes";

		const newData = { ...formData };

		saveMethod(saveURL, StrapiRequest(newData)).then((response) => {
			if (customer)
				set({ customers: customers.map(item => item.id === customer.id ? { ...newData } : item) });
			else {
				newData.id = StrapiResponse(response).id;
				set({ customers: [...customers, { ...newData }] });
			}
		}).catch(error => alert.error(`Beim Speichern ist ein Fehler aufgetreten: ${error}`));
	},

	contracts: [],
	contractsReady: false,
	contractsLoad: alert => {
		axios.get("/hh-vertrags?populate=*").then(response => {
			set({ contracts: StrapiResponse(response), contractsReady: true });
		}).catch(error => {
			alert.error(`Fehler beim Laden Aufgetreten: ${error}`);
		})
	},

	contract: null,
	contractSet: contract => set({ contract }),
	contractDelete: alert => {
		const { contract, contracts } = get();
		axios.delete(`/hh-vertrags/${contract.id}`).then(response => {
			const newContracts = contracts.filter(item => item.id != contract.id);
			alert.success(`Der Vertrag '${contract ? contract.contractType : ""}' von '${contract ? contract.hh_kunde.name : ""}' wurde gelöscht`);
			set({ contracts: newContracts, contract: null });
		}).catch(error => alert.error(`Fehler beim Laden Aufgetreten: ${error}`));
	},
	contractSave: (formData, alert) => {
		const { contract, contracts, customers } = get();
		const saveMethod = contract ? axios.put : axios.post;
		const saveURL = contract ? `/hh-vertrags/${contract.id}` : "/hh-vertrags";

		const newData = { ...formData };
		saveMethod(saveURL, StrapiRequest(newData)).then((response) => {

			if (contract) {
				newData.hh_kunde = customers.filter(customer => customer.id == newData.hh_kunde)[0];
				set({ contracts: contracts.map(item => item.id === contract.id ? { ...newData } : item) });
			}
			else {
				newData.hh_kunde = customers.filter(customer => customer.id == newData.hh_kunde)[0];
				newData.id = StrapiResponse(response).id;
				set({ contracts: [...contracts, { ...newData }] });
			}
			set({ contract: null });
		}).catch(error => alert.error(`Beim Speichern ist ein Fehler aufgetreten: ${error}`));
	},

	updateNextPayment: alert => {
		const { contract, contracts } = get();
		var date = new Date(contract.nextBill);
		var newDate = new Date(date.setMonth(date.getMonth() + contract.payInterval));
		const formatedFormData = {
			data:
			{
				nextBill: newDate
			}
		}

		var newContract = { ...contract };
		newContract.nextBill = newDate.toISOString().slice(0, 10);

		axios.put(`/hh-vertrags/${contract.id}`, formatedFormData).then(response => {
			set({
				contracts: contracts.map(item => item.id === contract.id ? { ...newContract } : item),
				contract: null
			});
			alert.success(`Rechnungsdatum von ${contract.jobID} wurde erfolgreich geändert.`);
		}).catch(error => alert.error(`Beim Speichern ist ein Fehler aufgetreten: ${error}`));
	}
})));

export default useHHStore;