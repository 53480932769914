import React from "react";

const FlagFormatter = (isoLanguageCode, language = false) => {
	isoLanguageCode = isoLanguageCode === "EN" ? "GB" : isoLanguageCode;

	const title = languageCode => {
		let name;
		try {
			name = new Intl.DisplayNames(["de"], {type : language ? "language" : "region"}).of(languageCode);
		} catch (_) {
			name = "UNKNOWN";
		}

		return name;
	};

	return <span
		title={title(isoLanguageCode)}
		className={`flag-icon flag-icon-${isoLanguageCode.toLowerCase()}`}>
	</span>
};

export default FlagFormatter;
