import React, {useContext, useEffect, useState} from "react";

import {Routes, Route, NavLink} from "react-router-dom";
import {DoorOpenFill}           from "react-bootstrap-icons";

import usePermission from "components/hooks/usePermission";

import RoomsList           from "./components/RoomsList";
import BookingsList        from "./components/BookingsList";

import "./_styles.scss"

const RoomManager = ({nav}) => {
	const canUseModule      = usePermission("rm-booking", "find", "rm-booking")
	const [ready, setReady] = useState(false);

	if (!canUseModule)
		return <></>

    if (nav) {
        return (
            <NavLink to="/RoomManager" className={({isActive}) => isActive ? "active" : ""}>
                <DoorOpenFill /> <span>Raumverwaltung</span>
            </NavLink>
        );
    }

	return (
		<>
			<section className="room-manager">
				<nav>
					<ul className="nav nav-tabs">
						<li className="nav-item"><NavLink end className="nav-link" to="/RoomManager">Buchungen</NavLink></li>
						<li className="nav-item"><NavLink end className="nav-link" to="/RoomManager/rooms">Räume</NavLink></li>
					</ul>
				</nav>
				<Routes>
					<Route index element={<BookingsList />} />
					<Route exact path="rooms" element={<RoomsList />}/>
				</Routes>
			</section>
		</>
	);
};

export default RoomManager;
