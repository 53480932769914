import React from "react";
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";

import {Clock} from "react-bootstrap-icons";


const TimeInput = ({value, onChange = () => {}, className, name, placeholder, filterTime, required = false}) => {

	/**Filter for setting end time of a booking depending on start time. */
	const filterPassedTime = (time) => {
		const currentDate = new Date(filterTime);
		const selectedDate = new Date(time);
	
		return currentDate.getTime() < selectedDate.getTime();
	  };
	
	return (
		<>
			<div className="input-group" style={{width: "135px"}}>
				<div className="input-group-prepend">
					<div className="input-group-text"><Clock /></div>
				</div>
				<DatePicker
					selected={value}
					showTimeSelect
					showTimeSelectOnly
					timeCaption="Uhrzeit"
					locale={de}
					required={required}
					dateFormat="HH:mm"
					className={`form-control ${className}`}
					placeholderText={placeholder}
					onChange={value =>	onChange({
							target : {
								name,
								value : new Date(value)
							}
						})
					} 
					filterTime={filterTime ? filterPassedTime : null}
				/>
			</div>
		</>
	);
};

export default TimeInput;
