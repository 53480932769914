import _ from "lodash";
class StrapiDate extends Date {
	differenceInWeeks(date) {
		return Math.ceil((this - date) / (7 * 24 * 60 * 60 * 1000));
	}

	differenceInDays(date) {
		return Math.round((this - date) / (24 * 60 * 60 * 1000));
	}

	toStrapiDate() {
		return this.toString();
	}

	toJSON() {
		return this.toString();
	}

	toString() {
		return this.toLocaleString("fr-CA", {
			year  : "numeric",
			month : "2-digit",
			day   : "2-digit"
		});
	}

	addWeeks(weeks) {
		const newDate = new Date(this);
		return new Date(newDate.setDate(newDate.getDate() + (weeks * 7)));
	}
}

const GenerateSecuraPassword = () => {
	let key = "";

	let key32 = new Uint32Array(4);
	key32 = window.crypto.getRandomValues(key32);

	for (let keySegment = 0; keySegment < key32.length; keySegment++) {
		key += (keySegment > 0) ? "-" : "";
		key += key32[keySegment].toString(16);
	}

	return key;
}

const StrapiRequest = data => {
	return {
		data : {...data}
	};
};

const StrapiResponse = response => {
	let matter = null;
	if (response.data && response.data.hasOwnProperty("data"))
		matter = response.data;
	else if (response.hasOwnProperty("data"))
		matter = response;

	if (!matter)
		return null;
	
	const normalizeObject = obj => {
		const attributes = obj?.hasOwnProperty("attributes") ? obj.attributes : {};
		const newObj     = {
			...obj,
			...attributes
		};
		delete newObj.attributes;

		for (const [key, value] of Object.entries(newObj)) {
			if (value && (typeof value === "object") && value?.hasOwnProperty("data"))
				newObj[key] = StrapiResponse(value);
		}

		return newObj;
	}

	if (Array.isArray(matter.data))
		return matter.data.map(obj => normalizeObject(obj)) 
	else if (typeof matter.data === "object")
		return normalizeObject(matter.data);
	else
		return matter.data
};

const RemoveEmptyRecursively = object => {
	if (object instanceof Date)
		return object;
	if (typeof object !== "object" || object === null)
		return object;

	if (Array.isArray(object)) {
		return object.map(RemoveEmptyRecursively).filter(item => {
			if (typeof item === "string" && item.length < 1)
				return false;
			return !(typeof item === "object" && Object.keys(item).length === 0);
		});
	}

	const sanobject = {};
	for (const [key, value] of Object.entries(object)) {
		if (typeof value === "string" && value.trim().length < 1)
			continue;
		if (typeof value === "undefined" || value === null)
			continue;
		if (Array.isArray(value) && value.length === 0)
			continue;
		const newValue = RemoveEmptyRecursively(value);
		if (!newValue || (typeof newValue === "object" && Object.keys(newValue).length < 1))
			continue;
		sanobject[key] = newValue; 
	}

	return sanobject;
};

export {StrapiDate, GenerateSecuraPassword, StrapiRequest, StrapiResponse, RemoveEmptyRecursively};