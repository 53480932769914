import React, {useState, useMemo, useCallback} from "react";

import {CaretDownFill, CaretUpFill} from "react-bootstrap-icons";

import Std from "../../utils/Std";

const {LogLevel} = Std;

const resolveMemberPath = (obj, path) => {
	const paths    = path.split('.');
	let   variable = obj;

	for (const member of paths)
		variable = variable === null || typeof variable === "undefined" || !variable.hasOwnProperty(member) ? null : variable[member];

	// Error reporting in dev environment
	if (variable === null && Std.Develoment()) {
		Std.Log(`[SortableTable] Could not resolve member '${path}'`, LogLevel.INFO);
		Std.Log(`\tresolved to '${paths.join(' → ')}'`);
		console.groupCollapsed("\t⏍ show object data");
		console.table(obj);
		console.groupEnd();
	}
	return variable?.toString();
};

const SortableTable = React.memo(({headers, data, rowCallback, sorting =  null, query = null, footer = null, sortCallback = null}) => {
	const [sortingField, setSortingField] = useState(sorting);
	const [sortingOrder, setSortingOrder] = useState("ASC");

	const sortASC = member => {
		if (sortCallback) {
			sortCallback(member, "ASC");
			return;
		}
		setSortingOrder("ASC");
		setSortingField(member);
	};

	const sortDESC = member => {
		if (sortCallback) {
			sortCallback(member, "DESC");
			return;
		}
		setSortingOrder("DESC");
		setSortingField(member);
	};

	const sorted = useMemo(() => {
		if (sortCallback)
			return data;

		let sortedData = [...data];

		if (sortingField === null)
			return sortedData;

		sortedData.sort((a, b) => {
			switch (sortingOrder) {
				case "DESC":
					return resolveMemberPath(a, sortingField)
						.localeCompare(resolveMemberPath(b, sortingField)
						.toString()) * -1;
				case "ASC":
				default:
					return resolveMemberPath(a, sortingField)
						.localeCompare(resolveMemberPath(b, sortingField)
						.toString());
			}
		});

		return sortedData;
	}, [data, sortingField, sortingOrder]);

	const cursor = useMemo(() => rowCallback ? "pointer" : "default", [rowCallback]);

	const clickRow = useCallback(item => rowCallback ? rowCallback(item) : null, [rowCallback]);

	if (Std.Develoment())
		console.groupCollapsed(`%c[SortableTable] ⚠ Error data`, "color: red");

	const markup = useMemo(() => (
		<section className="tableWrap">
			<table className="table table-striped sortable">
				<thead>
				<tr>
					{headers.map((item, index) => {
						return (
							<th key={index} style={{width : item.width ? item.width : "auto"}}>
								{item.label}
								{ typeof item.sortable === "undefined" || item.sortable === true ?
									<span className="controls">
										<CaretUpFill className={
											sortingField === item.member && sortingOrder === "ASC" ? "active" : ""
										} onClick={() => sortASC(item.member)} />
										<CaretDownFill className={
											sortingField === item.member && sortingOrder === "DESC" ? "active" : ""
										} onClick={() => sortDESC(item.member)} />
									</span> : null
								}
							</th>
						)
					})}
				</tr>
				</thead>
				<tbody>
				{sorted.map((item, index) => {
					return(
						<tr key={index}	style={{cursor}} onClick={event => { if (event.target.tagName.toLowerCase() !== "td") return; clickRow(item) }}>
							{
								headers.map((header, index) => {
									const cellStyle = {
										textAlign : header.align ? header.align : "left",
										padding   : header.padding ? header.padding : null
									};
									const cellClass = header.fullContent ? "block" : "";
									
									if (typeof header.functional !== "undefined") {
										const Component = header.functional;
										return (
											<td className={`functional ${cellClass}`} style={cellStyle} key={index}>
												<Component member={item} />
											</td>
										);
									}
									const cellTitle = typeof header.formatter !== "undefined" ?
										header.formatter(resolveMemberPath(item, header.member)?.toString()) :
										resolveMemberPath(item, header.member)?.toString();
									return (
										<td key={index} style={cellStyle} title={cellTitle}	className={cellClass}>
											{
												typeof header.formatter !== "undefined" ?
												header.formatter(resolveMemberPath(item, header.member)) :
												resolveMemberPath(item, header.member)
											}
										</td>
									);
								})
							}
						</tr>
					);
				})}
				{footer ? footer : null}
				</tbody>
			</table>
		</section>
	), [headers, clickRow, cursor, footer, sorted, sortingField, sortingOrder]);
	if (Std.Develoment())
		console.groupEnd();

	return markup;
});

export default SortableTable;
