import React, {useState, useEffect, useMemo} from "react";

const SplitMenu = ({orgUnits, onChange = () => {}}) => {
	const [currentUnit, setCurrentUnit] = useState(null);
	
	const sortedOrgValues = useMemo(() => {
		//concat needed because sort mutates the original array which is not allowed on state hooks
		const sortedCopy = [].concat(currentUnit?.organisational_values).sort((a,b) => a.value?.localeCompare(b.value));
		return(sortedCopy[0]!=undefined?sortedCopy:[]);
	},[currentUnit]);
	
	useEffect(() => {
		if (!orgUnits || orgUnits.length < 1)
			return;
		setCurrentUnit(orgUnits[0]);
	}, [orgUnits]);

	return (
		<section className="split-menu">
			<section className="level1">
				{orgUnits.map((unit, index) => (
					<span className={unit.id === currentUnit?.id ? "active" : ""} key={index} onClick={event => { event.stopPropagation(); setCurrentUnit(unit); }}>{unit.name}</span>
				))}
			</section>
			<section className="level2">
				{sortedOrgValues.map((value, index) => (
					<span key={index} onClick={() => onChange(currentUnit, value)}>{value.value}</span>
				))}
				{/*currentUnit?.organisational_values?.map((value, index) => (
					<span key={index} onClick={() => onChange(currentUnit, value)}>{value.value}</span>
				))*/}
			</section>
		</section>
	);
};

export default SplitMenu;