import React, {useMemo, useState} from "react";

const Canvas = document.createElement("canvas");
const CTX    = Canvas.getContext("2d");

const DynamicTextField = ({value, style, ...args}) => {
	const [field, setField] = useState(null);

	const textWidth = useMemo(() => {
		if (!field ||(!value && !field.placeholder) || (value && value.length < 1 && !field.placeholder))
			return "3ch";
		const computedStyles = window.getComputedStyle(field);
		const fontFamily     = computedStyles.getPropertyValue("font-family");
		const fontSize       = computedStyles.getPropertyValue("font-size");
		const paddingLeft    = Number(computedStyles.getPropertyValue("padding-left").replace("px", ""));
		const paddingRight   = Number(computedStyles.getPropertyValue("padding-right").replace("px", ""));
		const borderLeft     = Number(computedStyles.getPropertyValue("border-left-width").replace("px", ""));
		const borderRight    = Number(computedStyles.getPropertyValue("border-right-width").replace("px", ""));
			  
		CTX.font = `${fontSize} ${fontFamily}`;
		const textProperties = CTX.measureText(value || field.placeholder);

		return `${textProperties.width + paddingLeft + paddingRight + borderLeft + borderRight}px`;
	}, [value, field]);

	return (
		<>
			<input {...args} value={value} style={{...style, width : `${textWidth}`}} ref={ref => setField(ref)} />
		</>
	);
};

export default DynamicTextField;