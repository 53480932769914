import React, { useEffect, useState, useMemo } from "react";

import SortableTable from "../../../components/shared/SortableTable";
import { useAlert } from "react-alert";
import SearchField from "../forms/SearchField";
import { Trash, Pencil, FileEarmarkPlus } from "react-bootstrap-icons";
import YesNoModal from "../../../components/shared/YesNoModal";
import DomainEdit from "../modals/DomainEdit.js";

import useHHStore from "../hooks/useHHStore";
import NoResult from "components/shared/NoResult";
import Spinner from "components/shared/Spinner";


const DomainList = () => {
	const alert = useAlert();
    const domains = useHHStore(store => store.domains);
    const [domain, setDomain] = useHHStore(store => [store.domain, store.domainSet]);
    const deleteDomain = useHHStore(store => store.domainDelete);
	const ready = useHHStore(store => store.domainsReady);

	const [filtered, setFiltered] = useState([]);
    const [search, setSearch] = useState("");
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);


    const headers = useMemo(() => [
        {
            label: "Jobnummer",
            member: "hh_vertrag.jobID",
            sortable: true
        },
        {
            label: "Kunde",
            member: "hh_vertrag.hh_kunde.name",
            sortable: true
        },
        {
            label: "Vertragsart",
            member: "hh_vertrag.contractType",
            sortable: true
        },
        {
            label: "Leistungs Info",
            member: "hh_vertrag.contractInfo",
            sortable: false
        },
        {
            label: "Domain",
            member: "domain",
            sortable: false
        },
        {
            label: "",
            member: null,
            sortable: false,
            width: "8%",
            align: "right",
            functional: ({ member }) => {
                return (
                    <>
                        <button className="btn" onClick={() => {
                            setDomain(member);
                            setShowEdit(true);
                        }}><Pencil />
                        </button>
                        <button className="btn" onClick={() => {
                            setDomain(member);
                            setShowDelete(true);
                        }}>
                            <Trash />
                        </button>
                    </>
                );
            }
        }
    ], []);

    useEffect(() => {
        setFiltered(search === "" ? domains : domains.filter(domain => String(domain.hh_vertrag.jobID).match(new RegExp(`${search}`, "i"))));
    }, [domains, search]);

    return (
        <>
            <button className="btn btn-success btn-sm float-right"
                onClick={event => { setDomain(null); setShowEdit(true); }}>
                <FileEarmarkPlus className="big" />Domain anlegen
            </button>
            <section className="grid three-thirds">
            <SearchField search={search} setSearch={setSearch} />
            </section>
			{ready?
			filtered.length > 0
			?<SortableTable data={filtered} headers={headers} />
			:<NoResult />
			:<Spinner />}
            <DomainEdit domain={domain} show={showEdit} setShow={setShowEdit} />
            <YesNoModal text={`Möchten Sie die Domain '${domain ? domain.domain : ""} wirklich löschen?`}
			show={showDelete} setShow={setShowDelete} callback={() => deleteDomain(alert)}
            />
        </>
    );
};

export default DomainList;