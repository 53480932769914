import React, { useCallback, useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useAlert } from "react-alert";

import SortableTable from "../../../components/shared/SortableTable";
import DateFormatter from "../../../formatters/DateFormatter";
import SearchField from "../forms/SearchField";
import { Trash, Pencil, FileEarmarkPlus, CheckSquare, XSquare } from "react-bootstrap-icons";
import Button from 'react-bootstrap/Button';

import YesNoModal from "../../../components/shared/YesNoModal";
import ContractEdit from "../modals/ContractEdit";
import Std from "../../../utils/Std";
import Select from "../../../components/shared/Select";
import useLocalStorage from "../../../components/hooks/useLocalStorage";

import { StrapiResponse } from "utils/StrapiUtils";
import Toggle from "components/shared/Toggle";
import useHHStore from "../hooks/useHHStore";
import NoResult from "components/shared/NoResult";
import Spinner from "components/shared/Spinner";

const ContractList = () => {
	const alert = useAlert();
	const [filtered, setFiltered] = useState([]);
	const [search, setSearch] = useState("");
	const [showEdit, setShowEdit] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	
	const contracts = useHHStore(store => store.contracts);
	const [contract, setContract] = useHHStore(store => [store.contract,store.contractSet]);
	const updateNextPayment = useHHStore(store => store.updateNextPayment);
	const deleteContract = useHHStore(store => store.contractDelete);
	const ready = useHHStore(store => store.contractsReady);

	const [type, setType] = useState("");

	const [showInactive, setShowInactive] = useLocalStorage("showCurrentContracts", false);

	const headers = useMemo(() => [
		{
			label: "Jobnummer",
			member: "jobID",
			width: "10%",
			sortable: true
		},
		{
			label: "Kunde",
			member: "hh_kunde.name",
			width: "8%",
			sortable: true
		},
		{
			label: "Leistung",
			member: "contractType",
			width: "8%",
			sortable: true
		},
		{
			label: "Leistungs Info",
			member: "contractInfo",
			sortable: false,
			width: "8%",
			functional: ({ member }) => {
				if (member.contractInfo) {
					return (
						<>
							{member.contractInfo}
						</>
					)
				}
				else {
					return <></>
				}
			}
		},
		{
			label: "Preis",
			member: "price",
			sortable: false,
			width: "5%",
			functional: ({ member }) => {
				if (member.contractType != "Extern") {
					return (
						<>
							{member.price} €
						</>
					)
				}
				else {
					return <></>
				}
			}
		},
		{
			label: "Jahrespreis",
			member: null,
			sortable: false,
			width: "7%",
			functional: ({ member }) => {
				if (member.contractType != "Extern") {
					return (
						<>
							{12 * member.price} €
						</>
					)
				}
				else {
					return <></>
				}
			}
		},
		{
			label: "Zahlungsinterval",
			member: "payInterval",
			sortable: false,
			width: "8%",
			functional: ({ member }) => {
				if (member.contractType != "Extern") {
					return (
						<>
							{member.payInterval} Monate
						</>
					)
				}
				else {
					return <></>
				}
			}

		},
		{
			label: "Nächste Rechnung",
			member: "nextBill",
			formatter: DateFormatter,
			width: "13%",
			functional: ({ member }) => {
				if (member.contractType != "Extern") {
					return (
						<>
							{member.nextBill}
						</>
					)
				}
				else {
					return <></>
				}
			}
		},
		{
			label: "Aktiv",
			member: "active",
			sortable: true,
			width: "8%",
			functional: ({ member }) => {
				if (member.active === true)
					return <CheckSquare style={{opacity : 1}} className="text-success" />
				else
					return <XSquare style={{opacity : 1}} className="text-danger" />
			}
		},
		{
			label: "Gekündigt am",
			sortable: false,
			formatter: DateFormatter,
			functional: ({ member }) => {
				if (member.contractType != "Extern") {
					if (member.terminationDate)
						return member.terminationDate;
					else {
						return <></>
					}
				} else {
					return <></>
				}
			}
		},
		{
			label: "Bemerkungen",
			sortable: false,
			functional: ({ member }) => {
				if (member.extra)
					return member.extra;
				else
					return <></>
			}

		},
		{
			label: "",
			member: null,
			sortable: false,
			width: "8%",
			align: "right",
			functional: ({ member }) => {
				if (member.contractType != "Extern") {
					return (
						<>
							<Button className="btn btn-sm primary" onClick={() => {
								setContract(member);
								updateNextPayment(alert);
							}}
								 variant="primary">Rechnung gestellt
							</Button>
						</>
					)
				} else {
					return <></>
				}
			}
		},
		{
			label: "",
			member: null,
			sortable: false,
			width: "8%",
			align: "right",
			functional: ({ member }) => {
				return (
					<>
						<button className="btn" onClick={() => {
							setContract(member);
							setShowEdit(true);
						}}><Pencil />
						</button>
						<button className="btn" onClick={() => {
							setContract(member);
							setShowDelete(true);
						}}>
							<Trash />
						</button>
					</>
				);
			}
		}
	], []);

	useEffect(() => {
		if (search === "" && type === "") {
			setFiltered(showInactive ? contracts.filter(contract => contract.nextBill.split("-")[1] === (new Date().getMonth() + 1).toString().padStart(2, "0") && contract.nextBill.split("-")[0] === new Date().getFullYear().toString()) : contracts);
			return;
		}
		const regex = new RegExp(`${search}`, "gi");
		setFiltered(contracts.filter(contract => {
			let select = true;
			if (select && search !== "") {
				select = String(contract.jobID).match(new RegExp(`${search}`, "i")) || contract.hh_kunde.name.match(regex);
			}
			if (select && type !== "") {
				select = contract.id == type;
			} if (select && showInactive) {
				select = contract.nextBill.split("-")[1] === (new Date().getMonth() + 1).toString().padStart(2, "0") && contract.nextBill.split("-")[0] === new Date().getFullYear().toString();
			}
			return select;
		}));
	}, [contracts, search, type, showInactive]);


	return (
		<>
			<h2 className="py-4">Verträge<button className="btn btn-success btn-sm float-right" onClick={event => { setContract(null); setShowEdit(true); }}>
				<FileEarmarkPlus className="big" />Leistung anlegen</button></h2>
			<section className="filter grid three-thirds">
				<SearchField search={search} setSearch={setSearch} />
				<p className="grid ten-ninety" style={{ padding: 0, margin: 0 }}>
					<label style={{ justifySelf: "right", alignSelf: "center", margin: 0 }}>Leistung</label>
					<Select data={Std.SortAoOByMember(contracts, "contractType")} member={"contractType"} current={type} defaultText="alle" onChange={event => setType(event.target.value)} firstSelectable={true} />
				</p>
				<div className="grid ten-ninety" style={{ padding: 0, margin: 0 }}>
					<Toggle name="active" checked={showInactive} onChange={event => setShowInactive(event.target.checked)} style={{ alignSelf: "center", justifySelf: "right" }} />
					<label style={{ alignSelf: "center", justifySelf: "left", margin: 0 }}>Zeige diesen Monat zu zahlende Leistungen</label>
				</div>
			</section>
			{ready?
			filtered.length > 0
			?<SortableTable data={filtered} headers={headers} />
			:<NoResult />
			:<Spinner />}
			<ContractEdit contract={contract} show={showEdit} setShow={setShowEdit} />
			<YesNoModal text={`Möchten Sie die Leistung '${contract ? contract.jobID : ""}' von '${contract ? contract.hh_kunde.name : ""}' wirklich löschen?`}
				show={showDelete} setShow={setShowDelete} callback={() => deleteContract(alert)}
			/>

		</>
	);
};

export default ContractList;