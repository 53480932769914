import React, {useState, useEffect, useMemo, useCallback} from "react";
import axios from "axios";

import {useAlert} from "react-alert";

import Std                 from "utils/Std";
import MinutesFormatter    from "../../../../formatters/MinutesFormatter";
import Spinner             from "../../../../components/shared/Spinner";
import SortableTable       from "../../../../components/shared/SortableTable";
import TableFooterTotalSum from "../../../../components/shared/TableFooterTotalSum";
import { StrapiResponse, StrapiDate } from "utils/StrapiUtils";
import {PersonSquare, X} from "react-bootstrap-icons";
import DateInput           from "../../../../components/shared/DateInput";

const StatsWards = () => {
	const headers               = [
		{
			label  : "Id",
			member : "id",
			width  : "90px"
		},
		{
			label  : "Name",
			member : "name",
			width  : "10%"
		},
		{
			label     : "Stunden gesamt",
			member    : "minutes_total",
			formatter : MinutesFormatter,
			align     : "right"
		}
	];
	const alert                             = useAlert();
	const [wards, setWards]                 = useState([]);
	const [ward, setWard]                   = useState(null);
	const [filteredWards, setFilteredWards] = useState([]);
	const [from, setFrom]                   = useState(null);
	const [to, setTo]                       = useState(null);
	const [ready, setReady]                 = useState(false);

	const load = useCallback(() => {
		setReady(false);
		const range = [];

		if (from)
			range.push(`filters[date][$gte]=${new StrapiDate(from)}`);
		if (to)
			range.push(`filters[date][$lte]=${new StrapiDate(to)}`);
		const rangeString = range.length > 0 ? `?${range.join("&")}` : "";

		axios.get(`/ward/statistics${rangeString}`).then(response => {
			setWards(StrapiResponse(response));
			setReady(true);
		}).catch(error => alert.error(`Fehler beim laden der Einsatzortdaten: ${error}`));
	}, [alert, from, to]);

	useEffect(() => {
		load();
	}, [load, from, to]);

	useEffect(() => {
		if (!ward) {
			setFilteredWards([...wards]);

			return;
		}
		setFilteredWards(wards.filter(item => item.id === parseInt(ward)));
	}, [ward, wards]);

	const totalMinutes = useMemo(() => {
		return filteredWards.reduce((accumulator, item) => accumulator + item.minutes_total, 0);
	}, [filteredWards]);

	if (!ready)
		return (
			<>
				<h2 className="py-4">Statistiken "Einsatzorte"</h2>
				<Spinner />
			</>
		);

	return (
		<>
			<h2 className="py-4">Statistiken "Einsatzorte"</h2>
			<section className="grid three-thirds">
				<div>
					<div className="input-group">
						<div className="input-group-prepend">
							<div className="input-group-text" title="Einsatz auswählen"><PersonSquare /></div>
						</div>
						<select name="course" style={{fontWeight : "normal", fontSize : "1rem"}} value={ward || ""} className="form-control" onChange={event => setWard(event.target.value)}>
							<option value="">alle</option>
							{Std.SortAoOByMember(wards, "name").map((ward, index) => <option value={ward.id} key={index}>{ward.name}</option>)}
						</select>
					</div>
				</div>
				<div className="date-input">
					<DateInput placeholder="Von" value={from || ""} onChange={value => setFrom(value === "" ? null : value)} />
				</div>
				<div className="date-input">
					<DateInput placeholder="Bis" value={to || ""} onChange={value => setTo(value === "" ? null : value)} />
				</div>
			</section>
			<SortableTable data={filteredWards} headers={headers} footer={<TableFooterTotalSum colspan={3} content={`Summe: ${MinutesFormatter(totalMinutes)}`} />} />
		</>
	);
};

export default StatsWards;
