import React, { useMemo, useState } from "react";

import { Trash, PlusCircle, Pencil, Funnel } from "react-bootstrap-icons";
import { useAlert } from "react-alert";
import { escapeRegExp } from "lodash";

import PageHeader from "components/shared/PageHeader";
import SortableTable from "components/shared/SortableTable";
import YesNoModal from "components/shared/YesNoModal";
import Shield from "components/shared/Shield";
import PhoneNumberFormatter from "formatters/PhoneNumberFormatter";
import SearchField          from "components/shared/SearchField";
import NoResult from "components/shared/NoResult"
import usePBStore from "../hooks/usePBStore";
import DialcodeEdit from "./modals/DialcodeEdit";
import Spinner from "components/shared/Spinner";

const DialcodesList = () => {
	const alert = useAlert();
	const [dialcode, setDialcode] = usePBStore(store => [store.dialcode, store.dialcodeSet]);
	const dialcodes = usePBStore(store => store.dialcodes);
	const [showEdit, setShowEdit] = usePBStore(store => [store.showDialcodesEdit, store.showDialcodesEditSet]);
	const [showDelete, setShowDelete] = usePBStore(store => [store.showDialcodesDelete, store.showDialcodesDeleteSet]);
	const ready = usePBStore(store => store.ready);
	
	const [filters, setFilters]             = useState([]);
	const [recentFilters, setRecentFilters] = useState({});
	const [search, setSearch]               = useState("");
	const orgUnits                          = usePBStore(store => store.orgUnits);

	const deleteDialcode = usePBStore(store => store.dialcodeDelete);

	console.log()

	const headers = useMemo(() => [
		{
			label: "Name",
			member: "name"
		},
		{
			label: "Telefon",
			member: null,
			sortable: false,
			functional: ({member}) => {
				if (!member)
					return null;
				if (member.length === 0)
					return null
				return member.contact.map((contact, index) => {
					if(!contact.phonenumber)
						return null
					return (
						<p key={contact.id || index} className="m-0">
							{(contact.phonenumber)}
							<span className="badge bg-secondary ml-3">{contact.label?.data?.attributes?.name || contact.label?.name}</span>
						</p>
					);
				});
			}
		},
		{
			label: "Mobil",
			member: null,
			sortable: false,
			functional: ({member}) => {
				if (!member)
					return null;
				if (member.length === 0)
					return null
				return member.contact.map((contact, index) => {
					if(!contact.mobilenumber)
						return null
					return (
						<p key={contact.id || index} className="m-0">
							{(contact.mobilenumber)}
							<span className="badge bg-secondary ml-3">{contact.label?.data?.attributes?.name || contact.label?.name}</span>
						</p>
					);
				});
			}
		},
		{
			label: "Fax",
			member: null,
			sortable: false,
			functional: ({member}) => {
				if (!member)
					return null;
				if (member.length === 0)
					return null
				return member.contact.map((contact, index) => {
					if(!contact.faxnumber)
						return null
					return (
						<p key={contact.id || index} className="m-0">
							{(contact.faxnumber)}
							<span className="badge bg-secondary ml-3">{contact.label?.data?.attributes?.name || contact.label?.name}</span>
						</p>
					);
				});
			}
		},
		{
			label: "Ort",
			member: null,
			sortable: false,
			functional: ({ member }) => {
				if (!member.address)
					return null;
				return (
					<>
						{member.address.street}<br />
						{member.address.zip} {member.address.city}
					</>
				)
			}
		},
		{
			label: "Notiz",
			member: "note"
		},
		{
			label: "",
			member: null,
			sortable: false,
			functional: ({ member }) => {
				return (
					<section className="mt-2">
						{member.organisation?.map(orgValue => {
							return <Shield tag={orgValue.unit?.name} value={orgValue.value} key={orgValue.id} className="warning" />
						})}
					</section>
				);
			}
		},
		{
			label: "",
			member: null,
			sortable: false,
			width: "7%",
			functional: ({ member }) => {
				return (
					<>
						<button className="btn" onClick={() => { setDialcode(member); setShowEdit(true) }}><Pencil /></button>
						<button className="btn" onClick={() => { setDialcode(member); setShowDelete(true) }}><Trash /></button>
					</>
				)
			}
		}
	], []);

	const filteredDialcodes = useMemo(() => {
		const isFiltered = Object.values(filters).filter(item => item !== null && item !== "").length > 0;
		if (search === "" && !isFiltered)
			return dialcodes;
		
		const regex = new RegExp(`.*${escapeRegExp(search)}.*`, "gi");
		
		let newFilteredDialcodes = null;

		newFilteredDialcodes = dialcodes.filter(dialcode => 
			dialcode.name?.match(regex) ||
			dialcode.note?.match(regex)  ||
			dialcode.address?.city?.match(regex)  ||
			dialcode.address?.street?.match(regex)  ||
			dialcode.address?.zip?.match(regex)  ||
			(dialcode.contact?.filter((contact) => {
				if (contact.phonenumber?.match(regex) || contact.mobilenumber?.match(regex) || contact.fax?.match(regex) || contact.email?.match(regex))
					return true;
				else
					return false;
			}).length > 0) ||
			dialcode.organisation?.filter(item => item.value.match(regex)).length > 0
		);
		newFilteredDialcodes = newFilteredDialcodes.filter(dialcode => filters.every(item => dialcode?.organisation?.map(org => org.id.toString()).includes(item)));
		console.log(filters);
		return newFilteredDialcodes;
	}, [dialcodes, filters, search]);

	const filter = (orgUnit, value) => {
		const newRecentFilters = {...recentFilters};
		let   newFilters       = [...filters];

		newFilters = newFilters.filter(item => item !== recentFilters[orgUnit]);

		if (value === "")
			delete newRecentFilters[orgUnit];
		else {
			newRecentFilters[orgUnit] = value;
			newFilters.push(value);
		}

		setFilters(newFilters);
		setRecentFilters(newRecentFilters);
	};

	return (
		<>
			<PageHeader title="Kurzwahlen (weitere Treffer)" textNew="Kurzwahl anlegen" Icon={PlusCircle} actionNew={() => { setDialcode(null); setShowEdit(true) }}>
			<section className="grid three-thirds">
					<SearchField search={search} setSearch={setSearch} />
				</section>
				{orgUnits.length > 0 ? 
					<section className="grid four-fourths">
						{orgUnits.map(orgUnit => {
							return (
								<article key={orgUnit.id}>
									<b className="mb-3">{orgUnit.name}</b><br />
									<section className="input-group">
										<div className="input-group-prepend">
											<span className="input-group-text"><Funnel /></span>
										</div>
										<select name={orgUnit.id} value={recentFilters[orgUnit.id] || ""} className="form-control" onChange={event => filter(orgUnit.id, event.target.value)}>
											<option value="">Kein Filter</option>
											{orgUnit.organisational_values?.map(orgValue => <option key={orgValue.id} value={orgValue.id}>{orgValue.value}</option>)}
										</select>
									</section>
								</article>
							);
						})}
					</section>
				: null}
			</PageHeader>
			{ready ?
			 (
				filteredDialcodes.length > 0 ?
				<SortableTable headers={headers} data={filteredDialcodes} rowCallback={dialcode => {setDialcode(dialcode); setShowEdit(true);}} />
				: ( 
				<NoResult />
				)
			 ) : <Spinner />}
			<DialcodeEdit show={showEdit} setShow={setShowEdit} dialcode={dialcode} />
			<YesNoModal title="Kurzwahl löschen" text={`Möchten Sie die Kurzwahl "${dialcode?.name}" wirklich löschen?`} show={showDelete} setShow={setShowDelete} callback={() => deleteDialcode(alert)} />
		</>
	);
};

export default DialcodesList;
