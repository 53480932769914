import React, {useEffect} from "react";
import axios from "axios";

import {useAlert} from "react-alert";

import {StrapiRequest} from "utils/StrapiUtils";

import Modal   from "components/shared/Modal"
import useForm from "components/hooks/useForm";
import Toggle  from "components/shared/Toggle";

const DefaultFormData = {
	name     : "",
	slug     : "",
	capacity : "",
	active   : true,
	location : "",
	extras	 : "",
	notify  : "",
	catering : ""
};

const RoomsEdit = ({show, setShow, onHide, fetchData, currentRoom}) => {
	const alert                          = useAlert();
	const [formData, handleInput, clear] = useForm(DefaultFormData);

	const save = () => {
		const saveMethod = currentRoom ? axios.put : axios.post;
		const saveURL    = currentRoom ? `/rb-rooms/${currentRoom.id}` : "/rb-rooms";
		saveMethod(saveURL, StrapiRequest(formData)).then(() => {
			alert.success(`Der Raum '${formData.name}' wurde erfolgreich ${currentRoom ? "aktualisiert" : "angelegt"}.`)
			clear();
			fetchData();
			setShow(false);
		}).catch((error) => {
			alert.error(`Fehler beim ${currentRoom ? "Aktualisieren" : "Anlegen"} des Raums '${formData.name}': ${error}`)
			console.log(error);
		})
	};

	useEffect(() => {
		if (!currentRoom)
			clear();
		else{
			console.log(currentRoom);
			clear(() => ({...currentRoom}));
		}
	}, [currentRoom, clear]);

	return (
		<>
			<Modal show={show} onHide={onHide} size="lg" scrollable={true}>
				<Modal.Header>
					<Modal.Title>{currentRoom?formData.name:'Neuer Raum'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<section className="grid form">
						<label>Aktiv</label>
						<Toggle name="active" checked={formData.active} onChange={handleInput} label="" id="active" />

						<label>Name</label>
						<input className="form-control" name="name" onChange={handleInput} value={formData.name} required/>

						<label>Raumnummer</label>
						<input className="form-control" name="slug" onChange={handleInput} value={formData.slug} required/>
						
						<label>Personenkapazität</label>
						<input className="form-control" type="number" name="capacity" onChange={handleInput} value={formData.capacity} />

						<label>Raumverwaltung</label>
						<input className="form-control" name="notify" onChange={handleInput} value={formData?.notify} />

						<label>Bewirtung</label>
						<input className="form-control"  name="catering" onChange={handleInput} value={formData?.catering} />

						<label>Ort</label>
						<input className="form-control" name="location" onChange={handleInput} value={formData.location}/>

						<label>Raum Informationen</label>
						<textarea className="form-control" name="extras" onChange={handleInput} value={formData.extras} rows="5"/>
					</section>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-outline-danger" onClick={() => {
						clear(); 
						setShow(false);
					}}>Abbrechen</button>
					<button className="btn btn-success" onClick={save}>Raum {currentRoom ? "speichern" : "anlegen"}</button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default RoomsEdit;
