import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useAlert } from "react-alert";

import { Modal } from "react-bootstrap";
import useForm from "components/hooks/useForm";
import useHHStore from "../hooks/useHHStore";

const InitialFormData ={
	name: "",
	extras: ""
};

const CustomerEdit = ({ customer, show, setShow }) => {
    const alert = useAlert();
    const [formData, handleInput, clear] = useForm(InitialFormData);
    
	const save = useHHStore(store => store.customerSave);

	useEffect(() =>{
		if(!customer){
			clear();
			return;
		}
		clear(structuredClone(customer));
	},[customer,show]);

    return (
        <>
            <Modal size="lg" scrollable={true} show={show}>
                    <Modal.Header>
                        <Modal.Title>
                            {customer === null ? "Kunde anlegen" : "Kunde bearbeiten"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <section className="row">
                            <section className="col-md-3">Name</section>
                            <section className="col-md-9">
                                <input className="form-control" onChange={handleInput} type="text" name="name" value={formData.name} required />
                            </section>
                        </section>
                        <section className="row">
                            <section className="col-md-3">Extra</section>
                            <section className="col-md-9">
                                <input className="form-control" onChange={handleInput} type="text" name="extras" value={formData.extras} />
                            </section>
                        </section>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn btn-outline-danger" onClick={() => {
                            clear();
                            setShow(false);
                        }}>Abbrechen</span>
                        <button className="btn btn-success"
						onClick={() => {
							save(formData,alert);
							setShow(false);
						}}
						>Speichern</button>
                    </Modal.Footer>
            </Modal>
        </>
    );
}

export default CustomerEdit;