import React, {useRef, useEffect, useState, useMemo} from "react";

import useCommandPalette from "./hooks/useCommandPalette";

const MAX_LENGTH = 10;

const CommandPalette = () => {
	const commands                                = useCommandPalette(palette => palette.commands);
	const [filteredCommands, setFilteredCommands] = useState([]);
	const [keyword, setKeyword]                   = useState("");
	const keywordField                            = useRef();
	const [show, setShow]                         = useState(false);
	const [current, setCurrent]                   = useState(0);

	const maxLength = useMemo(() => {
		return commands.length < MAX_LENGTH ? commands.length : MAX_LENGTH;
	}, [commands]);

	useEffect(() => {
		if (!keyword || keyword === "")
			setFilteredCommands(commands.slice(0, MAX_LENGTH));

		const regex = new RegExp(`.*${keyword}.*`, "gi");
		setFilteredCommands(commands.filter(item => item.label.match(regex)));
	}, [keyword, commands]);

	useEffect(() => {
		if (!show)
			setCurrent(0);
	}, [show]);

	useEffect(() => {
		const keyListener = event => {
			switch(event.key) {
				case "Escape":
					setShow(false);
					break;
				case "p":
					if (!event.metaKey)
						break;
					event.preventDefault();
					setKeyword("");
					setShow(true);
					keywordField.current.focus();
					break;
				case "ArrowUp":
					setCurrent(current => current > 0 ? current - 1 : maxLength - 1);
					break;
				case "ArrowDown":
					setCurrent(current => current >= maxLength - 1 ? 0 : current + 1);
					break;
				case "Enter":
					if (!show)
						break;
					if (commands[current].callback) {
						commands[current].callback();
						setShow(false);
					}
					break;
				default:
					break;
			}
		};

		document.addEventListener("keydown", keyListener);

		return () => {
			document.removeEventListener("keydown", keyListener);
		}
	}, [keywordField, current, show, commands, maxLength]);

	if (!show)
		return null;

	return (
		<section className="command-palette">
			<section className="palette">
				<input type="text" name="keyword" ref={keywordField} className="form-control" placeholder="Nach Aktionen suchen..." value={keyword} onChange={event => setKeyword(event.target.value)} />
				<section className="matches">
					{filteredCommands.length === 0 ? 
						<label>keine Übereinstimmung gefunden</label>
					: filteredCommands.map((command, index) => <label key={index} className={index === current ? "active" : ""}>{command.label} {command.module ? <span className="badge badge-secondary">{command.module}</span> : null}</label>)}
				</section>
			</section>
		</section>
	);
};

export default CommandPalette;