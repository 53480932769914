import React, {useEffect, useState, useCallback, useMemo} from "react";
import axios from "axios";

import {Building, Pencil, Trash, BoxArrowUpRight} from "react-bootstrap-icons";
import {useAlert} from "react-alert";

import SortableTable        from "../../../../components/shared/SortableTable";
import YesNoModal           from "../../../../components/shared/YesNoModal";
import Spinner              from "../../../../components/shared/Spinner";
import PhoneNumberFormatter from "../../../../formatters/PhoneNumberFormatter";
import {StrapiResponse}     from "utils/StrapiUtils";

import SearchField          from "../forms/SearchField";

import WardEdit from "../modals/WardEdit";

const Wards = () => {
	const alert                             = useAlert();
	const [ready, setReady]                 = useState(false);
	const [wards, setWards]                 = useState([]);
	const [ward, setWard]                   = useState(null);
    const [filteredWards, setFilteredWards] = useState([]);
    const [showEdit, setShowEdit]           = useState(false);
    const [showDelete, setShowDelete]       = useState(false);
	const [search, setSearch]               = useState("");

    const load = useCallback(() => {
	    axios.get("/wards?_sort=name:ASC").then(response => {
	    	setReady(true);
		    setWards(StrapiResponse(response));
	    }).catch(error => alert.error(`Fehler beim Laden der Einsatzorte: ${error}`));
    }, [alert]);

	useEffect(() => {
		setFilteredWards(search === "" ? wards : wards.filter(ward => ward.name.match(new RegExp(`${search}`, "i"))));
	}, [search, wards]);

    useEffect(() => {
    	load();
	}, [load]);

    const headers = useMemo(() => [
	    {
	        label    : "Id",
		    member   : "id",
		    width    : "90px",
		    sortable : false
	    },
	    {
		    label     : "",
		    member    : "external",
		    width     : "50px",
		    formatter : value => value === "true" ? <BoxArrowUpRight className="text-primary" style={{opacity : 1}} title="extern" /> : ""
	    },
	    {
			label    : "Bezeichnung",
			member   : "name",
            sortable : true,
        },
        {
			label     : "Abteilungsleiter",
			member    : "leader",
			sortable  : true,
	        formatter : value => value ? value : "-"
		},
		{
			label     : "Telefonnummer",
			member    : "phone",
			sortable  : false,
			formatter : PhoneNumberFormatter
        },
        {
			label     : "E-Mail",
			member    : "email",
			sortable  : false
		},
	    {
	    	label      : "",
		    member     : null,
		    sortable   : false,
		    width      : "90px",
		    functional : ({member}) => {
	    		return (
	    			<>
					    <button className="btn" onClick={event => {setWard(member); setShowEdit(true);}} title="Bearbeiten"><Pencil /></button>
					    <button className="btn" onClick={() => {
						    setWard(member);
						    setShowDelete(true);
					    }} title="Löschen">
						    <Trash />
					    </button>
				    </>
			    )
		    }
	    }
	], []);

    const deleteWard = useCallback(() => {
    	if (!ward)
    		return;

    	axios.delete(`/wards/${ward.id}`).then(() => {
    		load();
	    }).catch(error => alert.error(`Fehler beim Löschen des Einsatzortes: ${error.response}`) && console.log(error.response));
	}, [load, alert, ward]);

    return (
            <>
                <h2 className="py-4" >Einsatzorte <button className="btn btn-success btn-sm float-right" onClick={() => {setWard(null); setShowEdit(true)}}><Building className="big" />  Einsatzort anlegen  </button> </h2>
				<section className="filter grid three-thirds">
					<SearchField search={search} setSearch={setSearch} />
				</section>
				{ready ?
					<SortableTable data={filteredWards} headers={headers} />
				: <Spinner /> }
                
                <YesNoModal show={showDelete} setShow={setShowDelete} callback={() => deleteWard()} title="Einsatzort löschen" text={`Den Einsatzort '${ward?.name}' wirklich löschen?`} />
                <WardEdit show={showEdit} setShow={setShowEdit} ward={ward} callback={() => load()} />
            </>
    );
}

export default Wards;
