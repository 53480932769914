import React, { useEffect} from "react";

import { useAlert } from "react-alert";


import { Modal, Form } from "react-bootstrap";
import useHHStore from "../hooks/useHHStore";

import useForm from "components/hooks/useForm";

const InitialFormData ={
		jobID: 0,
		contractType: "Wordpress",
		contractInfo: "",
		payInterval: 12,
		terminationDate: "",
		nextBill: undefined,
		active: true,
		price: 0,
		extra: "",
		hh_kunde: null
	};

const ContractEdit = ({ contract, show, setShow}) => {

    const alert = useAlert();
    const [formData, handleInput, clear] = useForm(InitialFormData);
    const customers = useHHStore(store => store.customers);

	const save = useHHStore(store => store.contractSave);


	useEffect(() =>{
		if(!contract){
			clear();
			return;
		}
		clear(structuredClone(contract));
	},[contract,show]);

    return (
        <>
            <Modal size="lg" scrollable={true} show={show} onHide={() => setShow(false)}>
                    <Modal.Header>
                        <Modal.Title>
                            {contract === null ? "Vertrag anlegen" : "Vertrag bearbeiten"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <section className="row">
                            <section className="col-md-3">Jobnummer</section>
                            <section className="col-md-9">
                                <input className="form-control" onChange={handleInput} type="number" name="jobID" value={formData.jobID} required />
                            </section>
                        </section>
                        <section className="row">
                            <section className="col-md-3">Leistung</section>
                            <section className="col-md-9">
                                <select className="form-control" onChange={handleInput} name="contractType" value={formData.contractType} required>
                                    <option value="Wordpress">Wordpress</option>
                                    <option value="TYPO3">TYPO3</option>
                                    <option value="Mailservice">Mailservice</option>
                                    <option value="Domainhosting">Domainhosting</option>
                                    <option value="Matomo">Matomo</option>
                                    <option value="Zusatzdomain">Zusatzdomain</option>
                                    <option value="Extern">Extern</option>
                                    <option value="Anderes">Anderes</option>
                                </select>
                            </section>
                        </section>
                        <section className="row">
                            <section className="col-md-3">Leistungs Info</section>
                            <section className="col-md-9">
                                <input className="form-control" onChange={handleInput} type="text" name="contractInfo" value={formData.contractInfo} />
                            </section>
                        </section>
                        <section className="row">
                            <section className="col-md-3">Preis</section>
                            <section className="col-md-9">
                                <input className="form-control" onChange={handleInput} type="number" min="0"  name="price" value={formData.price} required />
                            </section>
                        </section>
                        <section className="row">
                            <section className="col-md-3">Zahlungsinterval</section>
                            <section className="col-md-9">
                                <input className="form-control" onChange={handleInput} type="number" name="payInterval" min="1" value={formData.payInterval} required />
                            </section>
                        </section>
                        <section className="row">
                            <section className="col-md-3">Nächste Rechnung</section>
                            <section className="col-md-9">
                                <input className="form-control" onChange={handleInput} type="date" name="nextBill" value={formData.nextBill} required />
                            </section>
                        </section>
                        <section className="row">
                            <section className="col-md-3">Vertragsend</section>
                            <section className="col-md-9">
                                <input className="form-control" onChange={handleInput} type="date" name="terminationDate" value={formData.terminationDate}/>
                            </section>
                        </section>
                        <section className="row">
                            <section className="col-md-3">Aktiv</section>
                            <section className="col-md-9">
                                <Form.Check type="switch" name="active" onChange={handleInput} checked={formData.active} label="" id="active" />
                            </section>
                        </section>
                        <section className="row">
                            <section className="col-md-3">Extras</section>
                            <section className="col-md-9">
                                <input className="form-control" onChange={handleInput} type="text" name="extra" value={formData.extra} />
                            </section>
                        </section>
                        <section className="row">
                            <section className="col-md-3">Kunde</section>
                            <section className="col-md-9">
                                <select className="form-control" onChange={handleInput} name="hh_kunde" value={formData.hh_kunde? formData.hh_kunde.id:""} required>
                                <option value="">Kunden Auswählen</option>
                                    {customers.map((customer,key) => {
                                        return (<option key={key} value={customer.id}>{customer.name}</option>
                                        )
                                    })}
                                </select>
                            </section>
                        </section>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn btn-outline-danger" onClick={() => {
                            clear();
                            setShow(false);
                        }}>Abbrechen</span>
                        <button className="btn btn-success"
						onClick={()=>{
							save(formData,alert);
							setShow(false);
						}}
						>Speichern</button>
                    </Modal.Footer>
            </Modal>
        </>
    );
}

export default ContractEdit;