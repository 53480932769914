import React, {useEffect, useState, useRef} from "react";

import {DashCircleFill, CheckCircleFill, XCircle, CardList, Building, Save, TrashFill} from "react-bootstrap-icons";

import Modal                 from "components/shared/Modal";
import useForm               from "components/hooks/useForm";
import {useAlert}            from "react-alert";
import {ExclamationTriangle} from "react-bootstrap-icons";

import usePBStore from "modules/phonebook/hooks/usePBStore";

const InitialFormData = Object.freeze({
	name : "",
	slug : ""
});

const DeleteButtonState = Object.freeze({
	NORMAL : 1,
	DELETE : 2
});

const OrgUnitDeleteButton = ({onClick = () => {}, ...props}) => {
	const [state, setState] = useState(DeleteButtonState.NORMAL);

	if (state === DeleteButtonState.NORMAL) {
		return (
			<>
				<button className="btn text-danger" title="Wert löschen" onClick={() => setState(DeleteButtonState.DELETE)} {...props}><DashCircleFill /></button>
			</>
		);
	} else {
		return (
			<>
				<button className="btn text-secondary confirm" title="abbrechen" onClick={() => setState(DeleteButtonState.NORMAL)} {...props}><XCircle /></button>
				<button className="btn text-danger confirm" title="Wert endgültig löschen" onClick={onClick} {...props}><TrashFill /></button>
			</>
		);
	}

};

const OrgUnitEdit = ({show, setShow}) => {
	const field                                   = useRef();
	const alert                                   = useAlert();
	const orgUnit                                 = usePBStore(store => store.orgUnit);
	const [formData, handleInput, clear, changed] = useForm(InitialFormData);
	const removeOrgValue                          = usePBStore(store => store.orgValueRemove);
	const addOrgValue                             = usePBStore(store => store.orgValueAdd);
	const [value, setValue]                       = useState("");
	const [slug, setSlug]                         = useState("");
	const deletingValue                           = usePBStore(store => store.deletingValue);
	const save                                    = usePBStore(store => store.orgUnitSave);

	useEffect(() => {
		if (!orgUnit) {
			clear("");
			return;
		}
		clear({...orgUnit});
	}, [orgUnit, clear]);

	const enterListener = event => {
		if (event.key !== "Enter")
			return;
		setValue(oldValue => {
			addOrgValue({value : oldValue, slug}, alert);
			setSlug("");
			field.current?.focus();
			return "";
		});
	};

	return (
		<Modal show={show} onHide={() => {setShow(false)}} scrollable={true}>
			<Modal.Header>
				<Modal.Title><Building className="big" /> Organisationseinheit {orgUnit ? "bearbeiten" : "anlegen"}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="grid form">
					<label>Name</label>
					<input type="text" name="name" className="form-control" value={formData.name} onChange={handleInput} />
				</section>
				{orgUnit ? 
				<>
					{orgUnit.organisational_values?.length > 0 ?
					<>
						<hr />
						<h4>Werte</h4>
						<section className="value-card">
						{[...orgUnit?.organisational_values].sort((a, b) => a.value && b.value ? a.value.localeCompare(b.value) : -1)?.map((value, index) => {
							return (
								<p key={value.id || index} style={{margin: 0}}>
									<OrgUnitDeleteButton onClick={() => removeOrgValue(value, alert)} disabled={deletingValue} />
									<span style={{verticalAlign  : "middle"}}>{value.value} {value.slug ? <small>[{value.slug}]</small> : null}</span>
								</p>
							);
						})}
						</section>
						<p className="alert alert-warning mt-3 grid ten-ninety">
							<ExclamationTriangle style={{fontSize : "2.5rem", alignSelf : "center"}} />
							<small>
								<i>
									Das Löschen eine Wertes entfernt diesen von allen Benutzern, denen er bereits zugewiesen ist.<br />
									<b>
										Dieser Vorgang kann nicht rückgängig gemacht werden.
									</b>
								</i>
							</small>
						</p>
					</>
					: null }
					<hr />
					<section>
						<div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text"><CardList /></span>
						</div>
						<input type="text" className="form-control" placeholder="Wert hinzufügen..." onKeyDown={enterListener} onChange={event => setValue(event.target.value)} value={value} style={{flex : 3}} ref={field} />
						<input type="text" className="form-control" placeholder="Kurzform" onKeyDown={enterListener} onChange={event => setSlug(event.target.value)} value={slug} />
						</div>
					</section>
					</> : null }
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-outline-danger" onClick={() => {setShow(false)}}>Abbrechen</button>
				<button className="btn btn-success" onClick={() => { save(formData, alert); clear(); setValue(""); setShow(false);}} disabled={!changed || formData.name.length < 3}><Save /> Speichern</button>
			</Modal.Footer>
		</Modal>
	);
};

export default OrgUnitEdit;