import React, {useState, useEffect} from "react";
import axios from "axios";

import {useAlert} from "react-alert";
import {Modal}    from "react-bootstrap";
import {Star}     from "react-bootstrap-icons";

import Select       from "../../../../components/shared/Select";
import DateInput    from "../../../../components/shared/DateInput";
import {StrapiDate} from "../../../../utils/StrapiUtils";

import {StrapiRequest} from "../../../../utils/StrapiUtils";

const today = new StrapiDate();

const HourEntryEdit = ({show, setShow, onChange, student, hours, hourEntry, categories}) => {
	const alert                   = useAlert();
	const [formData, setFormData] = useState({
		hours       : 0,
		minutes     : 0,
		description : "",
		date        : today
	});

	useEffect(() => {
		if (!hourEntry)
			return;

		setFormData({
			...hourEntry,
			category : hourEntry.category.id,
			hours    : parseInt(hourEntry.duration_minutes / 60),
			minutes  : hourEntry.duration_minutes % 60,
			date     : new StrapiDate(hourEntry.date)
		});
	}, [hourEntry]);

	const handleInput = event => {
		const newData = {...formData};
		newData[event.target.name] = event.target.value;
		setFormData(newData);
	}

	const save = () => {
		axios.put(`/hour-entries/${hourEntry.id}`, StrapiRequest({
			...formData,
			student,
			duration_minutes : parseInt(formData.hours) * 60 + parseInt(formData.minutes)
		})).then(() => {
			alert.success("Der Eintrag wurde erfolgreich geändert.");
			setShow(false);
			onChange();
		}).catch(error => alert.error(`Fehler beim Speichern des Eintrags: ${error}`));
	}

	return (
		<Modal show={show} size="lg" className="hours-edit">
			<Modal.Header>
				<Modal.Title>
					<span style={{verticalAlign : "middle", marginRight : "2rem"}}>Stundeneintrag bearbeiten</span> {hourEntry?.special ? <Star className="text-primary" style={{verticalAlign : "middle"}} /> : ""}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p className="alert alert-primary">
					Anleiter war {hourEntry?.special ? `${hourEntry.user.firstname} ${hourEntry.user.lastname}` : `${hourEntry?.chief_firstname} ${hourEntry?.chief_lastname}`}.
					Dieser Anleiter ist ein <b>{hourEntry?.special ? "Praxisanleiter" : "regulärer Anleiter"}</b> am Einsatzort <i>{hourEntry?.ward?.name}</i>.
				</p>
				<p>
				</p>
				<section className="grid three-thirds">
					<label>Datum</label>
					<DateInput value={formData.date} onChange={handleInput} name="date" event={true} className="form-control full" />
					<label>Kategorie</label>
					<Select data={categories} current={formData.category} defaultText="Kategorie auswählen" name="category" onChange={handleInput} />
					<label>Anleitungsdauer</label>
					<div className="input-group" style={{gridColumn : "2 / span 0.5"}}>
						<input type="number" name="hours" value={formData.hours} className="form-control" placeholder="Stunden" max={10} onChange={handleInput} required />
						<div className="input-group-append">
							<span className="input-group-text">h</span>
						</div>
					</div>
					<div className="input-group" style={{gridColumn : "2 / span 0.5"}}>
						<input type="number" name="minutes" value={formData.minutes} className="form-control" placeholder="Minuten" max={59} onChange={handleInput} required />
						<div className="input-group-append">
							<span className="input-group-text">m</span>
						</div>
					</div>
					<label>Beschreibung</label>
					<textarea className="form-control" name="description" onChange={handleInput} value={formData.description} rows={5}>
					</textarea>
				</section>
			</Modal.Body>
			<Modal.Footer>
					<span className="btn btn-outline-danger" onClick={() => {
						setShow(false);
					}}>Abbrechen</span>
				<button className="btn btn-success" onClick={() => save()}>Speichern</button>
			</Modal.Footer>
		</Modal>
	);
};

export default HourEntryEdit;
