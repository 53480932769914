import React, {useEffect, useRef} from "react";

import {Tag, Save, Tags} from "react-bootstrap-icons";

import Modal      from "components/shared/Modal";
import useForm    from "components/hooks/useForm";
import usePBStore from "modules/phonebook/hooks/usePBStore";
import {useAlert} from "react-alert";

const InitialFormData = {
	name : ""
};

const LabelEdit = ({show, setShow, label}) => {
	const alert                          = useAlert();
	const field                          = useRef();
	const [formData, handleInput, clear] = useForm(InitialFormData);
	const save                           = usePBStore(store => store.labelSave);

	useEffect(() => {
		if (!label) {
			clear();
			return;
		}
		clear(structuredClone(label));
	}, [clear, label, show]);

	useEffect(() => {
		if (!show)
			return;

		setTimeout(() => {
			console.log("SHOW");
			field.current.focus();
		}, 500);
	}, [show, field]);

	return (
		<Modal show={show} onHide={() => setShow(false)}>
			<Modal.Header>
				<Modal.Title><Tag className="big" /> Label {label ? "bearbeiten" : "erstellen"}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="grid form">
					<label>Name</label>
					<input type="text" name="name" value={formData.name} onChange={handleInput} className="form-control" ref={field} />
				</section>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-outline-danger" onClick={() => { clear(); setShow(false); }}>Abbrechen</button>
				<button className="btn btn-success" onClick={() => save(formData, () => setShow(false), alert)}>{label ? <Save /> : <Tags />} {label ? "Speichern" : "Anlegen"}</button>
			</Modal.Footer>
		</Modal>
	);
};

export default LabelEdit;