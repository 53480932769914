import React, {useState, useEffect, useCallback} from "react";

import {ChevronLeft, Pencil, CalendarEvent, Trash, Star, Briefcase} from "react-bootstrap-icons";
import {useAlert}           from "react-alert";
import {NavLink, useParams} from "react-router-dom";
import axios                from "axios";

import Spinner          from "../../../components/shared/Spinner";
import SortableTable    from "../../../components/shared/SortableTable";
import DateFormatter    from "../../../formatters/DateFormatter";
import MinutesFormatter from "../../../formatters/MinutesFormatter";

import HourEntryEdit                   from "./modals/HourEntryEdit";
import YesNoModal                      from "../../../components/shared/YesNoModal";
import {StrapiRequest, StrapiResponse} from "utils/StrapiUtils";

import _ from "lodash";
import NoResult from "components/shared/NoResult";

const HoursEdit = () => {
	const headers = [
		{
			label  : "Id",
			member : "id",
			width  : "90px"
		},
		{
			label      : "",
			member     : null,
			sortable   : false,
			functional : ({member}) => {
				return (
					member.special ? <span title="Praxisanleiter"><Star className="text-primary" style={{opacity : 1}} /></span> : ""
				)
			},
			width      : "30px"
		},
		{
			label     : "Datum",
			member    : "date",
			formatter : DateFormatter,
			width     : "10%"
		},
		{
			label      : "Anleiter",
			member     : null,
			sortable   : false,
			width      : "10%",
			functional : ({member}) => {
				return (
					<span> {
						member.special && member.user ? `${member.user.firstname} ${member.user.lastname}` :
							`${member.chief_firstname} ${member.chief_lastname}`
					}</span>
				)
			}
		},
		{
			label  : "Einsatzort",
			member : "ward.name",
			width  : "10%"
		},
		{
			label     : "Dauer",
			member    : "duration_minutes",
			sortable  : false,
			width     : "5%",
			formatter : MinutesFormatter
		},
		{
			label  : "Kategorie",
			member : "category.name",
			width  : "15%"
		},
		{
			label     : "Beschreibung",
			member    : "description",
			formatter : value => <span style={{fontSize : "0.8rem", color : "#aaa"}}>{value}</span>
		},
		{
			label      : "",
			member     : null,
			sortable   : false,
			width      : "90px",
			functional : ({member}) => {
				return (
					<>
						<button className="btn" onClick={() => {
							setHourEntry(member);
							setShow(true);
						}}><Pencil /></button>
						<button className="btn" onClick={() => {
							setHourEntry(member);
							setShowDelete(true);
						}}><Trash /></button>
					</>
				)
			},
		}
	];

	const {id}                        = useParams();
	const alert                       = useAlert();
	const [ready, setReady]           = useState(false);
	const [student, setStudent]       = useState(null);
	const [hours, setHours]           = useState([]);
	const [filtered, setFiltered]     = useState([]);
	const [hourEntry, setHourEntry]   = useState(null);
	const [show, setShow]             = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [categories, setCategories] = useState([]);
	const [deployment, setDeployment] = useState("");

	const load = useCallback(() => {
		axios.get(`/students/${id}`).then(response => {
			setStudent(StrapiResponse(response));
			axios.get(`/hour-entries?_sort=date:DESC&filters[student][id]=${id}&populate=*`).then(response => {
				setHours(StrapiResponse(response));
				setReady(true);
			}).catch(error => alert.error(`Fehler beim Laden der Stundeneinträge: ${error}`));
		}).catch(error => alert.error(`Fehler beim Laden der Daten des Auszubildenden: ${error}`));
		axios.get("/categories").then(response => setCategories(StrapiResponse(response)))
			.catch(error => alert.error(`Fehler beim Laden der Kategorien: ${error}`));
	}, [alert, id]);

	useEffect(() => {
		load();
	}, [load]);

	const del = () => {
		axios.delete(`/hour-entries/${hourEntry.id}`).then(() => {
			alert.success("Der Eintrag wurde erfolgreich gelöscht");
		}).catch(error => alert.error(`Fehler beim Löschen des Stundeneintrags: ${error}`));
		load();
	}

	useEffect(() => {
		setFiltered(deployment === "" ? hours : hours.filter(entry =>entry.deployment.id == deployment));
	}, [deployment, hours]);

	if (!ready)
		return (
			<>
				<h2 className="py-4">
					<button className="btn bg-transparent"><ChevronLeft className="big" /></button>
					<span style={{verticalAlign : "middle"}}>
					Stundeneinträge
				</span>
				</h2>
				<Spinner />
			</>
		);

	return (
		<>
			<h2 className="py-4">
				<NavLink to="/PracticeChief/students">
					<button className="btn bg-transparent"><ChevronLeft className="big" /></button>
				</NavLink>
				<span style={{verticalAlign : "middle"}}>
					Stundeneinträge für {student.firstname} {student.lastname}
				</span>
				<NavLink to="/PracticeChief/new">
					<button className="btn btn-success btn-sm float-right" onClick={() => { setHourEntry(null); setShow(true); }}>
						<CalendarEvent className="big" />
						Stundeneintrag anlegen
					</button>
				</NavLink>
			</h2>
			<section className="grid three-thirds">
				<div>
					<div className="input-group">
						<div className="input-group-prepend">
							<div className="input-group-text" title="Einsatz auswählen"><Briefcase /></div>
						</div>
						<select name="deployment" style={{fontWeight : "normal", fontSize : "1rem"}} value={deployment} className="form-control" onChange={event => setDeployment(event.target.value)}>
							<option value="">alle</option>
							{_.uniqBy(hours.map(entry => { return {...entry.deployment, ward : entry.ward?.name}; }), "id").map((deployment, index) => {
								return (
									<option value={deployment.id} key={index}>{deployment.ward} ({DateFormatter(deployment.from)} – {DateFormatter(deployment.to)})</option>
								);
							})}
						</select>
					</div>
				</div>
			</section>
			{hours.length > 0 ?
				<SortableTable data={filtered} headers={headers} />
			: <NoResult>keine Stundeneinträge vorhanden</NoResult>}
			<HourEntryEdit show={show} setShow={setShow} hourEntry={hourEntry} onChange={() => load()} student={student} categories={categories} />
			<YesNoModal
				show={showDelete}
				setShow={setShowDelete}
				callback={() => del()}
				text={`Möchten Sie den Stundeneintrag mit der Id '${hourEntry?.id}' wirklich löschen?`}
				title="Eintrag löschen"/>
		</>
	);
}

export default HoursEdit;
