import React from "react";

import {Calendar3} from "react-bootstrap-icons";

import DatePicker from "./DatePicker";

const DateInput = ({value, onChange = () => {}, className = "", name = "", placeholder, required = false, ...args}) => {

	return (
		<>
		<div className="input-group">
			<div className="input-group-prepend">
				<div className="input-group-text"><Calendar3 /></div>
			</div>
			<DatePicker
				required={required}
				value={value}
				className={`form-control ${className}`}
				placeholder={placeholder}
				name={name}
				onChange={value =>	onChange(value)} {...args} />
		</div>
		</>
	);
};

export default DateInput;
