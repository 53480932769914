import React, {useMemo, useEffect, useCallback, useContext, useRef, useState} from "react";

import {
	CircleFill,
	Telephone,
	Envelope,
	Phone,
	ArrowRightShort,
	Printer,
	X,
	TelephonePlus,
	Building,
	DashCircleFill,
	XCircleFill,
	Translate
} from "react-bootstrap-icons";

import _          from "lodash";
import {useAlert} from "react-alert";

import Shield            from "components/shared/Shield";
import DynamicTextField  from "components/shared/DynamicTextField";
import CountrySelect     from "components/shared/CountrySelect";
import StateSelect       from "components/shared/StateSelect";
import DateInput         from "components/shared/DateInput";
import SplitMenu         from "../SplitMenu";
import UserContext       from "components/UserContext";
import LanguageSelect    from "components/shared/LanguageSelect";
import {getLanguageName} from "utils/Languages";

import usePBStore from "modules/phonebook/hooks/usePBStore";
import Select from "components/shared/Select";


const UserCardEdit = ({formData, handleInput, clear}) => {
	const avatarInput             = useRef();
	const {Modules}               = useContext(UserContext);
	const alert                   = useAlert();
	const user                    = usePBStore(store => store.user);
	const labels                  = usePBStore(store => store.labels);
	const orgUnits                = usePBStore(store => store.orgUnits);
	const [wards, loadWards]      = usePBStore(store => [store.wards, store.wardsLoad]);
	const [avatar, setAvatar]     = useState("");
	const [language, setLanguage] = useState("");

	useEffect(() => {
		if (!user)
			return;
		const newData = {
			firstname     : user.firstname                              || "",
			lastname      : user.lastname                               || "",
			address       : {
				street  : user.pb_userdatum?.address?.street  || "",
				zip     : user.pb_userdatum?.address?.zip     || "",
				city    : user.pb_userdatum?.address?.city    || "",
				country : user.pb_userdatum?.address?.country || "",
			},
			avatar       : user.pb_userdatum?.avatar                   || null,
			title        : user.pb_userdatum?.title                    || "",
			company      : user.pb_userdatum?.company                  || "",
			jobtitle     : user.pb_userdatum?.jobtitle                 || "",
			ward         : user.pb_userdatum?.ward                     || "",
			birthday     : user.pb_userdatum ? user.pb_userdatum?.birthday || "" : "",
			notes        : user.pb_userdatum?.notes                    || "",
			salutation   : user.pb_userdatum?.salutation               || "",
			languages    : user.pb_userdatum?.languages                || [],
			contacts     : user.pb_userdatum?.contacts                 || [],
			organisation : user.pb_userdatum?.organisation             || [],
			state        : user.pb_userdatum?.address?.state           || ""
		};
		clear(newData);
		setAvatar(user.pb_userdatum?.avatar ? (user?.pb_userdatum?.avatar instanceof File ? window.URL.createObjectURL(user.pb_userdatum.avatar) : process.env.REACT_APP_BACKEND_URL + user.pb_userdatum.avatar?.url) : "");
	}, [user, clear]);

	const organisations = useMemo(() => {
		const out  = [];
		const orgs = _.groupBy(formData.organisation, "unit.name");
		for (const [key, values] of Object.entries(orgs))
			out.push({ key, values});
		return out;
	}, [formData]);

	const addContact = useCallback(() => {
		const newFormData = {
			...formData,
			contacts : [
				...formData.contacts,
				{
					label        : { id : "" },
					phonenumber  : "",
					mobilenumber : "",
					email        : "",
					faxnumber    : ""
				}
			]
		};
		clear(newFormData);
	}, [clear, formData]);

	useEffect(() => {
		loadWards(alert);
	}, [loadWards, alert]);

	const changeAddress = event => {
		const newFormData = {...formData};
		formData.address[event.target.name] = event.target.value;
		clear(newFormData);
	};

	const changeContact = useCallback((index, key, value) => {
		const newFormData = structuredClone(formData);
		if (key === "label")
			value = labels.filter(item => item.id === Number(value))[0];
		newFormData.contacts[index][key] = value;
		clear(newFormData);
	}, [clear, formData, labels]);

	const deleteContact = index => {
		const newFormData = {...formData};
		newFormData.contacts = newFormData.contacts.filter((_, itemTndex) => itemTndex !== index);
		clear(newFormData);
	}; 

	const changeDate = birthday => {
		const newFormData = {...formData, birthday};
		clear(newFormData);
	};

	const addOrgUnit = useCallback((unit, value) => {
		if (!unit || !value)
			return;
		const organisation = formData.organisation ? [...formData.organisation] : [];
		
		// do not add orgUnit twice
		if (organisation.filter(item => item.id === value.id).length > 0)
			return;

		organisation.push({...value, unit});

		const newFormData  = {...formData, organisation};
		clear(newFormData);
	}, [clear, formData]);

	const deleteOrgValue = useCallback(value => {
		if (!value || !formData.organisation)
			return;
		const organisation = [...formData.organisation.filter(item => item.id !== value)];
		const newFormData  = {...formData, organisation};
		clear(newFormData);
	}, [formData, clear]);
	
	const selectWard = useCallback(event => {
		if (!event.target.value || wards.length < 1)
			return;
		const newFormData = {...formData};
		const ward        = wards.filter(item => item.id === Number(event.target.value));
		
		if (ward.length < 1)
			return;
		newFormData.ward = ward[0];
		clear(newFormData);
	}, [formData, wards, clear]);

	const changeAvatar = event => {
		if (!event.target.files || event.target.files.length < 1)
			return;

		const reader = new FileReader();

		reader.onload = event => {
			setAvatar(event.target.result);
		};
		reader.readAsDataURL(event.target.files[0]);
		const newData = {...formData, avatar : event.target.files[0]};
		clear(newData);
	};

	const deleteAvatar = () => {
		const newData = {...formData};

		newData.avatar = null;
		setAvatar("");
		clear(newData);
	};

	const addLanguage = () => {
		if (formData.languages.filter(item => item.code === language).length > 0)
			return;

		const newData = {...formData};
		newData.languages.push({code : language});
		setLanguage("");
		clear(newData);
	};

	const deleteLanguage = useCallback(language => {
		clear(oldData => {
			const newData = {...oldData};
			newData.languages = newData.languages.filter(lang => lang.code.toLowerCase() !== language.code.toLowerCase());
			return newData;
		});
	}, [clear, formData.languages]);

	return (
		  <>
		  	<input type="file" ref={avatarInput} name="avatar" onChange={changeAvatar} style={{display : "none"}} accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.svg,.SVG" />
			<section>
				<section className="row av">
						<section className="col-md-3" style={{position : "relative"}}>
							{avatar && avatar !== "" ?
								<button className="btn text-danger delete" title="Avatar entfernen" onClick={deleteAvatar}><XCircleFill /></button>
							: null }
							<article className="avatar">
								{avatar && avatar !== "" ?
									<img
										src={avatar}
										alt={user?.pb_userdatum?.avatar?.name || "no name"}
										className="image__avatar"
									/>
								: <div className="no-avatar"><span>{user?.firstname.charAt(0)}{user?.lastname.charAt(0)}</span></div>}
								<section className="avatar-edit" onClick={() => avatarInput.current?.click()}>Avatar ändern</section>
							</article>
						</section>
					<section className="col-md-9" style={{
						display: "flex",
						alignItems : "center",
						padding : user?.pb_userdatum?.avatar ? "initial" : "4rem 0"
					}}>
						<section>
							<span style={{fontSize : "1.5rem", color: "#aaa"}}>
								<DynamicTextField name="company" placeholder="Firma" value={formData.company} onChange={handleInput} type="text" className="form-control inline" />
							</span><br />
							<h1 style={{fontWeight : "100"}}>
								<DynamicTextField name="firstname" placeholder="Vorname" value={formData.firstname} onChange={handleInput} type="text" className="form-control inline" />
								<DynamicTextField name="lastname"  placeholder="Nachname" value={formData.lastname} onChange={handleInput} type="text" className="form-control inline" />
								, <DynamicTextField name="title"  placeholder="Titel" value={formData.title} onChange={handleInput} type="text" className="form-control inline" />
							</h1>
							<Shield tag="Anrede" value={<DynamicTextField name="salutation" placeholder="Anrede" style={{border : "none"}} value={formData.salutation} onChange={handleInput} />} />
							<Shield tag="Funktion" className="warning" value={<DynamicTextField name="jobtitle" placeholder="Funktion" style={{border : "none"}} value={formData.jobtitle} onChange={handleInput} />} />
							{Object.keys(Modules).includes("PracticeChief") ? <Shield tag="Station" className="primary" value={<Select data={wards} name="ward" style={{border : "none", padding : "0.5rem", fontSize : "inherit", margin : 0}} current={formData.ward.id} onChange={selectWard} />} /> : null }

						</section>
					</section>
				</section>
				<section className="row">
					<section className="col-md-3 text-right text-secondary small">Aktiv</section>
					<section className="col-md">
						<CircleFill className={!user?.blocked && user?.confirmed ? "text-success" : "text-danger"} style={{fontSize : "0.8rem"}} />
					</section>
				</section>
				<section className="row">
					<section className="col-md-3 text-right text-secondary small">Id</section>
					<section className="col-md-9">
						<code>{user?.id}</code>
					</section>
				</section>
				<section>
					<hr />
				</section>
				{formData.contacts.map((contact, index) => {
					return (
						<React.Fragment key={index}>
							<section className="row contact-edit">
								<section className="col-md-3 text-right text-secondary small">
									<Select data={labels} defaultText="Label auswählen" current={contact.label?.id || ""} className="inline" onChange={event => changeContact(index, "label", event.target.value)} />
								</section>
								<section className="col-md-9" style={{lineHeight : "2rem"}}>
									<Telephone className="mr-2" />< DynamicTextField name="phonenumber" placeholder="Telefon" value={contact.phonenumber || ""} type="text" className="form-control inline" onChange={event => changeContact(index, "phonenumber", event.target.value)} /><br />
									<Phone className="mr-2" /> <DynamicTextField name="mobilenumber" placeholder="Mobil" value={contact.mobilenumber || ""} type="text" className="form-control inline" onChange={event => changeContact(index, "mobilenumber", event.target.value)} /><br />
									<Envelope className="mr-2" /> <DynamicTextField name="email" placeholder="E-Mail" value={contact.email || ""} type="text" className="form-control inline" onChange={event => changeContact(index, "email", event.target.value)} /><br />
									<Printer className="mr-2" /> <DynamicTextField name="faxnumber" placeholder="Telefax" value={contact.faxnumber || ""} type="text" className="form-control inline" onChange={event => changeContact(index, "faxnumber", event.target.value)} />
									<button className="btn delete" onClick={() => deleteContact(index)}><X /></button>
								</section>
							</section>
							<section>
								<hr />
							</section>
						</React.Fragment>
					);
				})}
				<section className="row">
					<section className="col-md-3 text-right text-secondary small"> </section>
					<section className="col-md-9">
						<button className="btn btn-outline-success" title="Kontaktdatensatz hinzufügen" onClick={() => addContact()}><TelephonePlus /> Kontaktdatensatz hinzufügen</button>
					</section>
				</section>
				
				<section>
					<hr />
				</section>
				
				<section className="row">
					<section className="col-md-3 text-right text-secondary small">Adresse</section>
					<section className="col-md-9">
						<DynamicTextField name="street" placeholder="Straße" value={formData.address.street} onChange={changeAddress} type="text" className="form-control inline" /><br />
						<DynamicTextField name="zip" placeholder="PLZ" value={formData.address.zip} onChange={changeAddress} type="text" className="form-control inline" />
						<DynamicTextField name="city" placeholder="Stadt" value={formData.address.city} onChange={changeAddress} type="text" className="form-control inline" /><br />
						<StateSelect name="state" value={formData.state} onChange={changeAddress} style={{marginBottom : "0.5rem"}} className="inline" /><br />
						<CountrySelect name="country" value={formData.address.country} onChange={changeAddress} placeholder="Land" className="inline" />
					</section>
				</section>
			</section>
			<section className="row">
				<section className="col-md-3 text-right text-secondary small">Geburtsdatum</section>
				<section className="col-md-9">
					<DateInput name="birthday" value={formData.birthday} onChange={changeDate} />
				</section>
			</section>

			<section>
				<hr />
			</section>
			<section className="row">
				<section className="col-md-3 text-right text-secondary small">Sprachen</section>
				<section className="col-md-9">
					{[...formData.languages].sort((a, b) => getLanguageName(a.code).localeCompare(getLanguageName(b.code))).map((lang, index) => {
						return (
							<span key={index} className="badge badge-secondary badge-lang-delete mr-2" onClick={() => deleteLanguage(lang)}>{getLanguageName(lang.code)}</span>
						);
					})}
					<section className="grid seventy-thirty mt-5" style={{padding : 0}}>
						<div className="input-group">
							<div className="input-group-prepend">
								<div className="input-group-text"><Translate /></div>
							</div>
							<LanguageSelect value={language} onChange={event => setLanguage(event.target.value)} disable={user?.pb_userdatum?.languages?.map(lang => lang.code)} />
						</div>
						<button className="btn btn-success" onClick={addLanguage} disabled={language === ""}>hinzufügen</button>
					</section>
				</section>
			</section>

			<section>
				<hr />
			</section>
			{organisations?.map(organisation => {
				return(
					<React.Fragment key={organisation.key}>
						<section className="row contact-edit">
							<section className="col-md-3 text-right text-secondary small">
								{organisation.key}
							</section>
							<section className="col-md-9">
								{organisation.values?.map(value => (
									<React.Fragment key={value.id}>
										<ArrowRightShort /> {typeof value === "object" ? value.value : value}
										<button className="btn text-danger scale" title="Wert löschen" onClick={() => deleteOrgValue(value.id)}><DashCircleFill /></button>
										<br />
									</React.Fragment>
								))}
							</section>
						</section>
						<section>
							<hr />
						</section>
					</React.Fragment>
				);
			})}
			<section className="row">
				<section className="col-md-3 text-right text-secondary small"> </section>
				<section className="col-md-9">
					<section className="btn-group">
						<button className="btn btn-outline-warning dropdown-toggle" title="Organisationseinheit hinzufügen" data-toggle="dropdown"><Building />  Organisationseinheit hinzufügen</button>
						<section className="dropdown-menu p-0">
							<SplitMenu orgUnits={orgUnits} onChange={addOrgUnit} />
						</section>
					</section>
				</section>
			</section>
			<section>
				<hr />
			</section>
			<section className="row">
				<section className="col-md-3 text-right text-secondary small">Notizen</section>
				<section className="col-md-9 text-muted font-italic">
					<textarea value={formData.notes} className="form-control" name="notes" onChange={handleInput} rows={5}></textarea>
				</section>
			</section>
		</>
	);
};

export default UserCardEdit;