import create  from "zustand";
import {immer} from "zustand/middleware/immer";

const useCommandPalette = create(immer((set, get) => ({
	commands      : [],
	commandBuffer : [],
	addCommand    : (label, callback, module = null) => set(state => {
		if (state.commandBuffer.includes(label))
			return;
		state.commands.push({label, callback, module});
		state.commandBuffer.push(label);
	})
})));

export default useCommandPalette;