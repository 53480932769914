import React, {useMemo} from "react";

import PageHeader    from "components/shared/PageHeader";
import SortableTable from "components/shared/SortableTable";
import usePermission from "components/hooks/usePermission";

import {Pencil, Trash, Building, InfoCircle} from "react-bootstrap-icons";

import usePBStore  from "../hooks/usePBStore";
import OrgUnitEdit from "./modals/OrgUnitEdit";

const OrgsList = () => {
	const canEdit                 = usePermission("organisational-value", "update", "organisational-value");
	const orgUnits                = usePBStore(store => store.orgUnits);
	const setOrgUnit              = usePBStore(store => store.orgUnitSet);
	const [showEdit, setShowEdit] = usePBStore(store => [store.orgUnitShowEdit, store.orgUnitShowEditSet]);

	const headers = useMemo(() => [
		{
			member : "id",
			label  : "Id",
			width  : "90px"
		},
		{
			member : "name",
			label  : "Name"
		}, 
		{
			member     : null,
			label      : "",
			sortable   : false,
			width      : "7%",
			functional : ({member}) => {
				return (
					<>
						{canEdit ? <button className="btn" title="Organisationseinheit bearbeiten" onClick={() => { setOrgUnit(member); setShowEdit(true) }}><Pencil /></button> : null }
					</>
				);
			}
		}
	], []);

	return (
		<>
			<PageHeader title="Organisationseinheiten" textNew="neue Organisationseinheit" Icon={Building} actionNew={() => { setOrgUnit(null); setShowEdit(true); }}>
			<section className="alert alert-info" style={{display : "grid", gridTemplateColumns : "50px auto"}}>
				<InfoCircle className="big" />
				<span>
					Organisationseinheiten sind Strukturwerte, die frei definiert werden können. Jeder Person können beliebig viele Werte aus jeder Organisationseinheit zugewiesen werden.
					Die möglichen Werte für eine Organisationseinheit können Sie erst anlegen, nachdem sie die Organisationseinheit angelegt haben und diese danach wieder bearbeiten.
				</span>
			</section>
			</PageHeader>
			<SortableTable headers={headers} data={orgUnits} rowCallback={orgUnit => {setOrgUnit(orgUnit); setShowEdit(true);}}/>
			<OrgUnitEdit show={showEdit} setShow={setShowEdit} />
		</>
	);
};

export default OrgsList;