import React from "react";

import {Star} from "react-bootstrap-icons";

import Spinner from "../../../components/shared/Spinner";

import SortableTable    from "../../../components/shared/SortableTable";
import DateFormatter    from "../../../formatters/DateFormatter";
import MinutesFormatter from "../../../formatters/MinutesFormatter";

const headers = [
	{
		label      : "",
		member     : null,
		sortable   : false,
		width      : "50px",
		align      : "center",
		functional : ({member}) => {
			return member.special ? <Star className="text-primary" style={{opacity : 1}} /> : "";
		}
	},
	{
		label     : "Datum",
		member    : "date",
		sortable  : false,
		formatter : DateFormatter
	},
	{
		label : "Beschreibung",
		member : null,
		sortable: false,
		functional : ({member}) => {
			return (
				<>
					<p>
						<small style={{fontStyle : "italic", color : "#888"}}>Kategorie:</small><br />
						<small>
							{member.category ? member.category.name : "–"}
						</small>
					</p>
					<p>
						{member.description}
					</p>
				</>
			)
		}
	},
	{
		label     : "Dauer",
		member    : "duration_minutes",
		sortable  : false,
		formatter : MinutesFormatter
	},
	{
		label      : "Anleiter",
		sortable   : false,
		padding    : "0.8rem",
		functional : ({member}) => {
			if (!member.chief_firstname || member.chief_firstname === "")
				return `${member.user.firstname} ${member.user.lastname}`;
			return `${member.chief_firstname} ${member.chief_lastname}`;
		}
	}
];

const HoursList = ({data}) => {

	return(
		<>
			<h3 className="py-4">zuletzt gepflegte Stundeneinträge</h3>
			{
				data ? <SortableTable data={data} headers={headers} /> :
					<Spinner />
			}
		</>
	);
};

export default HoursList;
