import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";

import { Modal } from "react-bootstrap";
import useForm from "components/hooks/useForm";
import useHHStore from "../hooks/useHHStore";


const InitialFormData ={
	domain: "",
	hh_vertrag: undefined
}


const DomainEdit = ({ domain, show, setShow}) => {
    const alert = useAlert();
    const [formData, handleInput, clear] = useForm(InitialFormData);
    const contracts = useHHStore(store => store.contracts);

	const save = useHHStore(store => store.domainSave);

    useEffect(() => {
		if(!domain){
			clear();
			return;
		}
		clear(structuredClone(domain));
    }, [domain,show]);   

    
    return (
        <>
            <Modal size="lg" scrollable={true} show={show}>
                    <Modal.Header>
                        <Modal.Title>
                            {domain === null ? "Domain anlegen" : "Domain bearbeiten"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <section className="row">
                            <section className="col-md-3">Domain</section>
                            <section className="col-md-9">
                                <input className="form-control" onChange={handleInput} type="text" name="domain" value={formData.domain} required />
                            </section>
                        </section>
                        <section className="row">
                            <section className="col-md-3">Kunde</section>
                            <section className="col-md-9">
                                <select className="form-control" onChange={handleInput} name="hh_vertrag" value={formData.hh_vertrag?formData.hh_vertrag.id:formData.hh_vertrag} required>
                                    <option value="">Kunde auswählen</option>
                                    {contracts.map((contract,key) => {
                                        return (<option key={key} value={contract.id}>[{contract.jobID}] {contract.hh_kunde.name} {contract.contractType}</option>
                                        )
                                    })}
                                </select>
                            </section>
                        </section>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn btn-outline-danger" onClick={() => {
                            setShow(false);
							clear();
                        }}>Abbrechen</span>
                        <button className="btn btn-success"
						onClick={() =>{
							save(formData,alert);
							setShow(false);
						}}
						>Speichern</button>
                    </Modal.Footer>
            </Modal>
        </>
    );
}

export default DomainEdit;