import React, {useCallback} from "react";

import {StrapiDate}         from "../../../utils/StrapiUtils";
import Spinner              from "../../../components/shared/Spinner";
import {Briefcase}          from "react-bootstrap-icons";
import {ShortDateFormatter} from "formatters/DateFormatter";

import MinutesFormatter from "formatters/MinutesFormatter";

import NoDeployment from "./notices/NoDeployment";

const DeploymentInfo = React.memo(({student, hours, deployment, create, deployments, setDeployment = () => {}}) => {
	const changeDeployment = useCallback(event => {
		setDeployment(event.target.value);
	}, [setDeployment]);

	const hasEnoughHours = student && student.currentDeployment ? student.statistics?.actualTimeInMinutes >= (student.statistics?.hoursNeededToEndOfWeek * 60) : false;

	if (!student && !create)
		return <Spinner />

	if (!deployment && !create)
		return <NoDeployment />

	if (create)
		return <></>
	return (
		<section className="deployment-info alert alert-secondary grid thirty-seventy" data-deploymentid={deployment ? deployment.id : ""}>
			<article style={{gridColumn : "span 2"}}>
				<h2 style={{borderBottom : "solid 1px"}} className="grid half-half">
					{student.firstname} {student.lastname}
					{deployments ? 
						<div>
							<div className="input-group">
								<div className="input-group-prepend">
									<div className="input-group-text" title="Einsatz auswählen"><Briefcase /></div>
								</div>
								<select name="deployment" style={{fontWeight : "normal", fontSize : "1rem"}} value={deployment.id} className="form-control" onChange={changeDeployment}>
									{Object.values(deployments).map((depl, index) => {
										const from = new StrapiDate(depl.from);
										const to   = new StrapiDate(depl.to);
										return <option value={depl.id} key={index}>{depl.ward?.name} ({ShortDateFormatter(from)} – {ShortDateFormatter(to)}){depl.id === student.currentDeployment?.id ? " ❮ aktuell" : ""}</option>
										})
									}
								</select>
							</div>
						</div>
					: null}
				</h2>
				<span className="float-right">{MinutesFormatter(hours.reduce((accumulator, current) => current.special ? accumulator + current.duration_minutes : accumulator, 0))} <b>Praxisanleiterstunden</b></span>
				<br />
				<span className="float-right">{MinutesFormatter(hours.reduce((accumulator, current) => !current.special ? accumulator + current.duration_minutes : accumulator, 0))} <b>Anleiterstunden</b></span>
			</article>
			<b>aktueller Einsatzort:</b>
			<span>
				{
					deployment ?
						<>
							{deployment.ward?.name} <br />
							{new Date(deployment.from).toLocaleDateString()} bis {new Date(deployment.to).toLocaleDateString()}<br />
							{process.env.REACT_APP_SHOW_HOURS_PLANED_TOTAL === "true" ?
							<small style={{fontStyle : "italic"}}>({deployment.hoursTotal} Stunden gesamt geplant)</small>
							: <></>}
						</>
						: "–"
				}
			</span>
			<b>Praxisanleiterstunden:</b>
			<span style={{fontSize : "1.5rem"}}>
				{ student.currentDeployment?.id === deployment.id ?
					<>
						<code style={{display : "block"}} className={hasEnoughHours ? "text-success" : "text-danger"}>
							{MinutesFormatter(student ? student.statistics.actualTimeInMinutes : 0)} / {student ? student.statistics.hoursTotal : 0}h
							<br />
						</code>
						<small style={{fontStyle : "italic", fontSize : "0.7rem" }}>({student?.statistics.hoursNeededToEndOfWeek}h bis Ende dieser Woche benötigt)</small>
					</>
						:
					<>
						<code style={{display : "block"}} className="text-secondary">
							{MinutesFormatter(hours.reduce((accumulator, current) => current.deployment && current.deployment.id === deployment?.id && current.special ? accumulator + current.duration_minutes : accumulator, 0))}
							/ {MinutesFormatter(deployment.hoursTotal * 0.1 * 60)}
						</code>
					</>
			}</span>
			<b>Anleiterstunden:</b>
			<span style={{fontSize : "1.5rem"}}>
				{ hours.length > 0 ?
					<code style={{color: "#333"}}>{MinutesFormatter(hours.reduce((accumulator, current) => !current.special && current.deployment && current.deployment.id === deployment.id ? accumulator + current.duration_minutes : accumulator, 0))}</code>
					: <code style={{color : "#888"}}>0h 0m</code>
				}
			</span>
		</section>
	);
});

export default DeploymentInfo;
