import React, {useMemo, useEffect, useState} from "react";

import {useAlert} from "react-alert";

import PageHeader           from "components/shared/PageHeader";
import Spinner              from "components/shared/Spinner";
import SortableTable        from "components/shared/SortableTable";
import PhoneNumberFormatter from "formatters/PhoneNumberFormatter";
import EmailFormatter       from "formatters/EmailFormatter";
import usePermission        from "components/hooks/usePermission";
import YesNoModal           from "components/shared/YesNoModal";
import SearchField          from "components/shared/SearchField";
import {getLanguageName}    from "utils/Languages";
import NoResult             from "components/shared/NoResult";
import Shield               from "components/shared/Shield";

import {InfoCircleFill, CheckSquare, XSquare, BoxArrowUpRight, ExclamationTriangle, Funnel, ListNested, Trash} from "react-bootstrap-icons";

import usePBStore         from "../hooks/usePBStore";
import UserCard            from "./modals/UserCard";

const DeleteMessage = () => {
	return (
		<p className="alert alert-warning mt-5 grid ten-ninety">
			<ExclamationTriangle style={{fontSize : "2.8rem", alignSelf : "center"}} />
			<span style={{alignSelf : "center"}}>
				Diese Aktion kann nicht rückgängig gemacht werden!
			</span>
		</p>
	);
};

const UsersList = () => {
	const alert                             = useAlert();
	const canDelete                         = usePermission("pb-userdatum", "delete", "pb-userdatum");
	const [user, setUser]                   = usePBStore(store => [store.user, store.userSet]);
	const [showDelete, setShowDelete]       = usePBStore(store => [store.showDelete, store.showDeleteSet]);
	const [showInfo, setShowInfo]           = usePBStore(store => [store.showInfo, store.showInfoSet]);
	const users                             = usePBStore(store => store.users);
	const ready                             = usePBStore(store => store.ready);
	const [search, setSearch]               = useState("");
	const orgUnits                          = usePBStore(store => store.orgUnits);
	const [filters, setFilters]             = useState([]);
	const [recentFilters, setRecentFilters] = useState({});
	const userDeleteInfo                    = usePBStore(store => store.userDeleteInfo);

	const headers = useMemo(() => [
		{
			label      : "",
			member     : null,
			sortable   : false,
			width      : "40px",
			align      : "center",
			functional : ({member}) => {
				return member.pb_userdatum?.company && member.pb_userdatum.company !== "" ? <BoxArrowUpRight style={{opacity : 1}} className="text-primary" /> : null;
			}
		},
		{
			label      : "",
			member     : null,
			sortable   : false,
			width      : "40px",
			align      : "center",
			functional : ({member}) => {
				return (
					<span title={member.active ? "aktiv" : "inaktiv"}>
						{member.confirmed && !member.blocked ?
							<CheckSquare style={{opacity : 1}} className="text-success" />
						: <XSquare style={{opacity : 1}} className="text-danger" />
						}
					</span>
				)
			}
		},
		{
			label      : "",
			member     : "pb_userdatum.avatar",
			width      : "50px",
			sortable   : false,
			functional : ({member}) => member.pb_userdatum?.avatar ? <img src={member.pb_userdatum?.avatar instanceof File ? window.URL.createObjectURL(member.pb_userdatum.avatar) : process.env.REACT_APP_BACKEND_URL + member.pb_userdatum?.avatar?.url} alt="" className="avatar" /> : null
		},
		{
			member : "firstname",
			label  : "Vorname",
			width  : "15%"
		},
		{
			member : "lastname",
			label  : "Nachnname",
			width  : "15%"
		},
		{
			member     : null,
			label      : "Telefon",
			sortable   : false,
			functional : ({member}) => {
				if (!member.pb_userdatum)
					return null;
				return member.pb_userdatum.contacts?.map((contact, index) => {
					if (!contact.phonenumber)
						return null;
					return (
						<p key={contact.id || index} className="m-0">
							{PhoneNumberFormatter(contact.phonenumber)}
							<span className="badge bg-secondary ml-3">{contact.label?.name}</span>
						</p>
					);
				});
			}
		},
		{
			member     : null,
			label      : "Mobil",
			sortable   : false,
			functional : ({member}) => {
				if (!member.pb_userdatum)
					return null;
				return member.pb_userdatum.contacts?.map((contact, index) => {
					if (!contact.mobilenumber)
						return null;
					return (
						<p key={contact.id || index} className="m-0">
							{PhoneNumberFormatter(contact.mobilenumber)}
							<span className="badge bg-secondary ml-3">{contact.label?.name}</span>
						</p>
					);
				});
			}
		},
		{
			member     : null,
			label      : "Fax",
			sortable   : false,
			functional : ({member}) => {
				if (!member.pb_userdatum)
					return null;
				return member.pb_userdatum.contacts?.map((contact, index) => {
					if (!contact.faxnumber)
						return null;
					return (
						<p key={contact.id || index} className="m-0">
							{PhoneNumberFormatter(contact.faxnumber)}
							<span className="badge bg-secondary ml-3">{contact.label?.name}</span>
						</p>
					);
				});
			}
		},
		{
			member     : null,
			label      : "E-Mail",
			sortable   : false,
			functional : ({member}) => {
				if (!member.pb_userdatum)
					return null;
				return member.pb_userdatum.contacts?.map((contact, index) => {
					if (!contact.email)
						return null;
					return (
						<p key={index} className="m-0">
							{EmailFormatter(contact.email)}
							<span className="badge bg-secondary ml-3">{contact.label?.name}</span>
						</p>
					);
				});
			}
		},
		{
			label      : "",
			member     : null,
			sortable   : false,
			functional : ({member}) => {
				return (
					<section className="mt-2">
						{member.pb_userdatum?.organisation?.map(orgValue => {
						return <Shield tag={orgValue.unit?.name} value={orgValue.value} key={orgValue.id} className="warning" />
						})}
					</section>
				);
			}
		},
		{
			member     : null,
			label      : "",
			sortable   : false,
			width      : "7%",
			functional : ({member}) => {
				return (
					<>
						<button className="btn" onClick={() => {setUser(member); setShowInfo(true);}}><InfoCircleFill /></button>
						{canDelete && member.pb_userdatum ? <button className="btn" title="Zusatzdaten leeren" onClick={() => {setUser(member); setShowDelete(true);}}><Trash /></button> : null}
					</>
				);
			}
		}
	], [canDelete, setShowDelete, setShowInfo, setUser]);

	const filteredUsers = useMemo(() => {
		const isFiltered = Object.values(filters).filter(item => item !== null && item !== "").length > 0;
		if (search === "" && !isFiltered)
			return users;
		
		const regex = new RegExp(`.*${search}.*`, "gi");
		
		let newFilteredUsers = null;

		newFilteredUsers = users.filter(user => 
			user.firstname.match(regex) ||
			user.lastname.match(regex)  ||
			user.pb_userdatum?.organisation?.filter(item => item.value.match(regex)).length > 0 ||
			user.pb_userdatum?.languages?.filter(lang => getLanguageName(lang.code).match(regex)).length > 0
		);
		newFilteredUsers = newFilteredUsers.filter(user => filters.every(item => user.pb_userdatum?.organisation?.map(org => org.id.toString()).includes(item)));

		return newFilteredUsers;
	}, [users, filters, search]);

	const filter = (orgUnit, value) => {
		const newRecentFilters = {...recentFilters};
		let   newFilters       = [...filters];

		newFilters = newFilters.filter(item => item !== recentFilters[orgUnit]);

		if (value === "")
			delete newRecentFilters[orgUnit];
		else {
			newRecentFilters[orgUnit] = value;
			newFilters.push(value);
		}

		setFilters(newFilters);
		setRecentFilters(newRecentFilters);
	};

	return (
		<>
			<PageHeader title="Telefonbuch">
				<section className="grid three-thirds">
					<SearchField search={search} setSearch={setSearch} />
				</section>
				{orgUnits.length > 0 ? 
					<section className="grid four-fourths">
						{orgUnits.map(orgUnit => {
							return (
								<article key={orgUnit.id}>
									<b className="mb-3">{orgUnit.name}</b><br />
									<section className="input-group">
										<div className="input-group-prepend">
											<span className="input-group-text"><Funnel /></span>
										</div>
										<select name={orgUnit.id} value={recentFilters[orgUnit.id] || ""} className="form-control" onChange={event => filter(orgUnit.id, event.target.value)}>
											<option value="">Kein Filter</option>
											{orgUnit.organisational_values?.map(orgValue => <option key={orgValue.id} value={orgValue.id}>{orgValue.value}</option>)}
										</select>
									</section>
								</article>
							);
						})}
					</section>
				: null}
			</PageHeader>
			{ready ? (
				filteredUsers.length > 0 ?
					<SortableTable data={filteredUsers} headers={headers} rowCallback={user => {setUser(user); setShowInfo(true);}} />
					: (
						<NoResult />
					)
			) : <Spinner />}
			<YesNoModal show={showDelete} setShow={setShowDelete} title="Zusatzinfos löschen" text={<>Möchten Sie die Zusatzinfos für {user?.firstname} {user?.lastname} wirklich löschen? <DeleteMessage /></>} callback={() => userDeleteInfo(alert)} />
			<UserCard show={showInfo} setShow={setShowInfo} />
		</>
	);
};

export default UsersList;