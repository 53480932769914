import React from "react";
import axios from "axios";

import { useAlert } from "react-alert";

import Modal from "components/shared/Modal";

import ShortTimeFormatter from "../../formatters/TimeFormatter";

import { ShortDateFormatter } from "formatters/DateFormatter";

import { StrapiRequest, StrapiResponse } from "utils/StrapiUtils";

import { ExclamationSquareFill } from "react-bootstrap-icons";

const Confirmation = ({ show, onHide, setShow, currentBooking, bookings, fetchData }) => {

    const alert = useAlert();

    const handleConfirmation = () => {
        if (!currentBooking)
            return;
        let copy = currentBooking;
        copy.confirmed = !currentBooking.confirmed;
        axios.put(`/rm-bookings/confirm/${copy.id}`, StrapiRequest(copy)).then(() => {
            alert.success(`Raumbuchung erfolgreich ${copy.confirmed === true ? "bestätigt" : "zurückgenommen"}`);
            fetchData();
            setShow(false);
        }).catch((error) => {
            alert.error(`Fehler bei der Bestätigung der Buchung: ${error.response.data.message}`);
        })
    }

    function CheckForCollision() {
        let collison = null;
        let currentFrom = new Date(currentBooking?.from);
        let currentTo = new Date(currentBooking?.to);

        currentFrom.setSeconds(0);
        currentTo.setSeconds(0);

        // 1 Minute tollerance so an event fom 10:00-12:00 does not collide with 09:00-10:00
        currentFrom.setMinutes(currentFrom.getMinutes()+1);
        currentTo.setMinutes(currentTo.getMinutes()-1);
        
        bookings.forEach(event => {
            let eventFrom = new Date(event?.from);
            let eventTo = new Date(event?.to);
            
            eventFrom.setSeconds(0);
            eventTo.setSeconds(0);

            if ((event?.room.id == currentBooking?.room.id) && (event.id != currentBooking.id) && event.confirmed) {
                if ((currentFrom >= eventFrom && currentFrom <= eventTo) || (currentTo >= eventFrom && currentTo <= eventTo) || (currentFrom < eventFrom && currentTo > eventTo)) { //starts during other event || ends during other event || starts before and ends after other event
                    console.log("Warning collison with " + event.name);
                    collison = <>Zur gleichen Zeit wie: {event.name}</>;
                }
            }
            

        });
        if(collison)
        return (
            <>
                <label className="collisionWarning">Kollision <ExclamationSquareFill></ExclamationSquareFill></label>
                <label className="collisionWarning">{collison}</label>
            </>);
        else
        return null;
    }

    return (
        <Modal show={show} onHide={onHide} size="lg" scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title>{`${currentBooking?.confirmed === true ? "Buchung zurücknehmen" : "Raumbuchung bestätigen"}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <section className="grid form">
                    <CheckForCollision></CheckForCollision>
                    <label>Antragsteller/in</label>
                    <label>{currentBooking?.contactName}</label>

                    <label>Raum</label>
                    <label>{currentBooking?.room?.name} ({currentBooking?.room?.slug})</label>

                    <label>Titel</label>
                    <label>{currentBooking?.name}</label>

                    <label>Datum</label>
                    <label>{`${ShortDateFormatter(currentBooking?.from)}`}</label>

                    <label>Anfang</label>
                    <label>{`${ShortTimeFormatter(currentBooking?.from)}`}</label>

                    <label>Ende</label>
                    <label>{`${ShortTimeFormatter(currentBooking?.to)}`}</label>

                    <label>Teilnehmer</label>
                    <label>{currentBooking?.participants}</label>

                    <label>Mit Bewirtung</label>
                    <label>{currentBooking?.hospitality ? "Ja" : "Nein"}</label>

                    <label>Bemerkung</label>
                    <label>{currentBooking?.notes}</label>

                    <label>E-Mail-Adresse</label>
                    <label><a href={`mailto:${currentBooking?.contactEmail}`}>{currentBooking?.contactEmail}</a></label>
                </section>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-danger" onClick={() => { setShow(false) }}>Abbrechen</button>
                {
                    currentBooking?.confirmed ?
                        <>
                            <button className="btn btn-danger" onClick={handleConfirmation}>Buchung zurücknehmen</button>
                        </>
                        :
                        <button className="btn btn-success" onClick={handleConfirmation}>Buchung bestätigen</button>
                }
            </Modal.Footer>
        </Modal>
    )

}

export default Confirmation