import create  from "zustand";
import {immer} from "zustand/middleware/immer";
import axios   from "axios";

import {StrapiRequest, StrapiResponse, RemoveEmptyRecursively} from "utils/StrapiUtils";
import { id } from "date-fns/locale";

// @enum
const State = Object.freeze({
	VIEW : 1,
	EDIT : 2
});

const usePBStore = create(immer((set, get) => ({
	ready    : false,
	readySet : ready => set({ready}),

	users     : [],
	usersSet  : users => set({users}),
	usersLoad : alert => {
		axios.get("/users/?populate=*,pb_userdatum.contacts,pb_userdatum.contacts.label,pb_userdatum.languages,pb_userdatum.address,pb_userdatum.avatar,pb_userdatum.ward,pb_userdatum.organisation,pb_userdatum.organisation.unit").then(response => {
			set({ready : true, users : response.data});
		}).catch(error => {
			alert.error(`Fehler beim Laden der Benutzer: ${error}`)
		});
	},

	dialcode     : null,
	dialcodeSet  : dialcode => set({dialcode}),
	dialcodeSave : (formData, alert) => {
		const {dialcode, showDialcodesEditSet} = get();
		const saveMethod                       = dialcode ? axios.put                      : axios.post;
		const saveUrl                          = dialcode ? `/pb-dialcodes/${dialcode.id}` : "/pb-dialcodes";

		const newData = {...formData};

		if (newData.address?.country?.length === 0)
			delete newData.address.country;
		saveMethod(saveUrl, StrapiRequest(newData)).then((response) => {
			showDialcodesEditSet(false);
			set(state => {
				if (dialcode)
					state.dialcodes = state.dialcodes.map(item => item.id === dialcode.id ? {id:dialcode.id,...formData} : item);
				else
					state.dialcodes.push({id:response?.data?.data?.id,...formData});
			});
			alert.success(`Kurzwahl '${formData.name}' wurde erfolgreich ${dialcode ? "gespeichert" : "angelegt"}`);
		}).catch(error => {
			console.log(error);
			alert.error(`Fehler beim ${dialcode ? "Speichern" : "Anlegen"} der Kurzwahl: ${error}`)
		});
	},
	dialcodeDelete : alert => {
		const {dialcode} = get();
		axios.delete(`/pb-dialcodes/${dialcode?.id}`).then(() => {
			alert.success(`Kurzwahl '${dialcode?.name}' ${dialcode?.code ? `[${dialcode.code}]` : ""} erfolgreich gelöscht.`);
			set(state => {
				state.dialcodes = state.dialcodes.filter(item => item.id !== dialcode?.id);
			});
		}).catch(error => alert.error(`Fehler beim Löschen der Kurzwahl: ${error}`));
	},

	dialcodes     : [],
	dialcodesSet  : dialcodes => set({dialcodes}),
	dialcodesLoad : alert => {
		axios.get("/pb-dialcodes/?populate=*,organisation, organisation.unit, contact, contact.label, address&pagination[limit]=-1").then(response => {
			set({dialcodes : StrapiResponse(response)});
		}).catch(error => {
			alert.error(`Fehler beim Laden der Kurzwahlen: ${error}`)
		});
	},

	room     : null,
	roomSet  : room => set({room}),
	roomSave : (formData, alert) => {
		const {room, roomEditShowSet} = get();

		axios.put(`/rb-rooms/${room.id}`, StrapiRequest({...room, contact : {...room.contact, ...formData}})).then(() => {
			alert.success(`Raum '${room.name}' erfolgreich gespeichert`);
			roomEditShowSet(false);
			set(state => {
				state.rooms = state.rooms.map(item => item.id === room.id ? {...item, contact : {...item.contact, ...formData}} : item);
				state.room  = null;
			});
		}).catch(error => {
			console.log(error);
			alert.error(`Fehler beim Speichern der Rauminfos: ${error}`);
		});
	},

	rooms     : [],
	roomsSet  : rooms => set({rooms}),
	roomsLoad : alert => {
		axios.get("/rb-rooms/?populate=*,contact&pagination[limit]=-1").then(response => {
			set({rooms : StrapiResponse(response)});
		}).catch(error => alert.error(`Fehler beim Laden der Räume: ${error}`));
	},

	roomEditShow    : false,
	roomEditShowSet : roomEditShow => set({roomEditShow}),

	showDialcodesEdit      : false,
	showDialcodesEditSet   : showDialcodesEdit => set({showDialcodesEdit}),
	showDialcodesDelete    : false,
	showDialcodesDeleteSet : showDialcodesDelete => set({showDialcodesDelete}),

	filteredUsers    : [],
	filteredUsersSet : filteredUsers => set({filteredUsers}),

	user     : null,
	userSet  : user => set({user}),
	userSave : (formData, alert) => {
		const {user}     = get();
		const saveMethod = user.pb_userdatum ? axios.put : axios.post;
		const saveURL    = user.pb_userdatum ? `/pb-userdata/${user.pb_userdatum?.id}` : "/pb-userdata";

		const contacts = formData.contacts.map(item => ({...item, email : item.email === "" ? null : item.email}));
		const data     = RemoveEmptyRecursively({
			user : user.id,
			...formData,
			contacts,
			organisation : formData.organisation.map(item => item.id),
		});
		data.contacts = contacts;

		if (!data.avatar)
			data.avatar = null;
		if (!data.languages || data.languages.length < 1)
			data.languages = [];

		if (!data.birthday)
			data.birthday = null;

		if (formData.ward)
			data.ward = formData.ward;

		if (!data.organisation)
			data.organisation = [];

		if(!data.title)
			data.title = null;

		if(!data.jobtitle)
			data.jobtitle = null;

		if(!data.company)
			data.company = null;

		if(!data.notes)
			data.notes = null;

		if(!data.salutation)
			data.salutation = null;

		saveMethod(saveURL, StrapiRequest({...data})).then(response => {
			const newInfo               = StrapiResponse(response);
			const id                    = user.pb_userdatum?.id? user.pb_userdatum.id : newInfo.id;
			const {firstname, lastname} = formData;
			const newUser               = {...user, firstname, lastname, pb_userdatum : {...user.pb_userdatum, ...formData, id}};

			axios.put(`/users/${user.id}`, {firstname, lastname}).then(() => {
				set(state => {
					state.users = state.users.map(item => item.id === user.id ? {...newUser, contacts: data.contacts} : item);
					state.user  = {...newUser};
					state.state = State.VIEW;
				});
			}).catch(error => {
				console.error(error);
				alert.error(`Fehler beim Speichern des Namens: ${error}`);
			});

			if (!formData.avatar || !(formData.avatar instanceof File))
				return;

			const fileData = new FormData();
			fileData.append("files", formData.avatar);
			fileData.append("refId", user?.pb_userdatum?.id);
			fileData.append("ref", "api::pb-userdatum.pb-userdatum");
			fileData.append("field", "avatar");
			axios.post("/upload", fileData).catch(error => console.log(error));
		}).catch(error => {
			console.log(error);
			alert.error(`Fehler beim Speichern der Benutzerdaten: ${error}`);
		});
	},
	userDeleteInfo : (alert) => {
		const {user} = get();

		if (!user || !user.pb_userdatum) {
			alert.error("Kein Benutzer verfügbar um Zusatzinformation zu löschen");
			return;
		}
		axios.delete(`/pb-userdata/${user.pb_userdatum?.id}`).then(() => {
			set(state => {
				state.users = state.users.map(item => {
					if (item.id === user.id) {
						const newUser = {...item};
						delete newUser.pb_userdatum;
						return newUser;
					}

					return item;
				});
			});
			alert.success(`Zusatzdaten für "${user.firstname} ${user.lastname}" erfolgreich gelöscht.`);
		}).catch(error => {alert.error(`Fehler beim Löschen der Zusatzdaten für "${user.firstname} ${user.lastname}": ${error}`)});
	},
	showDelete     : false,
	showDeleteSet  : showDelete => set({showDelete}),

	showInfo    : false,
	showInfoSet : showInfo => set({showInfo}),

	showEdit    : false,
	showEditSet : showEdit => set({showEdit}),

	state    : State.VIEW,
	stateSet : state => set({state}),

	labels     : [],
	labelsSet  : labels => set({labels}),
	labelsLoad : alert => {
		axios.get("/contact-info-labels?pagination[limit]=-1").then(response => {
			set({labels : StrapiResponse(response)});
		}).catch(error => alert.error(`Fehler beim Laden der Kontaktlabels: ${error}`));
	},

	label     : null,
	labelSet  : label => set({label}),
	labelSave : (formData, callback, alert) => {
		const {label, labels, users} = get();

		const saveMethod = label ? axios.put : axios.post;
		const saveURL    = `/contact-info-labels/${label?.id || ""}`;

		saveMethod(saveURL, StrapiRequest({...formData})).then(response => {
			const newElement = StrapiResponse(response);
			let   newLabels  = [...labels];
			if (label) {
				newLabels = newLabels.map(item => item.id === label.id ? newElement : item);
				set({ users : users.map(item => {
					if (!item.pb_userdatum || !item.pb_userdatum.contacts || item.pb_userdatum.contacts.length < 1)
						return item;
					const newUser = {...item};
					newUser.pb_userdatum.contacts = newUser.pb_userdatum.contacts.map(item => {
						if (item.label?.id !== label.id)
							return item;
						const newContact = {...item};
						newContact.label.name = formData.name;

						return newContact;
					});
					return newUser;
				})});
			} else
				newLabels.push(newElement);
			set({labels : newLabels});
			callback();
			alert.success(`Das Label "${formData.name}" wurde erfolgreich gespeichert.`);
		}).catch(error => {
			console.log(error);
			alert.error(`Fehler beim Speichern des Labels "${formData.name}": ${error}`);
		});
	},
	labelDelete : alert => {
		const {label} = get();
		if (!label)
			return;
		axios.delete(`/contact-info-labels/${label.id}`).then(() => {
			set(state => {
				state.labels = state.labels.filter(item => item.id !== label.id);
				state.users  = state.users.map(item => ({
					...item,
					pb_userdatum : {...item.pb_userdatum, contacts : item.pb_userdatum?.contacts.map(contact => {
						const newContact = {...contact};
						if (newContact.label?.id === label.id)
							delete newContact.label;

						return newContact;
					})
				}}));
			});
			alert.success(`Das Label "${label.name}" wurde erfolgreich gelöscht.`);
		}).catch(error => alert.error(`Fehler beim Löschen des Labels "${label.name}": ${error}`));
	},
	labelShowDelete    : false,
	labelShowDeleteSet : labelShowDelete => set({labelShowDelete}),
	labelShowEdit      : false,
	labelShowEditSet   : labelShowEdit => set({labelShowEdit}),

	orgUnits     : [],
	orgUnitsSet  : orgUnits => set({orgUnits}),
	orgUnitsLoad : alert => {
		axios.get("/organisational-units/?populate=*&pagination[limit]=-1").then(response => {
			set({orgUnits : StrapiResponse(response)});
		}).catch(error => alert.error(`Fehler beim Laden der Organisationseinheiten: ${error}`));
	},

	orgUnit     : null,
	orgUnitSet  : orgUnit => set({orgUnit}),
	orgUnitSave : (formData, alert) => {
		const {orgUnit, orgUnits} = get();
		const saveMethod          = orgUnit ? axios.put : axios.post;
		const saveURL             = `/organisational-units/${orgUnit ? orgUnit.id : ""}`;

		saveMethod(saveURL, StrapiRequest({...formData})).then(response => {
			const newUnit = StrapiResponse(response);

			if (orgUnit) {
				set(state => {
					state.orgUnits = orgUnits.map(item => item.id === newUnit.id ? {...orgUnit, ...newUnit} : item);
					state.users    = state.users.map(item => {
						return ({...item, pb_userdatum : {...item.pb_userdatum, organisation : item.pb_userdatum?.organisation?.map(item => item.unit?.id === newUnit.id ? {...item, unit : newUnit} : item)}})
					});
				});
			}
			else
				set({orgUnits : [...orgUnits, {...newUnit}]});
		}).catch(error => {
			console.log(error);
			alert.error(`Fehler beim ${orgUnit ? "Speichern" : "Anlegen"} der Organisationseinheit: ${error}`);
		});
	},

	orgUnitShowEdit    : false,
	orgUnitShowEditSet : orgUnitShowEdit => set({orgUnitShowEdit}),

	// TODO: clean up all other states to reflect this change
	orgValueRemove : (value, alert) => {
		const {orgUnit, orgUnits} = get();

		if (!orgUnit)
			return;
		set({deletingValue : true});
		axios.delete(`/organisational-values/${value.id}`).then(() => {
			const newUnit = {...orgUnit};
			newUnit.organisational_values = orgUnit.organisational_values.filter(item => item.id !== value.id);
			set({
				orgUnit  : newUnit,
				orgUnits : orgUnits.map(item => item.id === newUnit.id ? newUnit : item)
			});
		}).catch(error => alert.error(`Fehler beim Löschen des Wertes "${value.name}": ${error}`)).finally(() => set({deletingValue : false}));
	},
	orgValueAdd : (value, alert) => {
		const {orgUnit, orgUnits} = get();
		if (!orgUnit || value.value === "")
			return;
		axios.post("/organisational-values", StrapiRequest({value : value.value, slug : value.slug, unit : orgUnit.id})).then(response => {
			const newOrgValue = StrapiResponse(response);
			const oldValues   = orgUnit.organisational_values || [];
			set({
				orgUnit : {...orgUnit, organisational_values : [...oldValues, {...newOrgValue, unit : {...orgUnit}}]},
				orgUnits : orgUnits.map(item => item.id === orgUnit.id ? {...item, organisational_values : [...item.organisational_values, newOrgValue]} : item) || []
			});
		}).catch(error => {
			console.log(error);
			alert.error(`Fehler beim Anlegen des Wertes "${value.value}": ${error}`)
		});
	},

	deletingValue    : false,
	deletingValueSet : deletingValue => set({deletingValue}),

	wards     : [],
	wardsSet  : wards => set({wards}),
	wardsLoad : alert => {
		axios.get("/wards?pagination[limit]=-1").then(response => {
			const wards = StrapiResponse(response);
			set({wards});
		}).catch(error => alert.error(`Fehler beim Laden der Stationen: ${error}`));
	}
})));

export {State};
export default usePBStore;
