import React, { useEffect, useMemo, useCallback, useRef } from "react";

import _ from "lodash";
import { useAlert } from "react-alert";

import {
	Telephone,
	Envelope,
	Phone,
	ArrowRightShort,
	Printer,
	X,
	TelephonePlus,
	Building,
	DashCircleFill,
	Save
} from "react-bootstrap-icons";


import Modal from "components/shared/Modal";
import useForm from "components/hooks/useForm";
import DynamicTextField from "components/shared/DynamicTextField";
import CountrySelect from "components/shared/CountrySelect";
import SplitMenu from "../SplitMenu";
import Select from "components/shared/Select";

import usePBStore from "modules/phonebook/hooks/usePBStore";

const InitialState = {
	name: "",
	contact: [],
	address: {
		street: "",
		zip: "",
		city: "",
		country: "",
	},
	organisation: [],
	note: ""
};

const DialcodeEdit = ({ dialcode, show, setShow }) => {
	const firstField = useRef();
	const alert = useAlert();
	const orgUnits = usePBStore(store => store.orgUnits);
	const labels = usePBStore(store => store.labels);
	const [formData, handleInput, clear] = useForm(InitialState);
	const save = usePBStore(store => store.dialcodeSave);

	const organisations = useMemo(() => {
		const out = [];
		const orgs = _.groupBy(formData.organisation, "unit.name");
		for (const [key, values] of Object.entries(orgs))
			out.push({ key, values });
		return out;
	}, [formData]);

	useEffect(() => {
		if (!dialcode) {
			clear();

			return;
		}
		clear({
			name: dialcode.name || "",
			contact: dialcode?.contact || [],
			address: {
				street: dialcode?.address?.street || "",
				zip: dialcode?.address?.zip || "",
				city: dialcode?.address?.city || "",
				country: dialcode?.address?.country || ""
			},
			organisation: dialcode?.organisation || "",
			note: dialcode?.note || "",
		});
		console.log(dialcode);
	}, [dialcode, clear]);

	const changeAddress = event => {
		const newFormData = { ...formData };
		formData.address[event.target.name] = event.target.value;
		clear(newFormData);
	};

	const addContact = useCallback(() => {
		const newFormData = {
			...formData,
			contact: [
				...formData.contact,
				{
					label: { id : ""},
					phonenumber: "",
					mobilenumber: "",
					email: null,
					faxnumber: ""
				}
			]
		};
		clear(newFormData);
	}, [clear, formData]);

	const changeContact = useCallback((index, key, value) => {
		const newFormData = structuredClone(formData);
		if (key === "label")
			value = labels.filter(item => item.id === Number(value))[0];
		newFormData.contact[index][key] = value;
		clear(newFormData);
	}, [clear, formData, labels]);

	const deleteContact = index => {
		const newFormData = { ...formData };
		newFormData.contact = newFormData.contact.filter((_, itemTndex) => itemTndex !== index);
		clear(newFormData);
	};

	const addOrgUnit = useCallback((unit, value) => {
		if (!unit || !value)
			return;
		const organisation = formData.organisation ? [...formData.organisation] : [];

		// do not add orgUnit twice
		if (organisation.filter(item => item.id === value.id).length > 0)
			return;

		organisation.push({ ...value, unit });

		const newFormData = { ...formData, organisation };
		clear(newFormData);
	}, [clear, formData]);

	const deleteOrgValue = useCallback(value => {
		if (!value || !formData.organisation)
			return;
		const organisation = [...formData.organisation.filter(item => item.id !== value)];
		const newFormData = { ...formData, organisation };
		clear(newFormData);
	}, [formData, clear]);

	useEffect(() => {
		if (!show)
			return;
		setTimeout(() => firstField.current?.focus(), 300);
	}, [show, firstField]);

	return (
		<Modal show={show} size="xl" onHide={() => setShow(false)}>
			<Modal.Header>
				<Modal.Title>Kurzwahl {dialcode ? "bearbeiten" : "anlegen"}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="grid form mb-0">
					<label>Bezeichnung</label>
					<input type="text" className="form-control" name="name" value={formData.name} onChange={handleInput} ref={firstField} />

					<label>Adresse</label>
					<address>
						<DynamicTextField name="street" placeholder="Straße" value={formData.address?.street} onChange={changeAddress} type="text" className="form-control inline" /><br />
						<DynamicTextField name="zip" placeholder="PLZ" value={formData.address?.zip} onChange={changeAddress} type="text" className="form-control inline" />
						<DynamicTextField name="city" placeholder="Stadt" value={formData.address?.city} onChange={changeAddress} type="text" className="form-control inline" /><br />
						<CountrySelect name="country" value={formData.address?.country || "DE"} onChange={changeAddress} placeholder="Land" className="inline" />
					</address>
					<label> Notiz </label>
					<input type="text" className="form-control" name="note" value={formData.note} onChange={handleInput} />
				</section>
				<hr />
				{formData.contact.map((contact, index) => {
					return (
						<React.Fragment key={index}>
							<section className="row contact-edit">
								<section className="col-md-3 text-right text-secondary small">
									<Select data={labels} defaultText="Label auswählen" current={contact.label?.id || contact.label?.data?.id|| ""} className="inline" onChange={event => changeContact(index, "label", event.target.value)} />
								</section>
								<section className="col-md-9" style={{ lineHeight: "2rem" }}>
									<Telephone className="mr-2" />< DynamicTextField name="phonenumber" placeholder="Telefon" value={contact.phonenumber || ""} type="text" className="form-control inline" onChange={event => changeContact(index, "phonenumber", event.target.value)} /><br />
									<Phone className="mr-2" /> <DynamicTextField name="mobilenumber" placeholder="Mobil" value={contact.mobilenumber || ""} type="text" className="form-control inline" onChange={event => changeContact(index, "mobilenumber", event.target.value)} /><br />
									<Envelope className="mr-2" /> <DynamicTextField name="email" placeholder="E-Mail" value={contact.email || ""} type="text" className="form-control inline" onChange={event => changeContact(index, "email", event.target.value)} /><br />
									<Printer className="mr-2" /> <DynamicTextField name="faxnumber" placeholder="Telefax" value={contact.faxnumber || ""} type="text" className="form-control inline" onChange={event => changeContact(index, "faxnumber", event.target.value)} />
									<button className="btn delete" onClick={() => deleteContact(index)}><X /></button>
								</section>
							</section>
							<section>
								<hr />
							</section>
						</React.Fragment>
					);
				})}
				<section className="row">
					<section className="col-md-3 text-right text-secondary small"> </section>
					<section className="col-md-9">
						<button className="btn btn-outline-success" title="Kontaktdatensatz hinzufügen" onClick={() => addContact()}><TelephonePlus /> Kontaktdatensatz hinzufügen</button>
					</section>
				</section>
				<hr />
				{organisations?.map(organisation => {
					return (
						<React.Fragment key={organisation.key}>
							<section className="row contact-edit">
								<section className="col-md-3 text-right text-secondary small">
									{organisation.key}
								</section>
								<section className="col-md-9">
									{organisation.values?.map(value => (
										<React.Fragment key={value.id}>
											<ArrowRightShort /> {typeof value === "object" ? value.value : value}
											<button className="btn text-danger scale" title="Wert löschen" onClick={() => deleteOrgValue(value.id)}><DashCircleFill /></button>
											<br />
										</React.Fragment>
									))}
								</section>
							</section>
							<section>
								<hr />
							</section>
						</React.Fragment>
					);
				})}
				<section className="row">
					<section className="col-md-3 text-right text-secondary small"> </section>
					<section className="col-md-9">
						<section className="btn-group">
							<button className="btn btn-outline-warning dropdown-toggle" title="Organisationseinheit hinzufügen" data-toggle="dropdown"><Building />  Organisationseinheit hinzufügen</button>
							<section className="dropdown-menu p-0">
								<SplitMenu orgUnits={orgUnits} onChange={addOrgUnit} />
							</section>
						</section>
					</section>
				</section>

			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-outline-danger" onClick={() => setShow(false)}>Abbrechen</button>
				<button className="btn btn-success" onClick={() => save(formData, alert)}><Save />{dialcode ? "Speichern" : "Anlegen"}</button>
			</Modal.Footer>
		</Modal>
	);
};

export default DialcodeEdit;
