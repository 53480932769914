import React, {useEffect, useContext, useRef} from "react";

import {NavLink, Routes, Route} from "react-router-dom";
import {TelephoneFill}          from "react-bootstrap-icons";
import {useAlert}               from "react-alert";
import {useNavigate}            from "react-router-dom";

import usePermission     from "components/hooks/usePermission";
import UserContext       from "components/UserContext";
import useCommandPalette from "components/hooks/useCommandPalette";

import usePBStore from "./hooks/usePBStore";

import UsersList     from "./components/UsersList";
import LabelsList    from "./components/LabelsList";
import OrgsList      from "./components/OrgsList";
import DialcodesList from "./components/DialcodesList";
import RoomsList     from "./components/RoomsList";

import "./_styles.scss";

const Phonebook = ({nav}) => {
	const redirect            = useRef(useNavigate());
	const addCommand          = useCommandPalette(palette => palette.addCommand);
	const {Modules}           = useContext(UserContext);
	const alert               = useAlert();
	const canUseModule        = usePermission("pb-userdatum", "find", "pb-userdatum");
	const canViewLabels       = usePermission("contact-info-label", "find", "contact-info-label");
	const canEditOrgs         = usePermission("organisational-value", "update", "organisational-value");
	const loadUsers           = usePBStore(store => store.usersLoad);
	const loadDialcodes       = usePBStore(store => store.dialcodesLoad);
	const loadLabels          = usePBStore(store => store.labelsLoad);
	const loadOrgs            = usePBStore(store => store.orgUnitsLoad);
	const loadRooms           = usePBStore(store => store.roomsLoad);
	const setShowEditLabel    = usePBStore(store => store.labelShowEditSet);
	const setShowEditDialcode = usePBStore(store => store.showDialcodesEditSet);
	const setDialcode         = usePBStore(store => store.dialcodeSet);
	const setLabel            = usePBStore(store => store.labelSet);

	useEffect(() => {
		if (!canUseModule)	
			return;

		loadUsers(alert);
		loadLabels(alert);
		loadOrgs(alert);
		loadDialcodes(alert);
		loadRooms(alert);
		
		addCommand("Labels anzeigen", () => redirect.current("/Phonebook/labels"), "Telefonbuch");
		addCommand("Neues Label", () => {
			setLabel(null);
			setShowEditLabel(true);	
			redirect.current("/Phonebook/labels");
		}, "Telefonbuch");
		addCommand("Teilnehmer anzeigen", () => redirect.current("/Phonebook"), "Telefonbuch");
		addCommand("Kurzwahlen anzeigen", () => redirect.current("/Phonebook/dialcodes"), "Telefonbuch");
		addCommand("Neue Kurwahl anlegen", () => {
			setDialcode(null);
			setShowEditDialcode(true);
			redirect.current("/Phonebook/dialcodes");
		}, "Telefonbuch");
	}, [
		loadUsers,
		alert,
		canUseModule,
		loadLabels,
		loadOrgs,
		addCommand,
		redirect,
		setShowEditDialcode,
		loadDialcodes,
		setShowEditLabel,
		loadRooms,
		setDialcode,
		setLabel
	]);

	if (!canUseModule)
		return <></>

	if (nav)
		return (
            <NavLink to="/Phonebook" className={({isActive}) => isActive ? "active" : ""}>
                <TelephoneFill /> <span>Telefonbuch</span>
            </NavLink>
		);

	return (
		<>
			<section className="phonebook-context">
				<nav>
					<ul className="nav nav-tabs">
						<li className="nav-item"><NavLink end className="nav-link" to="/Phonebook">Personen</NavLink></li>
						{Object.keys(Modules).includes("RoomManager") ? <li className="nav-item"><NavLink end className="nav-link" to="/Phonebook/rooms">Räume</NavLink></li> : null}
						<li className="nav-item"><NavLink end className="nav-link" to="/Phonebook/dialcodes">Kurzwahlen (weitere Treffer)</NavLink></li>
						{canViewLabels ? <li className="nav-item"><NavLink end className="nav-link" to="/Phonebook/labels">Labels</NavLink></li> : null}
						{canEditOrgs   ? <li className="nav-item"><NavLink end className="nav-link" to="/Phonebook/organisations">Organisationseinheiten</NavLink></li> : null}
					</ul>
				</nav>
				<Routes>
					<Route index element={<UsersList />} />
					<Route path="labels" element={<LabelsList />} />
					<Route path="organisations" element={<OrgsList />} />
					<Route path="dialcodes" element={<DialcodesList />} />
					{Object.keys(Modules).includes("RoomManager") ? <Route path="rooms" element={<RoomsList />} /> : null}
				</Routes>
			</section>
		</>
	);
};

export default Phonebook;
