import React from "react";

import {WifiOff} from "react-bootstrap-icons";

const Offline = ({logout}) => {
	return (
		<section className="offline">
			<section className="content">
				<p>
					<WifiOff />
				</p>
				<h2>marvolo. ist offline</h2>
				<section className="description my-5">
					Es besteht keine Konnektivität zum Internet und/oder dem Backendserver.<br />
					Bitte überprüfen Sie Ihre Internetverbindung.
					<p className="my-5">
						<button onClick={logout} className="btn btn-primary">jetzt ausloggen</button>
					</p>
				</section>
			</section>
		</section>
	);
};

export default Offline;