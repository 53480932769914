import React, {useState, useEffect, useMemo} from "react";

import {PersonPlusFill, Trash, Pencil} from "react-bootstrap-icons";
import {useAlert}                      from "react-alert";

import PaginatedSortableTable, {urlParamFilter} from "components/shared/PaginatedSortableTable";
import SortableTable                            from "components/shared/SortableTable";
import useLocalStorage                          from "components/hooks/useLocalStorage";
import YesNoModal                               from "components/shared/YesNoModal";
import SearchField                              from "components/shared/SearchField";
import PageHeader                               from "components/shared/PageHeader";
import usePermission                            from "components/hooks/usePermission";

import EditScreen                 from "./UserEdit";
import useUSStore, {InitialQuery} from "../hooks/useUSStore";

const UserList = () => {
	const alert                                = useAlert();
	const [user, setUser]                      = useUSStore(store => [store.user, store.userSet]);
	const [query, setQuery, setQueryImmediate] = useUSStore(store => [store.query, store.querySet, store.querySetImmediate]);
	const [showDelete, setShowDelete]          = useState(false);
	const [showEdit, setShowEdit]              = useState(false);
	const [search, setSearch]                  = useState("");
	const [numRows, setNumRows]                = useLocalStorage(15);
	const [users]                              = useUSStore(store => [store.users, store.usersSet]);
	const [filteredUsers, setFilteredUsers]    = useUSStore(store => [store.filteredUsers, store.filteredUsersSet]);
	const roles                                = useUSStore(store => store.roles);
	const load                                 = useUSStore(store => store.usersLoad);
	const canDelete                            = usePermission("user", "destroy", "users-permissions");
	const canUpdate                            = usePermission("user", "update", "users-permissions");
	const canCreate                            = usePermission("user", "create", "users-permissions");

	const headers = useMemo(() => [
		{
			label  : "Id",
			member : "id",
			width  : "90px"
		},
		{
			label     : "Benutzername",
			member    : "username",
			width     : "15%",
			formatter : value => <b>{value}</b>
		},
		{
			label  : "Nachname",
			member : "lastname"
		},
		{
			label  : "Vorname",
			member : "firstname"
		},
		{
			label     : "Rolle",
			member    : "role.name",
			formatter : value => <span className={`badge bg-${value.match(/.*admin.*/gi) ? "danger" : "info"} text-white`}>{value}</span>
		},
		{
			label     : "E-Mail",
			member    : "email",
			formatter : value => <a href={`mailto:${value}`}>{value}</a>
		},
		{
			label      : "",
			member     : null,
			sortable   : false,
			width      : "8%",
			functional : ({member}) => {
				return (
					<>
						{ canUpdate ? <button className="btn" onClick={() => { setUser(member); setShowEdit(true); }}><Pencil /></button> : null }
						{ canDelete ? <button className="btn" onClick={() => { setUser(member); setShowDelete(true); }}><Trash /></button> : null }
					</>
				)
			}
		}
	], [setUser, canDelete, canUpdate]);

	const userDelete = useUSStore(store => store.userDelete);

	useEffect(() => {
		load(alert);
	}, [load, alert]);

	useEffect(() => {
		if (!search || search === "") {
			setFilteredUsers(users);
			return;
		}

		const regex = new RegExp(`.*${search}.*`, "gi");
		setFilteredUsers(users.filter(user => {
			return user.firstname.match(regex) ||
				user.lastname.match(regex) ||
				user.username.match(regex)
		}));
		/*
		if (!search || search === "") {
			setQuery(InitialQuery);
			return;
		}
		setQueryImmediate(query => {
			return urlParamFilter(["username", "firstname", "lastname"], search, query);
		});
		*/
	}, [search, users/*setQuery, setQueryImmediate*/]);

	return (
		<>
			<PageHeader title="Übersicht" textNew="Benutzer anlegen" Icon={PersonPlusFill} actionNew={() => {setUser(null); setShowEdit(true);}} permission={canCreate}>
				<section className="grid three-thirds">
					{ canCreate ? <SearchField search={search} setSearch={setSearch} /> : null }
				</section>
			</PageHeader> 
			{/*<PaginatedSortableTable headers={headers} query={`/users${query}`} numRows={numRows || 15} state={users} setState={setUsers} />*/}
			<SortableTable headers={headers} data={filteredUsers} />
			{/*
			<section className="text-right">
				Einträge pro Seite:
				<select className="form-control" style={{width : "auto", display : "inline-block" , marginLeft : "1rem"}} value={numRows || 15} onChange={event => setNumRows(event.target.value)}>
					{[5, 15, 50, 100, 200].map((value, index) => <option key={index} value={value}>{value}</option>)}
				</select>
			</section>
			*/}
			{ canDelete ? <YesNoModal show={showDelete} setShow={setShowDelete} text={<>Sind Sie sicher, dass Sie den Benutzer mit der ID '{user?.id}' <i>({user?.firstname} {user?.lastname})</i> löschen möchten?</>} title="Benutzer löschen" callback={() => userDelete(alert)} /> : null }
			{ canUpdate || canCreate ? <EditScreen show={showEdit} setShow={setShowEdit} currentUser={user} roles={roles} /> : null }
		</>
	);
};

export default UserList;
