import React, {useEffect, useState, useRef} from "react";
import {useNavigate} from "react-router-dom";

import {PersonFill}             from "react-bootstrap-icons";
import {Routes, Route, NavLink} from "react-router-dom";
import {useAlert}               from "react-alert";

import usePermission     from "components/hooks/usePermission";
import useCommandPalette from "components/hooks/useCommandPalette";

import useUSStore  from "./hooks/useUSStore";
import UserList    from "./components/UserList";
import RolesList   from "./components/RolesList";

const Users = ({nav}) => {
	const alert             = useAlert();
	const redirect          = useRef(useNavigate());
	const addCommand        = useCommandPalette(palette => palette.addCommand);
	const [ready, setReady] = useState(false);
	const rolesLoad         = useUSStore(store => store.rolesLoad);
	const canUseModule      = usePermission("user", "find", "users-permissions");

	useEffect(() => {
		if (typeof canUseModule !== "undefined")
			setReady(true);

		addCommand("Benutzer anzeigen", () => redirect.current("/Users"), "Benutzer");
		addCommand("Benutzerrollen anzeigen", () => redirect.current("/Users/roles"), "Benutzer");
	}, [canUseModule, addCommand, redirect]);

	useEffect(() => {
		if (ready)
			rolesLoad(alert);
	}, [rolesLoad, alert, ready]);

	if (!ready || !canUseModule)
		return <></>

	if (nav)
		return (
			<NavLink to="/Users" className={({isActive}) => isActive ? "active" : ""}>
				<PersonFill /> <span>Benutzer</span>
			</NavLink>
		);

	return (
		<>
			<nav>
				<ul className="nav nav-tabs">
					<li className="nav-item"><NavLink end className="nav-link" to="/Users">Übersicht</NavLink></li>
					<li className="nav-item"><NavLink end className="nav-link" to="/Users/roles">Rollen</NavLink></li>
				</ul>
			</nav>
			<Routes>
				<Route index element={<UserList />} />
				<Route path="roles" element={<RolesList />} />
			</Routes>
		</>
	)
};

export default Users;
