import React, {useRef, useState, useContext, useCallback, useEffect, useMemo} from "react";
import axios from "axios";

import {useAlert}   from "react-alert";
import {Modal}      from "react-bootstrap";
import {CircleFill} from "react-bootstrap-icons";
import {DateTime}   from "luxon";

import UserContext                     from "../../../components/UserContext";
import SortableTable                   from "../../../components/shared/SortableTable";
import Spinner                         from "../../../components/shared/Spinner";
import YesNoModal                      from "../../../components/shared/YesNoModal";
import usePermission                   from "components/hooks/usePermission";
import {StrapiRequest, StrapiResponse} from "utils/StrapiUtils";

import {Trash, Eye, EnvelopeOpen, Reply} from "react-bootstrap-icons";

import {StringTimeFormatter} from "../../../formatters/DateFormatter";

const Message = () => {
	const alert                       = useAlert();
	const canReadMessages             = usePermission("message", "find", "message");
	const {authUser}                  = useContext(UserContext);
	const form                        = useRef();
	const [show, setShow]             = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [message, setMessage]       = useState(null);
	const [messages, setMessages]     = useState([]);
	const [ready, setReady]           = useState(false);
	const [formData, setFormData]     = useState({
        firstname : authUser.specialChief ? authUser.firstname : "",
        lastname  : authUser.specialChief ? authUser.lastname  : "",
        email     : authUser.specialChief ? authUser.email     : "",
        subject   : "",
        content   : "",
        date_sent : DateTime.now().toISO()
    });

    const loadMessages = useCallback(() => {
        if (!canReadMessages)
            return;

        axios.get("/messages?sort=date_sent:desc").then(response => {
            setReady(true);
            setMessages(StrapiResponse(response));
        }).catch(error => alert.error(`Fehler beim Laden der Nachrichten: ${error}`));
    }, [alert, canReadMessages]);

    useEffect(() => {
        loadMessages();
    }, [loadMessages]);

    const handleInput = event => {
        let newData = {...formData};
		newData[event.target.name] = event.target.value;
		setFormData(newData);
    }

    const sendMessage = useCallback((event) => {
        event.preventDefault()

        axios.post("/messages", StrapiRequest(formData)).then(() => {
            setFormData(data => { return {...data, ...{subject: "", content: ""}} })
            alert.success("Ihre Nachricht wurde versandt.")
        }).catch(error => {console.log(error); alert.error(`Fehler beim Senden der Nachricht: ${error}`); })
    }, [formData, alert]);

    const showMessage = useCallback((msg) => {
        setMessage(msg);
        axios.put(`/messages/${msg.id}`, StrapiRequest({
            seen : true
        })).then(() => loadMessages()).catch(error => alert.error(`Fehler beim Laden der Nachrichten: ${error}`));
        setShow(true);
    }, [loadMessages, alert]);

    const deleteMessage = useCallback(() => {
        axios.delete(`/messages/${message.id}`).then(() => {
            alert.success(`Die Nachricht '${message.subject}' wurde erfolgreich gelöscht.`);
            setMessage(null);
            loadMessages();
        });
    }, [message, alert, loadMessages]);

    const reply = useCallback(msg => {
        return `mailto:${msg?.email}?subject=${encodeURIComponent(`Re: ${msg?.subject}`)}`;
    }, []);


	const headers = useMemo(() => [
		{
			label      : "",
			member     : null,
			width      : "2%",
			sortable   : false,
			padding    : "0.8rem",
			functional : ({member}) => {
				return !member.seen ?
					<span className="text-primary" style={{visibility : "visible"}}>
					<CircleFill style={{opacity : "1", width: "0.65rem"}} />
				</span>
					: ""
			}
		},
		{
			label     : "Datum",
			member    : "date_sent",
			formatter : StringTimeFormatter,
			width     : "10%"
		},
		{
			label      : "Absender",
			functional : ({member}) => {
				return `${member.firstname} ${member.lastname}`;
			},
			padding    : "0.8rem",
			width      : "20%"
		},
		{
			label  : "Betreff",
			member : "subject"
		},
		{
			label      : "",
			member     : null,
			width      : "130px",
			sortable   : false,
			functional : ({member}) => {
				return (
					<>
						<button className="btn" onClick={() => showMessage(member)} title="lesen"><Eye /></button>
						<a href={reply(member)} className="btn" title="Via E-Mail antworten">
							<Reply />
						</a>
						<button className="btn" title="Löschen" onClick={() => {
							setMessage(member);
							setShowDelete(true);
						}}>
							<Trash />
						</button>
					</>
				);
			}
		}
	], [reply, showMessage]);

    if (canReadMessages) {
        if (!ready) {
			return (
				<>
					<h2 className="py-4">Posteingang</h2>
					<Spinner />
				</>
			);
		}

        return (
            <>
                <h2 className="py-4">Posteingang</h2>
                <SortableTable data={messages} headers={headers} />
                <Modal size="lg" show={show}>
                    <Modal.Header>
                        <Modal.Title>
                            Nachricht von&nbsp;
                            {message ? message.firstname : ""}&nbsp;
                            {message ? message.lastname : ""}
                            <br />
                            <small style={{fontWeight : 100, fontSize : "0.8rem"}}>{StringTimeFormatter(message?.date_sent)}</small>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{padding : "2rem"}}>
	                    <p className="float-right">
                            <a className="btn btn-primary" title="Via E-Mail antworten" href={reply(message)}>
                                <EnvelopeOpen style={{margin : 0}} />
                            </a>
                        </p>
                        <p>
                            <b>Betreff:</b> {message ? message.subject : "–"}
                        </p>
                        <p>
                            {message ? message.content : "N/A"}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-primary" onClick={() => setShow(false)}>schließen</button>
                    </Modal.Footer>
                </Modal>
                <YesNoModal show={showDelete} setShow={setShowDelete} text={`Möchten Sie die Nachricht '${message?.subject}' wirklich löschen?`} title="Nachricht löschen" callback={deleteMessage} />
            </>
        )
    }

    return (
            <>
                <h2 className="py-4" > Nachricht an den Admin </h2>
                <form onSubmit={sendMessage} ref={form} className="grid twenty-eighty right">
                    <label htmlFor="lastname"> Nachname </label>
                    <input className="form-control" name="lastname" type="text" value={formData.lastname} onChange={handleInput} required readOnly={authUser.specialChief} />
                    <label htmlFor="firstname"> Vorname </label>
                    <input className="form-control" name="firstname" type="text" value={formData.firstname} onChange={handleInput} required readOnly={authUser.specialChief} />
                    <label htmlFor="email"> E-Mail </label>
                    <input className="form-control" name="email" type="text" value={formData.email} onChange={handleInput} required readOnly={authUser.specialChief} />
                    <label htmlFor="subject"> Betreff </label>
                    <input className="form-control" name="subject" type="text" onChange={handleInput} value={formData.subject} required/>
                    <label htmlFor="content"> Nachricht </label>
                    <textarea className="form-control" name="content" rows={10} type="text" onChange={handleInput} value={formData.content} required/>
                    <p style={{gridColumn : "1 / span 2", textAlign : "right"}}>
                        <input type="submit" className="offset-md-1 btn btn-success" value="senden" />
                    </p>
                </form>
            </>
    );
}

export default Message;
