import React, {useRef, useEffect} from "react";

import {useLocation} from "react-router";

import $ from "jquery";

const Header = ({children}) => {
	return <div className="modal-header">{children}</div>;
};

const Title = ({children}) => {
	return <div className="modal-title h4">{children}</div>;
};

const Body = ({children}) => {
	return <section className="modal-body">{children}</section>;
};

const Footer = ({children}) => {
	return <section className="modal-footer">{children}</section>;
};

const Modal = ({size, show, scrollable = false, onHide = () => {}, children}) => {
	const modal    = useRef();
	const location = useLocation();

	useEffect(() => {
		const currentModal = modal.current;
		const listener     = onHide;

		if (!currentModal)
			return;

		$(currentModal).on("hidden.bs.modal", listener);

		return () => {
			$(currentModal).off("hidden.bs.modal", listener);
		};
	}, [onHide, modal]);

	useEffect(() => {
		$(modal.current).modal("hide");
	}, [location, modal]);

	useEffect(() => {
		const keyListener = event => {
			if (event.key !== "Escape")
				return;
			$(modal.current).modal("hide");
		};

		document.addEventListener("keydown", keyListener);

		return () => {
			document.removeEventListener("keydown", keyListener);
		};

	}, [modal]);

	useEffect(() => {
		$(modal.current).modal(show ? "show" : "hide");
	}, [show, modal]);

	return (
		<div className="modal marvolo-modal fade" ref={modal}>
			<section className={`modal-dialog${size ? ` modal-${size}` : "" }${scrollable ? " modal-dialog-scrollable" : ""}`}>
				<section className="modal-content">
					{children}
				</section>
			</section>
		</div>
	);
};

Modal.Header = Header;
Modal.Title  = Title;
Modal.Body   = Body;
Modal.Footer = Footer;

export default Modal;