import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";

import { useAlert } from "react-alert";
import { CheckSquare, XSquare, Pencil, CheckCircle, X, PlusCircle, CupFill } from "react-bootstrap-icons";

import SortableTable from "components/shared/SortableTable";
import SearchField from "components/shared/SearchField";
import Select from "components/shared/Select";
import Spinner from "components/shared/Spinner";
import DateInput from "components/shared/DateInput";
import Toggle from "components/shared/Toggle";
import { ShortDateFormatter } from "formatters/DateFormatter";
import { StrapiRequest, StrapiResponse } from "utils/StrapiUtils";

import BookingsEdit from "./modals/BookingsEdit";
import Confirmation from "./modals/Confirmation";
import ShortTimeFormatter from "../formatters/TimeFormatter";



const BookingsList = () => {
	const alert = useAlert();
	const [ready, setReady] = useState(false);
	const [rooms, setRooms] = useState([]);
	const [bookings, setBookings] = useState([]);
	const [filteredBookings, setFilteredBookings] = useState([]);
	const [filterRoom, setFilterRoom] = useState(null);
	const [filterDateFrom, setFilterDateFrom] = useState("");
	const [filterDateTo, setFilterDateTo] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [search, setSearch] = useState("");
	const [editBooking, setEditBooking] = useState(null);
	const [showOldBookings,setShowOldBookings] = useState(false);
	const handleModalClose = (setter) => setter(false);

	const Header = useMemo(() => [
		{
			label: "Titel",
			member: "name",
			sortable: false,
			width: "130px"
		},
		{
			label: "Angefragt am",
			member: "createdAt",
			formatter: ShortDateFormatter,
			width: "130px"
		},
		{
			label: "Termin",
			member: "from",
			formatter: ShortDateFormatter,
			width: "90px"
		},
		{
			label: "Von - Bis",
			member: null,
			sortable: false,
			functional: ({ member }) => {
				return <div style={{ "marginLeft": "0.75rem" }}>
					{ShortTimeFormatter(member.from)} - {ShortTimeFormatter(member.to)}
				</div>
			},
			width: "110px"
		},
		{
			label: "Raum",
			member: "room.slug",
			sortable: false,
			width: "100px"
		},
		{
			label: "Anfrage von",
			member: "contactName",
			sortable: false,
			functional: ({ member }) => {
				return <a href={`mailto:${member.contactEmail}`} style={{ "marginLeft": "0.75rem" }}>{member.contactName}</a>
			},
			width: "140px"
		},
		{
			label: "Bewirtung",
			member: "hospitality",
			width: "110px",
			sortable: false,
			functional: ({ member }) => {
				return member.hospitality ? <CupFill className="text-success" style={{ opacity: 1, "marginLeft": "0.75rem" }} /> : <></>;
			}

		},
		{
			label: "Status",
			width: "110px",
			member: "confirmed",
			functional: ({ member }) => {
				return (
					<>
						{
							member.confirmed ?
								<CheckSquare style={{ opacity: 1, "marginLeft": "0.75rem" }} className="text-success" />
								:
								<XSquare style={{ opacity: 1, "marginLeft": "0.75rem" }} className="text-danger" />
						}
					</>
				)
			}
		},
		{
			label: "",
			member: null,
			width: "150px",
			sortable: false,
			functional: ({ member }) => {
				return (
					<>
						<button className="btn">
							<Pencil onClick={() => {
								setEditBooking(member);
								setShowModal(true);
							}}
							/>
						</button>
						<button className="btn">
							<CheckCircle onClick={() => {
								setEditBooking(member);
								setShowConfirm(true);
							}} />
						</button>
					</>
				)
			}
		}
	])

	const load = useCallback(() => {
		const parameter = { _sort: "createdAt:desc" }
		axios.get("/rm-bookings/?populate=*&pagination[limit]=-1&pagination[start]=0", StrapiRequest({ params: parameter })).then(response => {
			setBookings(StrapiResponse(response));
			setReady(true)
		}).catch(error => alert.error(`Fehler beim Laden der Buchungen: ${error}`));
	}, []);

	const loadRooms = useCallback(() => {
		axios.get("/rb-rooms?populate=*&pagination[limit]=-1").then(response => {
			setRooms(StrapiResponse(response));
			setReady(true);
		}).catch(error => alert.error(`Fehler beim Laden der Räume: ${error}`))
	}, []);

	const handleSelect = (event) => {
		setFilterRoom(event.target.value)
	}

	const handleDateInput = (event) => {
		if (event.target.name === "from") {
			if(event.target.value != "")
			setFilterDateFrom(new Date(event.target.value));
			else
			setFilterDateFrom("");
		}
		if (event.target.name === "to") {
			if(event.target.value != ""){
			//Setting Date to 1 Day ahead because selected time in Datepicker is 00:00:00
			let to = new Date(event.target.value);
			to.setDate(to.getDate() + 1);
			setFilterDateTo(to);
			}else
			setFilterDateTo("");
		}
	}

	useEffect(() => {
		load();
		loadRooms();
	}, [load, loadRooms]);


	/**Filtering */

	useEffect(() =>{
		const today = new Date(Date.now());
		const regex = new RegExp(`${search}`, "gi");		
		setFilteredBookings(bookings.filter( booking => 
			{

				//Text Search
				if(search !== "" && !(booking.name.match(regex) || booking.contactName.match(regex)))
						return false;

                console.log(booking.room.id, filterRoom)

				//Room Filter
				if(filterRoom && (booking.room.id !== parseInt(filterRoom)))
 					return false;

				
				// Date Filter
				const bookingDate = new Date(booking.from);

				if (filterDateFrom !== "" && filterDateTo !== "") {
					if((bookingDate <= filterDateFrom) || (bookingDate >= (filterDateTo)))
						return false;
				}

				if (filterDateFrom !== "" && (bookingDate <= filterDateFrom)) 
						return false;

				if (filterDateTo !== "" && (bookingDate >= filterDateTo)) 
						return false;

				//Toggle Past Events
				if(!showOldBookings && (bookingDate < today))
					return false;

				return true;
			}
		))
	},[search,filterRoom,filterDateFrom,filterDateTo,showOldBookings,bookings])
	
	

	return (
		<>
			<header>
				<h2 className="py-4">
					Liste der Buchungen
					<button className="btn btn-success btn-sm float-right" onClick={() => { setEditBooking(null); setShowModal(true) }}><PlusCircle /> Neue Raumbuchung hinzufügen</button>
				</h2>

				<SearchField search={search} setSearch={setSearch} />

				<section className="container m-0 p-0">
					<section className="row align-items-center justify-content-between">
						<section className="col-lg-3 pl-0">
							<Select data={rooms.filter((room) => room?.active)} value={filterRoom || ""} onChange={handleSelect} firstSelectable={true} defaultText={"Alle Räume"} />
						</section>
						<section className="col-lg-4 pr-0 pl-0">
							<section className="container p-0">
								<section className="row flex-nowrap align-items-center">
									<section className="pr-2">Von</section>
									<div className="date-picker">
										<div className="date-picker-inputGroup">
											<DateInput className="form-control" name="from" onChange={handleDateInput} value={filterDateFrom || ""} event={true} />
										</div>
									</div>
								</section>
							</section>
						</section>
						<section className="col-lg-4 pl-0">
							<section className="container p-0">
								<section className="row flex-nowrap align-items-center">
									<section className="pr-2">Bis</section>
									<div className="date-picker">
										<div className="date-picker-inputGroup">
											<DateInput className="form-control" name="to" onChange={handleDateInput} value={filterDateTo || ""} event={true} />
										</div>
									</div>
								</section>
							</section>
						</section>
						<section className="col-lg-4 pl-0">
							<Toggle name="oldBookings" checked={showOldBookings} onChange={event => setShowOldBookings(event.target.checked)}/>
							<label className="ml-2" style={{ alignSelf: "center",margin: 0, justifySelf: "left" }}>zeige auch vergangene Termine </label>
						</section>
					</section>
				</section>
			</header>
			{
				ready ?
					<>
						<SortableTable headers={Header} data={filteredBookings} rowCallback={booking => { setEditBooking(booking); setShowConfirm(true); }} />
					</>
					: <Spinner />
			}
			<BookingsEdit show={showModal} setShow={setShowModal} rooms={rooms} fetchData={load} currentBooking={editBooking} />
			<Confirmation show={showConfirm} setShow={setShowConfirm} onHide={() => handleModalClose(setShowConfirm)} currentBooking={editBooking} bookings={bookings} fetchData={load} />
		</>
	)
};

export default BookingsList;
