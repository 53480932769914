import create from "zustand";
import axios  from "axios";
import _      from "lodash";

import {GenerateSecuraPassword} from "utils/StrapiUtils";

const InitialQuery = "?populate=*&sort=lastname:ASC";

const useUSStore = create((set, get) => ({
	roles      : [],
	rolesReady : false,
	rolesLoad  : alert => {
		axios.get("/users-permissions/roles").then(response => {
			set({roles : response.data.roles, rolesReady : true});
		}).catch(error => alert.error(`Fehler beim Laden der Benutzerrollen: ${error}`));
	},

	user       : null,
	userSet    : user => set({user}),
	users      : [],
	usersReady : false,
	usersLoad  : alert => {
		axios.get("/users/?populate=*").then(response => {
			set({users : response.data, usersReady : true});
		}).catch(error => alert.error(`Fehler beim Laden der Benutzer: ${error}`));
	},
	usersSet   : users => set({users}),
	userDelete : (alert) => {
		const {user} = get();
		if (!user)
			return;
		axios.delete(`/users/${user.id}`).then(() => {
			alert.success(`Der Benutzer mit der ID '${user.id}' wurde gelöscht.`);
			set(state => ({user : null, users : state.users.filter(item => item.id !== user.id)}));
		});
	},
	userSave   : (sendMail, formData, callback, alert) => {
		const {user, users, roles, userSendPassword} = get();

		const saveMethod = user ? axios.put : axios.post;
		const saveURL    = user ? `/users/${user.id}` : "/auth/local/register";
		const saveData   = user ? {...formData} : {
			...formData,
			specialChief : true,
			password     : GenerateSecuraPassword(),
			provider     : "local"
		};

		saveMethod(saveURL, saveData).then(response => {
			let newUsers  = [...users];
			const newUser = user ? response.data : response.data.user;
			if (!user) {
				// we need the role object for display in the users list, on save only
				// the role id is given.
				const role = roles.filter(item => item.id === parseInt(formData.role))[0];

				if (sendMail)
					userSendPassword(formData.email);

				// we must set the role manually again after user creation, because setting a non-standard
				// user role on `register` is prohibited for security reasons.
				axios.put(`/users/${newUser.id}`, {role : saveData.role}).catch(error => {
					alert.error(`Fehler beim setzen der Benutzerrolle des neuen Benutzers: ${error}`);
				});

				newUsers.push({...newUser, role});
			} else
				newUsers = users.map(item => item.id === user.id ? newUser : item);
			set({users : _.sortBy(newUsers, "lastname")});

			alert.success(`Der Benutzer '${formData.username}' wurde erfolgreich ${user ? 'geändert' : 'angelegt'}.`);
			callback();
		}).catch(error => { alert.error(`Beim ${user ? 'Speichern' : 'Anlegen'} des Benutzers '${formData.username}' ist ein Fehler aufgetreten: ${error}`); console.log(error);});
	},
	userSendPassword : email => {
		console.log("EMIL: ", email);
		axios.post(`/auth/forgot-password`, {email}).then(() => {
			alert.success(`Ein Link zur Passwortvergabe wurde an die E-Mail-Adresse "${email}" gesendet.`);
		}).catch(error => alert.error(`Fehler beim Senden der E-Mail-Benachrichtigung: ${error}`));
	},

	filteredUsers    : [],
	filteredUsersSet : filteredUsers => set({filteredUsers}),

	query             : InitialQuery,
	querySet          : query => set({query}),
	querySetImmediate : func => {
		const {query} = get();
		const newVal = func(query);
		set({query : newVal});
	}
}));

export {InitialQuery};
export default useUSStore;
