import React from "react";

class Std {
	static CreateElement(html) {
		const template = document.createElement("template");
		template.innerHTML = html.trim();

		return template.content.firstChild;
	}

	static Develoment() {
		return "_self" in React.createElement("div");
	}

	static Range(value) {
		return [...Array(value).keys()];
	}

	static IntToAlphabet(index) {
		let out = "";
		index++;

		while (index > 0) {
			let current = parseInt((index - 1) % 26);
			out = String.fromCharCode(current + 65).toString() + out;
			index = parseInt((index - 1) / 26);
		}

		return out;
	}

	static HexToRGB(hex) {
		if (hex.length < 6 || hex.length > 7)
			throw new Error(`Invalid hex code: '${hex}'`);
		hex = hex.charAt(0) === "#" ? hex.substr(1) : hex;

		return {
			r : parseInt(hex.substr(0, 2), 16),
			g : parseInt(hex.substr(2, 2), 16),
			b : parseInt(hex.substr(4, 2), 16)
		}
	}

	static EvaluateForegroundColor(hex) {
		try {
			const color = Std.HexToRGB(hex);

			if (color.r * 0.299 + color.g * 0.587 + color.b * 0.114 > 186)
				return "#000000";

			return "#ffffff";
		} catch (exception) {
			return  "#000000";
		}
	}

	static GetAPIName(name) {
		const lastChar = name.charAt(name.length - 1);

		return lastChar === "s" ? name : (lastChar === "y" ? `${name.substr(0, name.length - 1)}ies` : `${name}s`);
	}

	static SortAoOByMember(array = [], member = "name", desc = false) {
		if (desc)
			return array.sort((a, b) => a[member] < b[member] ? 1 : (a[member] > b[member] ? -1 : 0));
		return array.sort((a, b) => a[member] < b[member] ? -1 : (a[member] > b[member] ? 1 : 0));
	}

	static IsURLReachable(url, callback) {	
		return new Promise((resolve, reject) => {
			const request = new XMLHttpRequest();

			request.open(
				"HEAD",
				`${url}/?rand=${Math.random()}`
			);

			request.addEventListener("load", () => resolve(true));
			request.addEventListener("error", () => resolve(false));

			request.send();
		});

	}

	static Log(message, level = Std.LogLevel.DEFAULT) {
		let color = "color: ";

		switch(level) {
			default:
			case Std.LogLevel.DEFAULT:
				color += "grey";
				break;
			case Std.LogLevel.INFO:
				color += "lightblue";
				break;
			case Std.LogLevel.WARN:
				color += "orange";
				break;
			case Std.LogLevel.ERROR:
				color += "red";
				break;
		}
		console.log(`%c${message}`, color);
	}
}
Std.LogLevel = Object.freeze({
	DEFAULT : 1,
	INFO    : 2,
	WARN    : 3,
	ERROR   : 4
});

export default Std;
