import React from "react";

import {Pencil, Save} from "react-bootstrap-icons";
import {useAlert}     from "react-alert";

import Modal         from "components/shared/Modal";
import useForm       from "components/hooks/useForm";
import usePermission from "components/hooks/usePermission";

import usePBStore, {State} from "modules/phonebook/hooks/usePBStore";
import UserCardInfo        from "./UserCardInfo";
import UserCardEdit        from "./UserCardEdit";

const InitialFormData = {
	firstname     : "",
	lastname      : "",
	title         : "",
	address       : {
		street  : "",
		zip     : "",
		city    : "",
		country : "",
	},
	company       : "",
	jobtitle      : "",
	ward          : "",
	birthday      : "",
	notes         : "",
	salutation    : "",
	languages     : [],
	contacts      : [],
	organisations : [],
	state         : ""
};

const UserCard = ({show, setShow}) => {
	const alert                          = useAlert();
	const canEditUser                    = usePermission("pb-userdatum", "update", "pb-userdatum");
	const save                           = usePBStore(store => store.userSave);
	const [state, setState]              = usePBStore(store => [store.state, store.stateSet]);
	const [formData, handleInput, clear] = useForm(InitialFormData);

	const render = () => {
		switch(state) {
			case State.EDIT:
				return <UserCardEdit formData={formData} handleInput={handleInput} clear={clear} />
			case State.VIEW:
			default:
				return <UserCardInfo />
		}
	}

	return (
		<Modal show={show} onHide={() => {setState(State.VIEW); setShow(false);}} size="xl" scrollable={true}>
			<Modal.Header>
				<Modal.Title>Details zu Teilnehmer</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{render()}
			</Modal.Body>
			<Modal.Footer>
				{state === State.VIEW ?
					<button className="btn btn-outline-warning" onClick={() => setShow(false)}>Schließen</button>
				: <button className="btn btn-outline-danger" onClick={() => setState(State.VIEW)}>Abbrechen</button>}					
				{canEditUser ?
					(state === State.VIEW ?
						<button className="btn btn-secondary" onClick={() => setState(State.EDIT)}><Pencil /> Bearbeiten</button>
					: <button className="btn btn-success" onClick={() => save(formData, alert)}><Save /> Speichern</button> )
				: null }
			</Modal.Footer>
		</Modal>
	);
};

export default UserCard;