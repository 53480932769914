import {useContext} from "react";

import UserContext from "components/UserContext";

const usePermission = (controller, permission, module) => {
	const {permissions} = useContext(UserContext);
	const prefix        = ["email", "upload", "users-permissions"].includes(module) ? "plugin" : "api";

	return permissions[`${prefix}::${module}`]?.controllers[controller][permission]?.enabled === true;
};

const useIsAdmin = () => {
	const {authUser} = useContext(UserContext);

	return authUser.role.type === "admin" || authUser.role.type === "administrator";
};

export {useIsAdmin};
export default usePermission;