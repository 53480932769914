import React from "react";

import States from "utils/States";

const StateSelect = ({value, onChange, country = "de", ...props}) => {
	return (
		<select className="form-control" value={value} onChange={onChange} {...props} required>
			<option value="" disabled>Bundesland auswählen...</option>
			{States[country].map((state, index) => {
				return <option key={index} value={state}>{state}</option>
			})}
		</select>
	);
};

export default StateSelect;
