import React from "react";

const Shield = ({tag, value, className = ""}) => {
	return (
		<span className={`shield ${className}`}>
			<span>{tag}</span>
			<span>{value}</span>
		</span>
	);
};

export default Shield;