import React, {useMemo, useState, useEffect} from "react";

import SortableTable     from "components/shared/SortableTable";
import PageHeader        from "components/shared/PageHeader";
import usePermission     from "components/hooks/usePermission";
import {useAlert}        from "react-alert";
import YesNoModal        from "components/shared/YesNoModal";

import {Tags, Pencil, Trash, ExclamationTriangle} from "react-bootstrap-icons";

import usePBStore from "../hooks/usePBStore";
import LabelEdit  from "./modals/LabelEdit";

const DeletionNotice = <p className="alert alert-warning grid ten-ninety"><ExclamationTriangle style={{fontSize : "2.8rem", alignSelf : "center"}} /> <small><i>das Löschen von Labels kann zu inkonsistenten Datenbeständen führen, falls das betreffende Label bereits Kontakten zugewiesen ist. Bitte überlegen Sie genau, ob Sie das Label gefahrenfrei löschen können.</i></small></p>;

const LabelsList = () => {
	const alert                                 = useAlert();
	const canCreate                             = usePermission("contact-info-label", "create", "contact-info-label");
	const canEdit                               = usePermission("contact-info-label", "update", "contact-info-label");
	const canDelete                             = usePermission("contact-info-label", "delete", "contact-info-label");
	const labels                                = usePBStore(store => store.labels);
	const [label, setLabel]                     = usePBStore(store => [store.label, store.labelSet]);
	const [showEdit, setShowEdit]               = usePBStore(store => [store.labelShowEdit, store.labelShowEditSet]);
	const [showDeleteLabel, setShowDeleteLabel] = usePBStore(store => [store.labelShowDelete, store.labelShowDeleteSet]);
	const deleteLabel                           = usePBStore(store => store.labelDelete);

	const headers = useMemo(() => [
		{
			member : "id",
			label  : "Id",
			width  : "90px"
		},
		{
			member : "name",
			label  : "Name"
		},
		{
			member     : null,
			label      : "",
			sortable   : false,
			width      : "7%",
			functional : ({member}) => {
				return (
					<>
						{canEdit ? <button className="btn" title="Label bearbeiten" onClick={() => { setLabel(member); setShowEdit(true) }}><Pencil /></button> : null}
						{canDelete ? <button className="btn" title="Label löschen" onClick={() => { setLabel(member); setShowDeleteLabel(true) }}><Trash /></button> : null}
					</>
				);
			}
		}
	], [canEdit, canDelete, setLabel, setShowDeleteLabel, setShowEdit]);

	return (
		<>
			<PageHeader title="Kontaktlabels" Icon={Tags} textNew="neues Label erstellen" permission={canCreate} actionNew={() => { setLabel(null); setShowEdit(true); }}>

			</PageHeader>
			<SortableTable headers={headers} data={labels} rowCallback={label => {setLabel(label); setShowEdit(true);}}/>
			<LabelEdit show={showEdit} setShow={setShowEdit} label={label} />
			<YesNoModal show={showDeleteLabel} setShow={setShowDeleteLabel} callback={() => {deleteLabel(alert)}} text={<>Möchten Sie das Label "{label?.name}" wirklich löschen? <br /><br />{DeletionNotice}</>} title="Label löschen" />
		</>
	);
};

export default LabelsList;