import React, {useState, useCallback, useEffect, useMemo} from "react";
import axios from "axios";

import {useAlert} from "react-alert";

import {Pencil, Trash, Calendar3, PlusCircle} from "react-bootstrap-icons";

import SortableTable    from "components/shared/SortableTable";
import Spinner          from "components/shared/Spinner";
import SearchField      from "components/shared/SearchField";
import YesNoModal       from "components/shared/YesNoModal";
import {StrapiResponse} from "utils/StrapiUtils";

import RoomsEdit        from "./modals/RoomsEdit";
import RoomsBookingList from "./modals/RoomsBookingList";

const RoomsList = () => {
	const alert                             = useAlert();
	const [ready, setReady]                 = useState(false);
	const [rooms, setRooms]                 = useState([]);
	const [room, setRoom]                   = useState(null);
	const [filteredRooms, setFilteredRooms] = useState([]);
	const [search, setSearch]               = useState("");
	const [showDelete, setShowDelete]       = useState(false);
	const [showModal, setShowModal]         = useState(false);
	const [showBooked, setShowBooked]       = useState(false);
	const [editRoom, setEditRoom]           = useState(null);

	const handleModalClose                  = (setter) => setter(false);

	const Header = useMemo(() => [
		{
			label  : "Bezeichnung",
			member : "name"
		},
		{
			label     : "Kapazität",
			member    : "capacity",
			formatter : value => `${value} Personen`
		},
		{
			label     : "Raumnummer",
			member    : "slug",
			formatter : value => <code>{value?.toUpperCase()}</code>
		},
		{
			label  : "Ort",
			member : "location"
		},
		{
			label      : "",
			member     : null,
			width      : "100px",
			sortable   : false,
			functional : ({member}) => {
				return (
					<>
						<button className="btn"><Pencil onClick={() => {setEditRoom(member); setShowModal(true);}}/></button>
						<button className="btn" onClick={() => {setRoom(member); setShowDelete(true);}}><Trash /></button>
						<button className="btn"><Calendar3 onClick={() => {setEditRoom(member); setShowBooked(true);}}/></button>
					</>
				);
			}
		}
	], []);

	const load = useCallback(() => {
		axios.get("/rb-rooms?populate=*&pagination[limit]=-1&pagination[start]=0").then(response => {
			setRooms(StrapiResponse(response));
			console.log(StrapiResponse(response));
			setReady(true);
		}).catch(error => alert.error(`Fehler beim Laden der Räume: ${error}`));
	}, [alert]);

	useEffect(() => {
		load();
	}, [load]);

	useEffect(() => {
		if (!search || search === "") {
			setFilteredRooms(rooms);

			return;
		}
		const regex = new RegExp(`${search}`, "gi");
		setFilteredRooms(rooms.filter(room => room.name.match(regex)))
	}, [search, rooms]);

	const remove = useCallback(() => {
		if (!room)
			return;
		axios.delete(`/rb-rooms/${room.id}`).then(() => {
			alert.success(`Der Raum '${room.name}' wurde erfolgreich gelöscht.`);
			setRoom(null);
			load();
		}).catch(error => alert.error(`Fehler beim Löschen des Raumes: ${error}`));
	}, [room, alert, load]);

	return (
		<>
			<header style={{marginBottom : "1.5rem"}}>
				<h2 className="py-4">
					Liste der Räume
						<button	className="btn btn-success btn-sm float-right" onClick={() => {setEditRoom(null); setShowModal(true)}}><PlusCircle /> Raum hinzufügen</button>
				</h2>
				<section className="grid three-thirds">
					<SearchField search={search} setSearch={setSearch} />	
				</section>
			</header>
			{
				ready ?
				 	<>
						<SortableTable headers={Header} data={filteredRooms} />
						<YesNoModal title="Raum löschen" text={`Den Raum '${room?.name}' wirklich löschen?`} show={showDelete} setShow={setShowDelete} callback={remove} />
					</>
				: <Spinner />
			}
			<RoomsEdit show={showModal} setShow={setShowModal} onHide={() => handleModalClose(setShowModal)} fetchData={load} currentRoom={editRoom}/>
			<RoomsBookingList show={showBooked} onHide={() => handleModalClose(setShowBooked)} currentRoom={editRoom}/>
		</>
	);
};

export default RoomsList;