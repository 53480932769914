import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

import moment from "moment";
import { useAlert } from "react-alert";
import { DateTime } from "luxon";
import { PersonFill, Envelope } from "react-bootstrap-icons";

import Select from "components/shared/Select";
import DateInput from "components/shared/DateInput";
import Modal from "components/shared/Modal";
import Toggle from "components/shared/Toggle";
import useForm from "components/hooks/useForm";
import { StrapiRequest, StrapiResponse } from "utils/StrapiUtils";

import TimeInput from "../TimeInput";
import Event from "../Event";

import { Calendar, momentLocalizer } from "react-big-calendar";

import "moment/locale/de"
import "react-big-calendar/lib/css/react-big-calendar.css"

class BigCalendarEvent {
	constructor(booking) {
		this.id = booking?.id || "";
		this.title = booking?.name || "";
		this.start = booking.from ? new Date(booking?.from) : new Date();
		this.end = booking.to ? new Date(booking.to) : new Date();
		this.contact = booking?.contactName || "";
		this.email = booking?.contactEmail || "";
		this.hospitality = booking?.hospitality || "";
		this.confirmed = booking?.confirmed || "";
	}
}
const Localizer = momentLocalizer(moment);
const TRANSLATION = {
	next: "Nächstes",
	previous: "Zurück",
	today: "Heute",
	month: "Monat",
	week: "Woche",
	day: "Tag",
	date: "Datum",
	time: "Uhrzeit",
	event: "Veranstaltung",
	showMore: total => `+${total} mehr`,
}

const InitialFormData = {
	name: "",
	occasion: "",
	from: DateTime.now().set({ minute: 0 }),
	to: DateTime.now().set({ minute: 0 }),
	participants: 0,
	notes: "",
	contactName: "",
	contactEmail: "",
	room: "",
	hospitality: false,
	confirmed: false,
};

const BookingModal = ({ show, setShow, rooms, fetchData, currentBooking }) => {
	const alert = useAlert();
	const [series, setSeries] = useState(false);
	const [bookings, setBookings] = useState([]);
	const [filteredBookings, setFilteredBookings] = useState([]);
	const [events, setEvents] = useState([]);
	const [formData, handleInput, clear] = useForm(InitialFormData);

	const [pattern, setPattern] = useState({
		recurring_type: "weekly",			//emnum values = "daily","weekly","monthly","yearly"
		separation_count: 0,				//number of type between bookings e.g. recurring_type="weekly" separation_count=1 means every 2 Weeks (1 Week between bookings)
		end_date: new Date().toISOString
	});

	//console.log("EREDENER");
	const handleDate = (event) => {
		const value = event.target.value ? new Date(event.target.value) : new Date();

		clear(() => {
			const newData = { ...formData };
			const oldTimeStampFrom = new Date(newData.from);
			const oldTimeStampTo = new Date(newData.to);
			const newTime = value;
			newTime.setHours(oldTimeStampFrom.getHours());
			newTime.setMinutes(oldTimeStampFrom.getMinutes());
			newData.from = newTime?.toISOString();

			newTime.setHours(oldTimeStampTo.getHours());
			newTime.setMinutes(oldTimeStampTo.getMinutes());
			newData.to = newTime?.toISOString();
			console.log(newData);
			return newData;
		})
	}

	const handleTime = (event) => {
		const value = event.target.value;
		const propName = event.target.name;
		clear(() => {
			const newData = { ...formData };
			const oldDateStamp = new Date(newData.from);
			const newDate = value;
			newDate.setDate(oldDateStamp.getDate());
			newDate.setMonth(oldDateStamp.getMonth());
			newDate.setFullYear(oldDateStamp.getFullYear());
			newData[propName] = newDate?.toISOString();
			return newData;
		})
	}

	const resetForm = () => {
		setSeries(false);
		clear(initialState => {
			setEvents([]);
			return {
				...initialState,
				name: "",
				occasion: "",
				from: DateTime.now().set({ minute: 0 }),
				to: DateTime.now().set({ minute: 0 }),
				participants: 0,
				notes: "",
				contactName: "",
				contactEmail: "",
				room: "",
				hospitality: false,
				confirmed: false,
			};
		});
	}

	const save = (event) => {
		event.preventDefault();
		console.log(formData);
		const saveMethod = currentBooking ? axios.put : axios.post;
		const saveURL = currentBooking ? `/rm-bookings/${currentBooking.id}` : "/rm-bookings";
		saveMethod(saveURL, StrapiRequest(formData)).then((response) => {
			if (series && !currentBooking) {
				const booking_id = response.data.id;
				axios.post("/rm-recurrings", { data: { ...pattern, rm_bookings: [booking_id] } }).catch((error) => {
					alert.error(`Fehler beim Erstellen der Serienbuchung: ${error}`);
				});
			}
			alert.success(`Raumbuchung erfolgreich ${currentBooking ? "aktualisiert" : "angelegt"}.`);
			fetchData();
			resetForm();
			setShow(false);
		}).catch(error => {
			alert.error(`Fehler beim ${currentBooking ? "Aktualisieren" : "Anlegen"} der Buchung: ${error.response.error.message}`);
		});
	}

	const loadBookings = useCallback(() => {
		axios.get("/rm-bookings?populate=*&pagination[limit]=-1").then(response => {
			setBookings(StrapiResponse(response));
		});
	}, []);

	const handleConfirmation = () => {
		return new Promise((resolve, reject) => {
			let copy = currentBooking;
			copy.confirmed = !currentBooking?.confirmed;
			axios.put(`/rm-bookings/confirm/${currentBooking?.id}`, StrapiRequest(copy)).then(() => {
				alert.success(`Raumbuchung erfolgreich ${copy.confirmed ? "bestätigt" : "zurückgenommen"}`);
				resolve();
			}).catch((error) => {
				alert.error(error.response.data.error.message);
				reject(error);
			}).finally(() => {
				fetchData();
				setShow(false);
			})
		});
	}

	const handleDelete = async () => {
		if (currentBooking?.confirmed) {
			await handleConfirmation();
		}
		axios.delete(`/rm-bookings/${currentBooking.id}`).then(() => {
			alert.success("Raumbuchung erfolgreich gelöscht");
			fetchData();
			setShow(false);
		}).catch((error) => {
			alert.error(`Fehler bei der Löschung der Buchung: ${error}`);
		})
	}

	const handleSeriesInput = (event) => {
		console.log(pattern);
		const value = event.target.value;
		const propName = event.target.name;
		if (value != "bi-weekly")
			setPattern(oldData => {
				const newData = { ...oldData };
				newData[propName] = value;
				newData.separation_count = 0;
				return newData;
			})
		else
			setPattern(oldData => {
				const newData = { ...oldData };
				newData.recurring_type = "weekly";
				newData.separation_count = 1;
				return newData;
			})
	}

	useEffect(() => {
		if (!currentBooking)
			return;

		clear(() => {
			const newBooking = { ...currentBooking };
			delete newBooking.id;
			return (
				{
					...newBooking,
					occasion: currentBooking.occasion ?? "",
					notes: currentBooking.notes ?? ""
				})
		});
	}, [currentBooking, clear]);

	useEffect(() => {
		if (formData.room)
			loadBookings();
	}, [formData.room, loadBookings]);

	useEffect(() => {
		setFilteredBookings(bookings.filter(booking => (booking?.room?.id === formData.room?.id) || (booking?.room?.id == formData.room)));
	}, [bookings, formData.room]);

	// transforming list of bookings to list of events, which are expected by `react-big-calendar`
	useEffect(() => {
		const newEvents = filteredBookings.map(booking => new BigCalendarEvent(booking));
		setEvents(newEvents);
	}, [filteredBookings])
	return (
		<>
			<Modal show={show} onHide={() => { setShow(false); resetForm(); }} size="xl" scrollable={true}>
				<Modal.Header>
					<Modal.Title>{currentBooking ? "Raumbuchung aktualisieren" : "Neue Raumbuchung"}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={save} id="bookingsEditForm">
						<section className="grid form">
							<label>*Benötigter Raum</label>
							<Select data={rooms.filter((room) => room?.active)} name="room" onChange={handleInput} value={formData.room?.id || formData.room} required />

							{
								formData.room ?
									<>
										<label>Belegte Zeiträume</label>
										<Calendar localizer={Localizer} showMultiDayTimes messages={TRANSLATION} events={events} popup={false} components={{ month: { event: Event } }} views={{ month: true, day: true, week: true }} style={{ height: "650px" }} />
									</>
									: null
							}
							<label>Serientermin</label>
							<Toggle name="series" id="series" checked={series} onChange={() => setSeries(!series)} />
							{
								series ?
									<>
										<label></label>
										<div className="grid half-half px-0">
											<section>
												<label>Wiederholung</label>
												<select className="form-control" onChange={handleSeriesInput} name="recurring_type">
													<option value={"weekly"}>Wöchentlich</option>
													<option value={"bi-weekly"}>Bi-Wöchentlich</option>
													<option value={"monthly"}>Monatlich</option>
												</select>
											</section>
											<section>
												<label>Enddatum</label>
												<DateInput className="form-control" onChange={handleSeriesInput} name="end_date" value={pattern?.end_date !== "" ? new Date(pattern?.end_date) : new Date()} event={true}></DateInput>
											</section>
										</div>
									</>
									: <></>
							}
							<label>*Titel der Veranstaltung</label>
							<input className="form-control" onChange={handleInput} name="name" value={formData.name} required />

							<label>Anlass</label>
							<textarea className="form-control" onChange={handleInput} name="occasion" value={formData.occasion} />

							<label>*Datum</label>
							<DateInput className="form-control" onChange={handleDate} name="from" value={formData.from} event={true} required />

							<label>*Uhrzeit von</label>
							<div className="grid ten-ninety p-0">
								<TimeInput className="form-control" name="from" onChange={handleTime} value={new Date(formData.from)} required />
								<section className="grid ten-ninety p-0">
									<label style={{ marginBottom: "0", alignSelf: "center" }}>Bis</label>
									<TimeInput className="form-control" name="to" onChange={handleTime} value={new Date(formData.to)} filterTime={new Date(formData.from)} required />
								</section>
							</div>

							<label>*Anzahl der Teilnehmer</label>
							<div className="grid ten-ninety px-0">
								<div className="input-group" style={{ width: "135px" }}>
									<div className="input-group-prepend">
										<div className="input-group-text"><PersonFill /></div>
									</div>
									<input className="form-control" type="number" name="participants" onChange={handleInput} value={formData.participants} required />
								</div>
								<section className="grid ten-ninety p-0">
									<Toggle name="hospitality" checked={formData.hospitality} onChange={handleInput} />
									<label style={{ marginBottom: "0", alignSelf: "center" }}>Bewirtung benötigt</label>
								</section>
							</div>

							<label>Bemerkungen</label>
							<textarea className="form-control" onChange={handleInput} name="notes" value={formData.notes} />
						</section>

						<hr style={{ borderWidth: "2px", marginBottom: "2rem" }} />
						<h2>Kontakt</h2>

						<section className="grid form">
							<label>*Name</label>
							<input className="form-control" onChange={handleInput} name="contactName" value={formData.contactName} required />

							<div>
								<label>*E-Mail-Adresse</label>
								{currentBooking ?
									<>
										<a className="btn" href={`mailto:${currentBooking.email}`}>
											<Envelope style={{ color: "#ccc" }} />
										</a>
									</>
									: null}
							</div>
							<input className="form-control" type="email" onChange={handleInput} name="contactEmail" value={formData.contactEmail} required />

						</section>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-danger" onClick={handleDelete} style={{ marginRight: "auto" }}>Buchung Löschen</button>
					<button className="btn btn-outline-danger" onClick={() => {
						resetForm();
						setShow(false);
					}}>Abbrechen</button>
					{currentBooking ?
						<>
							<button className="btn btn-primary" form="bookingsEditForm" type="submit">Änderungen speichern</button>
							<button className={formData?.confirmed ? "btn btn-danger" : "btn btn-success"} onClick={handleConfirmation}>{formData?.confirmed ? "Bestätigung zurücknehmen" : "Anfrage bestätigen"}</button>
						</>
						: <button className="btn btn-success" form="bookingsEditForm" type="submit">Raumbuchung anfragen</button>
					}
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default BookingModal;
