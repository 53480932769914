import {useState, useCallback} from "react";
/**
 * `useForm()` hook
 * 
 * this should simplify/streamline the handling of forms all over the project
 * 
 * USAGE:
 *     // this HAS to be outside of your component!
 *     const DefaultFormData = {
 *         name       : "",
 *         email      : "",
 *         registered : false
 *     };
 * 
 *     ...
 * 
 *     const [formData, handleInput, clear] = useForm(DefaultFormData);
 *     
 *     const save = event => {
 *         console.log("SAVED!");
 *         clear();
 *     }
 *     
 *     return (
 *         <>
 *             <input type="text" name="name" value={formData.name} onChange={handleInput} />
 *             <button onClick={save} />
 *         </>
 *     );
 * 
 * @author Michael Ochmann <michael.ochmann@propeller.de>
 */
const useForm = initialState => {
	const [formData, setFormData] = useState(initialState);
	const [changed, setChanged]   = useState(false);

	const handleInput = event => {
		event.persist();
		const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		setFormData(oldData => { return {...oldData, [event.target.name] : value}; });
		setChanged(true);
	};

	const clear = useCallback((callback = null) => {
		setChanged(false);
		if (callback && typeof callback === "function")
			setFormData(oldData => callback(oldData));
		else if (callback && typeof callback === "object")
			setFormData(callback);
		else
			setFormData(initialState);
	}, [initialState]);

	return [formData, handleInput, clear, changed];
};

export default useForm;