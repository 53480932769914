import React, {useState, useEffect, useCallback, useMemo} from "react";
import axios from "axios";

import Modal                           from "components/shared/Modal";
import SortableTable                   from "components/shared/SortableTable";
import Spinner                         from "components/shared/Spinner";
import {ShortDateFormatter}            from "formatters/DateFormatter";
import {StrapiRequest, StrapiResponse} from "utils/StrapiUtils";

import ShortTimeFormatter    from "../../formatters/TimeFormatter";

const Header = [
	{
		label      : "Titel",
		member     : "name"
	},
    {
        label      : "Termin",
        member     : "from",
        sortable   : true,
        formatter  : ShortDateFormatter,
    },
	{
		label      : "Anfang",
		member     : "from",
		formatter  : ShortTimeFormatter
	},
	{
		label      : "Ende",
		member     : "to",
		formatter  : ShortTimeFormatter
	},
	{
		label      : "Anfrage von",
		member     : "contactName",
        functional : ({member}) =>  {return <a href={`mailto:${member.contactEmail}`}>{member.contactName}</a>}
	}
]


const RoomsBookingList = ({onHide, show, currentRoom}) => {

    const [bookings, setBookings]                 = useState([]);
    const [ready, setReady]                       = useState(false);

    const loadBookings = useCallback(() => {
        axios.get("/rm-bookings/?populate=*&pagination[limit]=-1").then(response => {
			//console.log(StrapiResponse(response));
            setBookings(StrapiResponse(response));
            setReady(true);
        }).catch(error => alert.error(`Fehler beim Laden des Belegungsplans: ${error}`));
    }, [alert])

    useEffect(() => {
        loadBookings();
    }, [loadBookings])


	const filteredBookings = useMemo(() => {
        return bookings.filter((booking) => {
			return booking.room?.id === currentRoom?.id
		});
	}, [currentRoom, bookings]);

    return(
        <>
            <Modal size="xl" onHide={onHide} show={show} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Belegte Zeiträume für '${currentRoom?.name}'`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
					<section className="p-4">
						{
							ready ? 
								<SortableTable data={filteredBookings} headers={Header}/>
							: <Spinner/>
						}
					</section>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-danger" onClick={onHide}>Schließen</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RoomsBookingList;