import React from "react";

import Languages from "utils/Languages";

const LanguageSelect = ({value, onChange, disable = [], ...props}) => {
	return (
		<select className="form-control" value={value} onChange={onChange} {...props} required>
			<option value="" disabled>Sprache auswählen...</option>
			{Languages.map((language, index) => {
				return <option key={index} value={language} disabled={disable.includes(language)}>{new Intl.DisplayNames(["de"], {type : "language"}).of(language.toLocaleLowerCase())}</option>
			})}
		</select>
	);
};

export default LanguageSelect;
