import React, {useCallback, useEffect, useState, useContext, useMemo} from "react";
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";
import {useAlert}             from "react-alert";
import {FileEarmarkArrowDown} from "react-bootstrap-icons";
import {Modal}                from "react-bootstrap";
import {DateTime}             from "luxon";

import Select                          from "../../../../../components/shared/Select";
import DateInput                       from "../../../../../components/shared/DateInput";
import {StrapiDate}                    from "../../../../../utils/StrapiUtils";
import UserContext                     from "components/UserContext";
import {StrapiRequest, StrapiResponse} from "../../../../../utils/StrapiUtils";
import usePermission                   from "components/hooks/usePermission";

import CourseSelect   from "../../selectFields/CourseSelect";
import StudentSelect  from "../../selectFields/StudentSelect";
import HoursList      from "../../HoursList";
import DeploymentInfo from "../../DeploymentInfo";
import HoursReport    from "../../reports/HoursReport";
import NoStudent      from "../../notices/NoStudent";
import NoDeployment   from "../../notices/NoDeployment";

import Notice from "./Notice";

const HoursSingle = ({currentStudent}) => {
	const today                         = new StrapiDate();
	const alert                         = useAlert();
	const {permissions, authUser}       = useContext(UserContext);
	const [courses, setCourses]         = useState([]);
	const [students, setStudents]       = useState([]);
	//const [shifts, setShifts]           = useState([]);
	const [categories, setCategories]   = useState([]);
	const [show, setShow]               = useState(false);
	const [deployments, setDeployments] = useState([]);

	const [course, setCourse]         = useState(null);
	const [formData, setFormData]     = useState({minutes : 0, hours : "", date : today, category : "", /*shift : "",*/ description : ""});
	const [deployment, setDeployment] = useState("");
	const [hours, setHours]           = useState([]);
	const [student, setStudent]       = useState(typeof currentStudent !== "undefined" ? currentStudent : null);
	const canEditStudent              = usePermission("student", "find", "student");

	/**
	 * get list of students if course is selected
	 */
	useEffect(() => {
		if (course === null)
			return;

		axios.get(`/students?course=${course}&sort[0]=lastname:asc&filters[active]=true&pagination[limit]=-1`).then(response => {
			setStudents(StrapiResponse(response));
			setFormData(oldData => {return  {...oldData, student : null}});
			setStudent(null);
		});
	}, [course]);

	useEffect(() => {
		if (deployment === "")
			return;
		setFormData(data => ({...data, ward : deployments[deployment]?.ward.id}));
	}, [deployment, deployments]);


	const currentDeployment = useMemo(() => {
		return deployment !== "" ? deployments[deployment] : null;
	}, [deployments, deployment]);

	/**
	 * get all details if student is selected
	 */
	const getInfo = useCallback((userChange = false) => {
		if (!formData.student)
			return;
		const date = new StrapiDate(formData.date);

		axios.get(`/deployments?filters[student]=${formData.student}&filters[from][$lte]=${date.toStrapiDate()}&filters[to][$gte]=${date.toStrapiDate()}&sort[0]=from:desc&populate=*&pagination[limit]=-1`).then(response => {
			const data = StrapiResponse(response);

			if (data.length < 1) {
				setDeployment(null);
				setHours([]);
				return;
			}

			setDeployment(deployment => userChange || deployment === "" ? data[0].id : deployment);
			const deploymentMap = {};
			for (const deployment of data)
				deploymentMap[deployment.id] = deployment;
			
			setDeployments(deploymentMap);

			axios.get(`/hour-entries?filters[student]=${formData.student}&sort[0]=id:desc&populate=*&pagination[limit]=-1`).then(response => {
				setHours(StrapiResponse(response));
			}).catch(error => error);
		}).catch(error => error);
	}, [formData.student, formData.date]);

	const getStatistics = useCallback(() => {
		if (typeof formData.student === "undefined" || formData.student === null)
			return;
		axios.get(`/students/${formData.student}/stats`).then(response => {
			setStudent(StrapiResponse(response));
			getInfo(true);
		}).catch(error => error.response.data);
	}, [formData.student, getInfo]);

	useEffect(() => {
		getStatistics();
	}, [formData.student, getStatistics]);

	/**
	 * handle form input
	 *
	 * @param event
	 */
	const handleInput = useCallback(event => {
		const data = {...formData, course};
		data[event.target.name] = event.target.value;
		setFormData(data);
	}, [formData, course]);

	/**
	 * save form to API
	 *
	 * @param event
	 */
	const save = useCallback(event => {
		event.preventDefault();
		const data = {
			...formData,
			user             : authUser.id,
			duration_minutes : parseInt(formData.hours) * 60 + parseInt(formData.minutes),
			date             : formData.date || DateTime.now().toISODate(),
			special          : authUser.specialChief,
			deployment       : currentDeployment.id
		}
		delete data.hours;
		delete data.minutes;

		if (!data.course || !data.deployment || !data.duration_minutes || !data.category) {
			alert.error(`Es wurden nicht alle Felder korrekt ausgefüllt.`);

			return;
		}


		axios.post("/hour-entries", StrapiRequest(data)).then(() => {
			alert.success(`Der Eintrag wurde erfolgreich gespeichert.`);
			setFormData({
				...formData,
				minutes         : 0,
				hours           : "",
				category        : "",
				description     : "",
			});
			getInfo();
			getStatistics();
		}).catch(error => alert.error(`Fehler beim Speichern des Eintrags: ${error}`));
	}, [alert, authUser, setFormData, formData, currentDeployment, getInfo, getStatistics]);

	/**
	/**
	 * get datasources from API on init
	 */
	useEffect(() => {
		getInfo();
		axios.get("/courses?filters[active][$eq]=true&sort[0]=year:desc&sort[1]=name:asc&pagination[limit]=-1").then(response => {
			setCourses(StrapiResponse(response));
		});

		axios.get("/categories?sort[0]=name:asc&pagination[limit]=-1").then(response => {
			setCategories(StrapiResponse(response));
		});
	}, [getInfo]);


	const lastAddedHourEntries = useMemo(() => {
		return hours.filter(entry => entry.deployment && entry.deployment?.id === currentDeployment?.id);
	}, [hours, currentDeployment]);

	return (
		<section className="hours-edit">
			<h2 className="py-4">Stundeneintrag erstellen</h2>
			<form className="grid half-half entry-form" onSubmit={save}>
				<section className="column">
					<section className="grid three-thirds" style={{gridTemplateColumns : "1fr 3fr 3fr"}}>
						{
							authUser ?
								<>
									<label>Name Anleiter</label>
									<input type="text" className="form-control" placeholder="Vorname" defaultValue={authUser.specialChief ? authUser.firstname : ""} name="chief_firstname" onChange={handleInput} disabled={authUser.specialChief} required={!authUser.specialChief} />
									<input type="text" className="form-control" placeholder="Nachname" defaultValue={authUser.specialChief ? authUser.lastname : ""} name="chief_lastname" onChange={handleInput} disabled={authUser.specialChief} required={!authUser.specialChief}/>
								</>
								: ""
						}
						<label>Kurs</label>
						<CourseSelect data={courses} name="course" defaultValue={course} onChange={event => setCourse(event.target.value)} />

						<label>Auszubildende/r</label>
						<StudentSelect data={students} name="student" value={formData.student ? formData.student : -1} onChange={handleInput} required />

						<label>Kategorie</label>
						<Select data={categories} current={formData.category} name="category" defaultText="Kategorie wählen" onChange={handleInput} required />

						<label>Beschreibung</label>
						<textarea className="form-control" rows="5" name="description" value={formData.description} onChange={handleInput} required>{formData.description}</textarea>

						<label>Anleitungsdauer</label>
						<div className="input-group mb-3">
							<input type="number" name="hours" value={formData.hours} className="form-control" placeholder="Stunden" max={10} onChange={handleInput} required />
							<div className="input-group-append">
								<span className="input-group-text">h</span>
							</div>
						</div>
						<div className="input-group mb-3">
							<input type="number" name="minutes" value={formData.minutes} className="form-control" placeholder="Minuten" max={59} onChange={handleInput} required />
							<div className="input-group-append">
								<span className="input-group-text">m</span>
							</div>
						</div>

						<label>Datum</label>
						<section>
							<DateInput value={formData.date} onChange={handleInput} name="date" event={true} className="form-control full" />
						</section>
						<span>&nbsp;</span>

						{/*<label>Dienst</label>
						<Select data={shifts} name="shift" current={formData.shift} defaultText="Dienst wählen" onChange={handleInput} required />i*/}
					</section>
					<p style={{textAlign: "right"}} className="mx-3">
						<button className="btn btn-primary" disabled={student === null || deployment === null}>Speichern</button>
					</p>
				</section>
				<section className="column p-4">
					{/* <Notice /> */}

					{
						student && canEditStudent ?
							(
								currentDeployment ?
									<DeploymentInfo deployment={currentDeployment} student={student} hours={hours} deployments={deployments} setDeployment={setDeployment} />
								: <NoDeployment />
							) : <NoStudent />
					}
					{ student && deployment && lastAddedHourEntries.length > 0  ?
						<p className="text-right">
							<button className="btn btn-outline-primary" onClick={event =>  {
								HoursReport(student, hours.filter(entry => entry.deployment && entry.deployment.id === currentDeployment.id && entry.special), currentDeployment);
								event.preventDefault();
							}}>
								<FileEarmarkArrowDown className="big" /> PDF-Stundenreport erstellen
							</button>
						</p>
						: ""
					}
				</section>
			</form>
			{student && currentDeployment ?
				<Modal show={show} size="xl">
					<Modal.Header>
						<Modal.Title>Detailansicht Stundeneinträge</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<HoursList data={hours.filter(entry => entry.deployment && entry.deployment.id === deployment.id).sort()} student={student} />
					</Modal.Body>
					<Modal.Footer>
						<button className="btn btn-outline-primary" onClick={() => setShow(false)}>Schließen</button>
					</Modal.Footer>
				</Modal>
			: ""}

			{
				student && currentDeployment && hours.filter(entry => entry.deployment && entry.deployment.id === currentDeployment.id).length > 0  ?
				(
					<>
						<br />
						<HoursList data={lastAddedHourEntries} student={student} />
					</>
				)
				: ""
			}
		</section>
	);
}

export default HoursSingle;
